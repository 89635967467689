import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Container,
} from "@mui/material";
import NewProjectForm from "./NewProjectForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function NewProject() {
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/app/mgmt/project/all");
  };
  const projectEdit = useSelector((state) => state.projectEdit);
  const projectDataToEdit = projectEdit.projectDataToEdit;
  const isProjectEdit = projectEdit.isProjectEdit;
  // console.log("projectData to edit", projectDataToEdit, isProjectEdit);
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Project</title>
      </Helmet>
      <Grid mt={15} mb={10}>
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader title={"Create New Project"} />
            <Divider />
            <CardContent>
              <NewProjectForm
                onSubmitSuccess={handleSubmitSuccess}
                data={projectDataToEdit}
                isProjectEdit={isProjectEdit}
              />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </DashboardLayout>
  );
}

export default NewProject;
