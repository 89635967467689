import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import {
  Button,
  IconButton,
  SvgIcon,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { fetchMyProject } from "../../../../actions/projectAction";

function MyProjectsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myProject = useSelector((state) => state.myProject);
  // console.log(" Myprojects: ", myProject);
  const [isShown, setIsShown] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const [servi, setServi] = useState({});
  const viewMyProject = (data) => {
    // console.log("handle view project", data);
    navigate("/app/mgmt/project/my");
  };

  useEffect(() => {}, [myProject.myProjects]);
  const handleDelete = async (
    serviceId,
    supId,
    state,
    certificateId,
    params
  ) => {
    // console.log("STATE",state)
    // console.log("PARAMS",params)
    // console.log("certificateId",certificateId)
    // console.log("serviceID", serviceId);
    // console.log("serviceID", supId);
    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("supplierId", supId);
      data.append("certificateId", serviceId);
      // const payload =
      // {
      //   "payload": {
      //     "supplierId": supId,
      //     "certificateId": certificateId
      //   }
      // }
      const deleteResult = await axios.post(`/api/projects/remove`, data);
      // console.log(`deleteResult: `, deleteResult);
    } catch (err) {}
  };
  return (
    <Card sx={{ boxShadow: 3, padding: 4, mt: 4, m: 4, width: "70%" }}>
      <CardContent>
        <Box sx={{ height: 400 }}>
          <DataGrid
            rows={myProject.myProjects}
            columns={headCells(true,handleDelete, viewMyProject)}
            getRowId={(row) => row.projectId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
          />
        </Box>
      </CardContent>
    </Card>
  );
}
export default MyProjectsList;
