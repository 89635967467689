import projectService from "services/projectService";
import {
  AddProject,
  AllocateDevice,
  AssignMember,
} from "../interface/addProjectIf";

export const PROJECT_FETCH = "@project/fetch";
export const PROJECT_FETCH_SUCCESS = "@project/fetch-success";
export const PROJECT_FETCH_FAILURE = "@project/fetch-failure";

export const MEMBER_LIST_FETCH = "@member/fetch";
export const MEMBER_LIST_FETCH_SUCCESS = "@member/fetch-success";
export const MEMBER_LIST_FETCH_FAILURE = "@member/fetch-failure";

export const ALLOCATE_DEVICE_FETCH = "@allocate/fetch";
export const ALLOCATE_DEVICE_FETCH_SUCCESS = "@allocate/fetch-success";
export const ALLOCATE_DEVICE_FETCH_FAILURE = "@allocate/fetch-failure";

export const MY_PROJECT_FETCH = "@myproject/fetch-project";
export const MY_PROJECT_FETCH_SUCCESS = "@myproject/fetch-project-success";
export const MY_PROJECT_FETCH_FAILURE = "@myproject/fetch-project-failure";

// export const PROJECT_INFO_FETCH = "@projectdetail/fetch-projectdetail";
// export const PROJECT_INFO_FETCH_SUCCESS =
//   "@projectdetail/fetch-projectdetail-success";
// export const PROJECT_INFO_FETCH_FAILURE =
//   "@projectdetail/fetch-projectdetail-failure";
export const PROJECT_DETAILS_FETCH = "@projectdetail/fetch-projectdetail";
export const PROJECT_DETAILS_FETCH_SUCCESS =
  "@projectdetail/fetch-projectdetail-success";
export const PROJECT_DETAILS_FETCH_FAILURE =
  "@projectdetail/fetch-projectdetail-failure";

export const PROJECT_CREATE = "@project/create";
export const PROJECT_CREATE_SUCCESS = "@project/create-success";
export const PROJECT_CREATE_FAILURE = "@project/create-failure";

export const PROJECT_DELETE = "@project/delete";
export const PROJECT_DELETE_SUCCESS = "@project/delete-success";
export const PROJECT_DELETE_FAILURE = "@project/delete-failure";

export const PROJECT_DEVICE_DELETE = "@project-device/delete";
export const PROJECT_DEVICE_DELETE_SUCCESS = "@project-device/delete-success";
export const PROJECT_DEVICE_DELETE_FAILURE = "@project-device/delete-failure";

export const PROJECT_MEMBER_DELETE = "@project-member/delete";
export const PROJECT_MEMBER_DELETE_SUCCESS = "@project-member/delete-success";
export const PROJECT_MEMBER_DELETE_FAILURE = "@project-member/delete-failure";

export const PROJECT_UPDATE = "@project/update";
export const PROJECT_UPDATE_SUCCESS = "@project/update-success";
export const PROJECT_UPDATE_FAILURE = "@project/update-failure";

export const PROJECT_EDIT_TRIGGER = "@project/edit-trigger";
export const PROJECT_CREATE_TRIGGER = "@project/create-trigger";

export const PROJECT_MEMBER_CREATE = "@member/create";
export const PROJECT_MEMBER_CREATE_SUCCESS = "@member/create-success";
export const PROJECT_MEMBER_CREATE_FAILURE = "@member/create-failure";

export const PROJECT_ALLOCATE_DEVICE = "@device/allocate";
export const PROJECT_ALLOCATE_DEVICE_SUCCESS = "@device/allocate-success";
export const PROJECT_ALLOCATE_DEVICE_FAILURE = "@device/allocate-failure";

// export function fetchProject(values) {
//   return async (dispatch) => {
//     try {

//       dispatch({
//         type: PROJECT_FETCH,
//         payload: {
//           newProjectInfo,
//         },
//       });
//       console.log("projectAction.addProjects:", newProjectInfo);

//       const resp = await projectService.addProjects(newProjectInfo);

//       console.log("projectAction.addProjects: Resp:", resp);

//       dispatch({
//         type: PROJECT_FETCH_SUCCESS,
//         payload: {
//           resp,
//         },
//       });

//       // TODO SP Fetch Rest of the information for the user to initialize the application
//     } catch (err) {
//       // console.log("projectAction.addProjects:: Error", err);

//       let errMsg = err?.response?.data?.message;
//       const error = { message: errMsg };

//       dispatch({ type: PROJECT_FETCH_FAILURE });
//       throw error;
//     }
//   };
// }
export function fetchProject() {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_FETCH,
      });
      const projects = await projectService.fetchProject();

      // console.log("projectAction.fetchProject Resp:", projects);

      dispatch({
        type: PROJECT_FETCH_SUCCESS,
        payload: {
          projects,
        },
      });
    } catch (error) {
      // console.log("projectAction.fetchProject: Error", error);
      dispatch({ type: PROJECT_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchMyProject() {
  return async (dispatch) => {
    try {
      dispatch({
        type: MY_PROJECT_FETCH,
      });
      // console.log("projectAction.fetchMyProject:");
      const myProjects = await projectService.fetchMyProject();

      console.log("projectAction.fetchMyProject Resp:", myProjects);

      dispatch({
        type: MY_PROJECT_FETCH_SUCCESS,
        payload: {
          myProjects,
        },
      });
    } catch (error) {
      // console.log("projectAction.fetchProject: Error", error);
      dispatch({ type: MY_PROJECT_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchProjectDetail(projectId) {
  // console.log("projectID IN Action", projectId)
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_DETAILS_FETCH,
        payload: {
          projectId,
        },
      });
      // console.log("projectAction.fetchProjectDetail:");
      const projectDetails = await projectService.fetchProjectDetail(projectId);

      // console.log("projectAction.fetchProjectDetail Resp:", projectDetails);

      dispatch({
        type: PROJECT_DETAILS_FETCH_SUCCESS,
        payload: {
          projectDetails,
        },
      });
    } catch (error) {
      // console.log("projectAction.fetchProjectDetail: Error", error);
      dispatch({ type: PROJECT_DETAILS_FETCH_FAILURE });
      throw error;
    }
  };
}

// export function fetchProjectInfo(projectInfo) {
//   // console.log("fetchProjectInfo aCTION", projectInfo);
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: PROJECT_INFO_FETCH,
//         payload: { projectInfo },
//       });
//       dispatch({
//         type: PROJECT_INFO_FETCH_SUCCESS,
//         payload: {
//           projectInfo,
//         },
//       });
//     } catch (error) {
//       // console.log("projectAction.fetchProjectInfo: Error", error);
//       dispatch({ type: PROJECT_INFO_FETCH_FAILURE });
//       throw error;
//     }
//   };
// }

export function createProjects(userId, values) {
  return async (dispatch) => {
    try {
      const newProjectInfo = new AddProject(userId, values);

      dispatch({
        type: PROJECT_CREATE,
        payload: {
          newProjectInfo,
        },
      });
      console.log("projectAction.createProjects:", newProjectInfo);

      const resp = await projectService.createProjects(newProjectInfo);

      console.log("projectAction.createProjects: Resp:", resp);

      dispatch({
        type: PROJECT_CREATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProject());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.addProjects:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function createProjectMembers(values) {
  return async (dispatch) => {
    try {
      const newMemberInfo = new AssignMember(values);

      dispatch({
        type: PROJECT_MEMBER_CREATE,
        payload: {
          newMemberInfo,
        },
      });
      console.log("projectAction.createProjectMembers:", newMemberInfo);

      const resp = await projectService.createProjectMembers(newMemberInfo);

      console.log("projectAction.createProjectMembers: Resp:", resp);

      dispatch({
        type: PROJECT_MEMBER_CREATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProjectDetail(values.projectId));

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      console.log("projectAction.createProjectMembers:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_MEMBER_CREATE_FAILURE });
      throw error;
    }
  };
}

export function createAllocateDevice(values) {
  return async (dispatch) => {
    try {
      const newDeviceInfo = new AllocateDevice(values);

      dispatch({
        type: PROJECT_ALLOCATE_DEVICE,
        payload: {
          newDeviceInfo,
        },
      });
      console.log("projectAction.createAllocateDevice:", newDeviceInfo);

      const resp = await projectService.createAllocateDevice(newDeviceInfo);

      console.log("projectAction.createAllocateDevice: Resp:", resp);

      dispatch({
        type: PROJECT_ALLOCATE_DEVICE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProjectDetail(values.projectId));

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      console.log("projectAction.createAllocateDevice:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_ALLOCATE_DEVICE_FAILURE });
      throw error;
    }
  };
}

export function deleteProject(projectId) {
  // console.log("delete project ", projectId);
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_DELETE,
        payload: {
          projectId,
        },
      });
      // console.log("projectAction.deleteProject:", rfqInfo);

      const projects = await projectService.deleteProject(projectId);

      // console.log("projectAction.deleteProject Resp:", requirements);
      dispatch({
        type: PROJECT_DELETE_SUCCESS,
        payload: {
          ...projects,
        },
      });
      await dispatch(fetchProject());
    } catch (error) {
      // console.log("projectAction.deleteProject: Error", error);
      dispatch({ type: PROJECT_DELETE_FAILURE });
      throw error;
    }
  };
}

export function updateProjects(projectId, values) {
  return async (dispatch) => {
    try {
      const newProjectInfo = new AddProject(projectId, values);

      dispatch({
        type: PROJECT_UPDATE,
        payload: {
          newProjectInfo,
        },
      });
      console.log("projectAction.updateProjects:", newProjectInfo);

      const resp = await projectService.updateProjects(newProjectInfo);

      console.log("projectAction.updateProjects Resp:", resp);

      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProject());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.updateProjects: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_UPDATE_FAILURE });
      throw error;
    }
  };
}
export function editProjectTrigger(projectEdit) {
  return async (dispatch) => {
    console.log("editProjectTrigger() ", projectEdit);
    dispatch({
      type: PROJECT_EDIT_TRIGGER,
      payload: { projectEdit },
    });
  };
}

export function createProjectTrigger() {
  return async (dispatch) => {
    dispatch({
      type: PROJECT_CREATE_TRIGGER,
      payload: {},
    });
  };
}
export function fetchAllocateList(projectId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ALLOCATE_DEVICE_FETCH,
        payload: {
          projectId,
        },
      });
      // console.log("projectAction.fetchAllocateList:", projectId);

      const devices = await projectService.fetchAllocateList(projectId);

      // console.log("projectAction.fetchAllocateList Resp:", devices);

      dispatch({
        type: ALLOCATE_DEVICE_FETCH_SUCCESS,
        payload: {
          devices,
        },
      });
    } catch (error) {
      // console.log("projectAction.fetchAllocateList: Error", error);
      dispatch({ type: ALLOCATE_DEVICE_FETCH_FAILURE });
      throw error;
    }
  };
}

export function deletePojectDevice(projectId, deviceId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_DEVICE_DELETE,
        payload: {
          projectId,
          deviceId,
        },
      });
      // console.log("projectAction.deletePojectDevice:", rfqInfo);

      const payload = await projectService.deletePojectDevice(
        projectId,
        deviceId
      );

      console.log("projectAction.deletePojectDevice Resp:", payload);
      dispatch({
        type: PROJECT_DEVICE_DELETE_SUCCESS,
        payload: {
          payload,
        },
      });
      await dispatch(fetchProjectDetail(projectId));
    } catch (error) {
      // console.log("projectAction.deletePojectDevice: Error", error);
      dispatch({ type: PROJECT_DEVICE_DELETE_FAILURE });
      throw error;
    }
  };
}

export function fetchMembersList(projectId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEMBER_LIST_FETCH,
        payload: {
          projectId,
        },
      });
      // console.log("projectAction.fetchMembersList:", projectId);

      const members = await projectService.fetchMembersList(projectId);

      // console.log("projectAction.fetchMembersList Resp:", devices);

      dispatch({
        type: MEMBER_LIST_FETCH_SUCCESS,
        payload: {
          members,
        },
      });
    } catch (error) {
      // console.log("projectAction.fetchMembersList: Error", error);
      dispatch({ type: MEMBER_LIST_FETCH_FAILURE });
      throw error;
    }
  };
}

export function deletePojectMember(projectId, userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_MEMBER_DELETE,
        payload: {
          projectId,
          userId,
        },
      });
      console.log("projectAction.deletePojectMember:", projectId, userId);

      const payload = await projectService.deletePojectMember(
        projectId,
        userId
      );

      console.log("projectAction.deletePojectMember Resp:", payload);
      dispatch({
        type: PROJECT_MEMBER_DELETE_SUCCESS,
        payload: { payload },
      });
      await dispatch(fetchProjectDetail(projectId));
    } catch (error) {
      // console.log("projectAction.deletePojectDevice: Error", error);
      dispatch({ type: PROJECT_MEMBER_DELETE_FAILURE });
      throw error;
    }
  };
}
