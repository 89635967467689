import registeredAddressService from "services/registeredAddressService";
import { ClientAddress } from "../interface/clientAddressIf";

import { fetchClient } from "./clientAction";

export const DELETE_REGISTER_ADDRESS = "@register-address/delete";
export const DELETE_REGISTER_ADDRESS_SUCCESS = "@register-address/delete-success";
export const DELETE_REGISTER_ADDRESS_FAILURE = "@register-address/delete-failure";

export const REGISTER_ADDRESS_UPDATE = "@register-address/update";
export const REGISTER_ADDRESS_UPDATE_SUCCESS = "@register-address/update-success";
export const REGISTER_ADDRESS_UPDATE_FAILURE = "@register-address/update-failure";

export const DELETE_OTHER_ADDRESS = "@other-address/delete";
export const DELETE_OTHER_ADDRESS_SUCCESS = "@other-address/delete-success";
export const DELETE_OTHER_ADDRESS_FAILURE = "@other-address/delete-failure";

export const OTHER_ADDRESS_UPDATE = "@other-address/update";
export const OTHER_ADDRESS_UPDATE_SUCCESS = "@other-address/update-success";
export const OTHER_ADDRESS_UPDATE_FAILURE = "@other-address/update-failure";

export const REGISTER_ADDRESS_EDIT_TRIGGER = "@register-address/edit-trigger";
export const REGISTER_ADDRESS_TRIGGER = "@register-address/create-trigger";

export const OTHER_ADDRESS_EDIT_TRIGGER = "@other-address/edit-trigger";
export const OTHER_ADDRESS_TRIGGER = "@other-address/create-trigger";

export function deleteRegisterAddress(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type:  DELETE_REGISTER_ADDRESS,
        payload: {
          clientId,
        },
      });
      // console.log("productsAction.deleteProduct:", clientId);

      const address = await registeredAddressService.removeClientRegisteredAddress(clientId);

      // console.log("productsAction.deleteProduct Resp:", products);
      dispatch({
        type: DELETE_REGISTER_ADDRESS_SUCCESS,
        payload: {
          address,
        },
      });
      await dispatch(fetchClient());
    } catch (error) {
      // console.log("productsAction.deleteProduct: Error", error);
      dispatch({ type: DELETE_REGISTER_ADDRESS_FAILURE });
      throw error;
    }
  };
}

export function updateRegisterAddress(clientId, values) {
  return async (dispatch) => {
    try {
      const newRegisterAddress = new ClientAddress(clientId, values);

      dispatch({
        type: REGISTER_ADDRESS_UPDATE,
        payload: {
            newRegisterAddress,
        },
      });
    //   console.log("catalogAction.updateProducts:", newProductInfo);

      const resp = await registeredAddressService.updateClientRegisteredAddress(newRegisterAddress);

    //   console.log("catalogAction.updateProducts Resp:", resp);
      dispatch({
        type: REGISTER_ADDRESS_UPDATE_SUCCESS ,
        payload: {
          resp,
        },
      });
      await dispatch(fetchClient());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateProducts: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: REGISTER_ADDRESS_UPDATE_FAILURE  });
      throw error;
    }
  };
}

export function deleteOtherAddress(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type:  DELETE_OTHER_ADDRESS,
        payload: {
          clientId,
        },
      });
      console.log("productsAction.deleteProduct:", clientId);

      const address = await registeredAddressService.removeClientOtherAddress(clientId);

      // console.log("productsAction.deleteProduct Resp:", products);
      dispatch({
        type: DELETE_OTHER_ADDRESS_SUCCESS,
        payload: {
          address,
        },
      });
      await dispatch(fetchClient());
    } catch (error) {
      // console.log("productsAction.deleteProduct: Error", error);
      dispatch({ type: DELETE_OTHER_ADDRESS_FAILURE });
      throw error;
    }
  };
}

export function updateOtherAddress(clientId,label, values) {
  return async (dispatch) => {
    try {
      const newOtherAddress = new ClientAddress(clientId,label, values);

      dispatch({
        type: OTHER_ADDRESS_UPDATE,
        payload: {
          newOtherAddress,
        },
      });
    //   console.log("catalogAction.updateProducts:", newProductInfo);

      const resp = await registeredAddressService.updateClientOtherAddress(newOtherAddress);

    //   console.log("catalogAction.updateProducts Resp:", resp);

      dispatch({
        type: OTHER_ADDRESS_UPDATE_SUCCESS ,
        payload: {
          resp,
        },
      });
      await dispatch(fetchClient());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateProducts: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: OTHER_ADDRESS_UPDATE_FAILURE  });
      throw error;
    }
  };
}

export function editRegisterAddressTrigger(registerAddressEdit) {
  return async (dispatch) => {
    // console.log("editRegisterAddressTrigger() ", registerAddressEdit);
    dispatch({
      type: REGISTER_ADDRESS_EDIT_TRIGGER,
      payload: { registerAddressEdit },
    });
  };
};


export function addRegisterAddressTrigger() {
  return async (dispatch) => {
    dispatch({
      type: REGISTER_ADDRESS_TRIGGER,
      payload: {},
    });
  };
}


export function editOtherAddressTrigger(otherAddressEdit) {
  return async (dispatch) => {
    // console.log("editOtherAddressTrigger", otherAddressEdit);
    dispatch({
      type: OTHER_ADDRESS_EDIT_TRIGGER,
      payload: { otherAddressEdit },
    });
  };
};


export function addOtherAddressTrigger() {
  return async (dispatch) => {
    dispatch({
      type: OTHER_ADDRESS_TRIGGER,
      payload: {},
    });
  };
}