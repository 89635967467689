import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import { Grid } from "@mui/material";
import MyProjectsList from "./MyProjectsList";

function MyProjects() {
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - MyProjects</title>
      </Helmet>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <MyProjectsList />
      </Grid>
    </DashboardLayout>
  );
}

export default MyProjects;
