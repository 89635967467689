import jwtDecode from "jwt-decode";
import axios from "utils/axios";
import { UserProfile } from "interface/userProfileIf";
import { BUSINESS_ID } from "config";

import {
  SIGNIN_EMAIL_PASS_URL,
  SIGNIN_MOBILE_REQ_OTP_URL,
  SIGNIN_MOBILE_VERIFY_OTP_URL,
  SIGNIN_SILENT_URL,
  SIGNOUT_URL,
  SIGNUP_MOBILE_URL,
  SIGNUP_MOBILE_VERIFY_OTP_URL,
  UPDATE_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
} from "config/services";

class AuthService {
  setUserId = (userId) => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  };

  setUserMobile = (mobile) => {
    if (mobile) {
      localStorage.setItem("mobile", mobile);
    } else {
      localStorage.removeItem("mobile");
    }
  };
  setUserEmail = (email) => {
    if (email) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  getUserId = () => localStorage.getItem("userId");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    // console.log(
    //   "isValidToken() accessToken: ",
    //   accessToken,
    //   " decoded",
    //   decoded
    // );
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    // console.log("handleAuthentication: ", accessToken);

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  isAuthenticated = () => !!this.getAccessToken();

  signinWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_EMAIL_PASS_URL, {
          email,
          password,
          businessId: BUSINESS_ID,
        })
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error?.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  signUpMobile = (name, mobile) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNUP_MOBILE_URL, { name, mobile })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + name,
          //   mobile,
          //   JSON.stringify(response)
          // );

          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data?.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  signupMobileVerifyOTP = (mobile, name, otp) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };

      axios
        .post(SIGNUP_MOBILE_VERIFY_OTP_URL, { mobile, name, otp })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + mobile,
          //   otp,
          //   JSON.stringify(response)
          // );

          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            // resolve(user);
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  signinMobileReqOTP = (mobile) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };

      axios
        .post(SIGNIN_MOBILE_REQ_OTP_URL, { mobile })
        .then((response) => {
          // console.log("signinMobileReqOTP()" + JSON.stringify(response));

          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data?.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signinMobileReqOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  signinMobileVerifyOTP = (mobile, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_MOBILE_VERIFY_OTP_URL, { mobile, otp })
        .then((response) => {
          // console.log("signinMobileVerifyOTP" + JSON.stringify(response));

          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            const payload = response?.data?.data;
            if (payload) {
              this.setSession(payload?.token);
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
            }
            // resolve(user);
            resolve(payload?.user);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signinMobileVerifyOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          if (error?.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  loginInWithToken = (userId) =>
    new Promise((resolve, reject) => {
      // console.log("loginInWithToken: ",userId, this.getAccessToken());
      axios
        .get(SIGNIN_SILENT_URL, {
          params: { userId: userId },
        })
        .then((response) => {
          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            const payload = response?.data?.payload;
            // console.log("loginInWithToken: ", payload);
            if (payload) {
              this.setSession(payload?.token);
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
            }
            // resolve(user);
            resolve(payload?.user);
          }
        })
        .catch((error) => {
          //console.log("loginInWithToken->error" + JSON.stringify(error));
          reject(error);
          resolve();
        });
    });

  signout = () => {
    new Promise((resolve, reject) => {
      this.setSession(null);
      this.setUserId(null);

      axios
        .post(SIGNOUT_URL)
        .then((response) => {
          this.setUserEmail(null);
          this.setUserMobile(null);

          // console.log(
          //   "authService:signinWithEmailAndPassword: Response",
          //   response.data
          // );
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "authService:signout:Error2",
            //   error
            // );
            // reject(error?.response?.data);
            resolve();
          }
          reject(error);
        });
    });
  };

  updatePassword = (newPassword, userId) =>
    new Promise((resolve, reject) => {
      // console.log("authService.updatePassword called", newPassword, userId);
      axios
        .post(UPDATE_PASSWORD_URL, { newPassword, userId })
        .then((response) => {
          // console.log("authService.updatePassword () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("authService.updatePassword() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  changeOwnPassword = (password, newPassword) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "authService.changeOwnPassword called",
      //   password,
      //   newPassword
      // );
      axios
        .post(CHANGE_PASSWORD_URL, { password, newPassword })
        .then((response) => {
          // console.log("authService.updatePassword () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("authService.changeOwnPassword() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const authService = new AuthService();

export default authService;
