import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import viewsRoutes from './routes/routes';
import AuthGuard from './components/AuthGuard';

const Routes = () => {
  return (
    <ReactRoutes>
      {viewsRoutes.map((item, i) => (
        <Route
        key={i}
        path={item.path}
        element={
          item.isProtected ? (
            <AuthGuard>
              <item.renderer />
            </AuthGuard>
          ) : (
            item.renderer()
          )
        }
      />
      ))}

      <Route path="*" element={<Navigate replace to="/not-found-cover" />} />
    </ReactRoutes>
  );
};

export default Routes;
