import axios from "utils/axios";

import {
  USERS_LIST_URL,
  CREATE_USER_URL,
  REMOVE_USER_URL,
  UPDATE_USER_URL,
  BLOCK_USER_URL,
  ADD_DISCOUNT_URL,
  FETCH_DISCOUNT_URL,
  DELETE_DISCOUNT_URL,
  UPDATE_DISCOUNT_URL,
} from "config/services";

class UsersService {
  fetchUsers = () =>
    new Promise((resolve, reject) => {
      axios
        .get(USERS_LIST_URL, {})
        .then((response) => {
          // console.log("usersService.fetchUsers() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createUsers = (newUserInfo) =>
    new Promise((resolve, reject) => {
      // console.log("usersService.createUsers called", newUserInfo);
      const payload = newUserInfo;
      axios
        .post(CREATE_USER_URL, { ...payload })
        .then((response) => {
          // console.log("usersService.createUsers() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  removeUser = (userId) =>
    new Promise((resolve, reject) => {
      // console.log("usersService.removeUser called", userId);
      axios
        .post(REMOVE_USER_URL, { userId })
        .then((response) => {
          // console.log("usersService.removeUser() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateUser = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);

      axios
        .post(UPDATE_USER_URL, { ...payload })
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  blockUser = (userId) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.blockclient called", userId);

      axios
        .post(BLOCK_USER_URL, { userId })
        .then((response) => {
          // console.log("clientService.blockClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addDiscount = (newDiscountInfo) =>
    new Promise((resolve, reject) => {
      // console.log("usersService.addDiscount called", newDiscountInfo);
      const payload = newDiscountInfo;
      axios
        .post(ADD_DISCOUNT_URL, { payload })
        .then((response) => {
          // console.log("usersService.addDiscount() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addDiscount() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  deleteDiscount = (discountId) =>
    new Promise((resolve, reject) => {
      // console.log("usersService.deleteDiscount called", discountId);

      axios
        .post(DELETE_DISCOUNT_URL, { payload: { discountId } })
        .then((response) => {
          // console.log("usersService.deleteDiscount() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.deleteDiscount() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchDiscount = () =>
    new Promise((resolve, reject) => {
      axios
        .get(FETCH_DISCOUNT_URL, {})
        .then((response) => {
          // console.log("usersService.fetchUsers() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateDiscount = (newDiscountInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);
      const payload = newDiscountInfo;
      axios
        .post(UPDATE_DISCOUNT_URL, { payload })
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const usersService = new UsersService();

export default usersService;
