import React from "react";

import {
  Home as HomeView,
  SignIn as SignInView,
  SignUpMobile as SignUpMobileView,
  SignUpMobileOTPVerify as SignUpMobileOTPVerifyView,
  SignInMobile as SignInMobileView,
  SignInMobileOTPVerify as SignInMobileOTPVerifyView,
  PageNotFound as PageNotFoundPage,
  Dashboard as DashboardPage,
  AllProjects as AllProjectsPage,
  MyProjects as MyProjectsPage,
  NewProject as NewProjectPage,
  ProjectDetail as ProjectDetailPage,
  AssignMember as AssignMemberPage,
  AllocateDevice as AllocateDevicePage,
  AllUsers as AllUsersPage,
  NewUser as NewUserPage,
  UserProfile as UserProfilePage,
  AllRequest as AllRequestPage,
  NewRequest as NewRequestPage,
  MyCustomers as MyCustomersPage,
  AllCustomers as AllCustomersPage,
  NewCustomer as NewCustomerPage,
  AllProducts as AllProductsPage,
  NewProduct as NewProductPage,
  Configurator as ConfiguratorPage,
  AllCustomProduct as AllCustomProductPage,
  ProfileCustomer as ProfileCustomerPage,
  Quote as QuotePage,
  AllQuotation as AllQuotationPage,
  Profile as ProfilePage,
  ProfileEdit as ProfileEditPage,
  QuoteCalculater as QuoteCalculaterPage,
  MyQuote as MyQuotePage,
  ProductUploadList as ProductUploadListPage,
  ChangePassword as ChangePasswordPage,
  ChangePasswordOwn as ChangePasswordOwnPage,
  AllDiscounts as AllDiscountsPage,
  NewDiscount as NewDiscountPage,
  ThemePage,
} from "views";
// import InvoicePDF from "../views/Quote/components/GenerateQuatation/InvoicePdf";
import Invoice from "../views/Quote/components/GenerateQuatation/Invoice";
import AppendixInvoice from "../views/Quote/components/GenerateAppendix/AppendixInvoice";

const routes = [
  {
    path: "/",
    renderer: (params = {}) => <HomeView {...params} />,
  },
  {
    path: "/signin",
    renderer: (params = {}) => <SignInView {...params} />,
  },
  {
    path: "/signup",
    renderer: (params = {}) => <SignUpMobileView {...params} />,
  },
  {
    path: "/signup-verify-otp",
    renderer: (params = {}) => <SignUpMobileOTPVerifyView {...params} />,
  },
  {
    path: "/signin-mobile",
    renderer: (params = {}) => <SignInMobileView {...params} />,
  },
  {
    path: "/signin-verify-otp",
    renderer: (params = {}) => <SignInMobileOTPVerifyView {...params} />,
  },
  {
    path: "/app/dashboards/dashboard",
    isProtected: true,
    renderer: (params = {}) => <DashboardPage {...params} />,
  },

  {
    path: "/app/mgmt/project/all",
    isProtected: true,
    renderer: (params = {}) => <AllProjectsPage {...params} />,
  },
  {
    path: "/app/mgmt/users/all",
    isProtected: true,
    renderer: (params = {}) => <AllUsersPage {...params} />,
  },
  {
    path: "/app/mgmt/user/create",
    isProtected: true,
    renderer: (params = {}) => <NewUserPage {...params} />,
  },
  {
    path: "/app/mgmt/user/user-viewprofile",
    isProtected: true,
    renderer: (params = {}) => <UserProfilePage {...params} />,
  },
  {
    path: "/app/mgmt/project/create",
    isProtected: true,
    renderer: (params = {}) => <NewProjectPage {...params} />,
  },
  {
    path: "/app/mgmt/project/my",
    isProtected: true,
    renderer: (params = {}) => <MyProjectsPage {...params} />,
  },
  {
    path: "/app/mgmt/project/project-details",
    isProtected: true,
    renderer: (params = {}) => <ProjectDetailPage {...params} />,
  },
  {
    path: "/app/mgmt/project/create-member",
    isProtected: true,
    renderer: (params = {}) => <AssignMemberPage {...params} />,
  },
  {
    path: "/app/mgmt/project/allocate-device",
    isProtected: true,
    renderer: (params = {}) => <AllocateDevicePage {...params} />,
  },
  {
    path: "/app/mgmt/customer/my",
    isProtected: true,
    renderer: (params = {}) => <MyCustomersPage {...params} />,
  },
  {
    path: "/app/mgmt/customer/all",
    isProtected: true,
    renderer: (params = {}) => <AllCustomersPage {...params} />,
  },
  {
    path: "/app/mgmt/customer/new-customer",
    isProtected: true,
    renderer: (params = {}) => <NewCustomerPage {...params} />,
  },
  {
    path: "/app/mgmt/customer/profile-customer",
    isProtected: true,
    renderer: (params = {}) => <ProfileCustomerPage {...params} />,
  },
  {
    path: "/app/mgmt/catalog/products/All",
    isProtected: true,
    renderer: (params = {}) => <AllProductsPage {...params} />,
  },
  {
    path: "/app/mgmt/catalog/products/create",
    isProtected: true,
    renderer: (params = {}) => <NewProductPage {...params} />,
  },
  ,
  {
    path: "/app/mgmt/catalog/configurators/create",
    isProtected: true,
    renderer: (params = {}) => <ConfiguratorPage {...params} />,
  },
  {
    path: "/app/mgmt/catalog/configurators/all",
    isProtected: true,
    renderer: (params = {}) => <AllCustomProductPage {...params} />,
  },
  {
    path: "/quote-create",
    isProtected: true,
    renderer: (params = {}) => <QuotePage {...params} />,
  },
  {
    path: "/quote-calci",
    isProtected: true,
    renderer: (params = {}) => <QuoteCalculaterPage {...params} />,
  },
  {
    path: "/app/quotations/my",
    isProtected: true,
    renderer: (params = {}) => <MyQuotePage {...params} />,
  },
  {
    path: "/app/quotations/all",
    isProtected: true,
    renderer: (params = {}) => <AllQuotationPage {...params} />,
  },
  {
    path: "/download-pdf",
    isProtected: true,
    renderer: (params = {}) => <Invoice {...params} />,
  },
  {
    path: "/download-appendix",
    isProtected: true,
    renderer: (params = {}) => <AppendixInvoice {...params} />,
  },
  {
    path: "/profile",
    isProtected: true,
    renderer: (params = {}) => <ProfilePage {...params} />,
  },
  {
    path: "/profile-update",
    isProtected: true,
    renderer: (params = {}) => <ProfileEditPage {...params} />,
  },
  {
    path: "/app/mgmt/catalog/upload-products",
    isProtected: true,
    renderer: (params = {}) => <ProductUploadListPage {...params} />,
  },
  {
    path: "/app/mgmt/user/changePassword",
    renderer: (params = {}) => <ChangePasswordPage {...params} />,
  },
  {
    path: "/app/mgmt/user/changePasswordOwn",
    renderer: (params = {}) => <ChangePasswordOwnPage {...params} />,
  },
  {
    path: "/app/mgmt/discount/all",
    renderer: (params = {}) => <AllDiscountsPage {...params} />,
  },
  {
    path: "/app/mgmt/discount/create",
    renderer: (params = {}) => <NewDiscountPage {...params} />,
  },

  {
    path: "/not-found-cover",
    renderer: (params = {}) => <PageNotFoundPage {...params} />,
  },
  {
    path: "/theme",
    renderer: (params = {}) => <ThemePage {...params} />,
  },
];

export default routes;
