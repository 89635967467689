import * as React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Check from "@mui/icons-material/Check";

import NotStartedIcon from "@mui/icons-material/NotStarted";
import PublicIcon from '@mui/icons-material/Public';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GavelIcon from '@mui/icons-material/Gavel';
import ReviewsIcon from '@mui/icons-material/Reviews';

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "light" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#000080",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#000080",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <NotStartedIcon />,
    2: <PublicIcon />,
    3: <ProductionQuantityLimitsIcon />,
    4: <SummarizeIcon />,
    5: <GavelIcon />,
    6: <ReviewsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? <Check  fontSize="small"/> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default ColorlibStepIcon;
