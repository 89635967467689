import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,

  // Briefcase as BriefcaseIcon,
  // Calendar as CalendarIcon,
  // ShoppingCart as ShoppingCartIcon,
  // Folder as FolderIcon,
  // Lock as LockIcon,
  // UserPlus as UserPlusIcon,
  // Shield as ShieldIcon,
  // AlertCircle as AlertCircleIcon,
  // Trello as TrelloIcon,
  // User as UserIcon,
  // Layout as LayoutIcon,
  // Edit as EditIcon,
  // DollarSign as DollarSignIcon,
  // Mail as MailIcon,
  // MessageCircle as MessageCircleIcon,
  // Share2 as ShareIcon,
  // Box as ProductIcon
} from "react-feather";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptIcon from "@mui/icons-material/ReceiptOutlined";

import { BUSINESS_TYPES, USER_ROLES } from "config";

const UserNavConfig = [
  {
    subheader: "Reports",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/app/dashboards/dashboard",
        role: [
          USER_ROLES.ADMIN,
          USER_ROLES.SALES_MANAGER,
          USER_ROLES.USER,
          USER_ROLES.CLIENT,
          USER_ROLES.SALES_AGENT,
          USER_ROLES.OWNER,
        ],
      },
      {
        title: "Dashboard Financial",
        icon: BarChartIcon,
        href: "/app/dashboards/dashboard-financial",
        role: [USER_ROLES.OWNER],
      },
    ],
  },
  {
    subheader: "Quotations",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "My Quotations",
        icon: PieChartIcon,
        href: "/app/quotations/my",
        role: [
          USER_ROLES.ADMIN,
          USER_ROLES.SALES_MANAGER,
          USER_ROLES.USER,
          USER_ROLES.CLIENT,
          USER_ROLES.SALES_AGENT,
          USER_ROLES.OWNER,
        ],
      },
      {
        title: "All Quotations",
        icon: BarChartIcon,
        href: "/app/quotations/all",
        role: [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.SALES_MANAGER],
      },
      {
        title: "Quotation Create",
        icon: RequestQuoteIcon,
        href: "/quote-create",
        role: [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.SALES_MANAGER],
      },
      {
        title: "Price Calculator",
        icon: CalculateIcon,
        href: "/quote-calci",
        role: [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.SALES_MANAGER],
      },
    ],
  },
  {
    subheader: "Management",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Catalog Management",
        icon: UsersIcon,
        href: "/app/mgmt/users",
        items: [
          {
            title: "Products",
            href: "/app/mgmt/catalog/products/all",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
          {
            title: "Custom Products",
            href: "/app/mgmt/catalog/configurators/all",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
          {
            title: "Upload Products",
            href: "/app/mgmt/catalog/upload-products",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
          //             {
          //   title: 'My Users',
          //   href: '/app/mgmt/user/my',
          //   role: [
          //     USER_ROLES.ADMIN, USER_ROLES.USER, USER_ROLES.CLIENT, USER_ROLES.SALES_AGENT, USER_ROLES.OWNER
          //   ],
          // },
        ],
      },
      {
        title: "User Management",
        icon: UsersIcon,
        href: "/app/mgmt/users",
        items: [
          // {
          //   title: 'My Users',
          //   href: '/app/mgmt/user/my',
          //   role: [
          //     USER_ROLES.ADMIN, USER_ROLES.USER, USER_ROLES.CLIENT, USER_ROLES.SALES_AGENT, USER_ROLES.OWNER
          //   ],
          // },
          {
            title: "All Users",
            href: "/app/mgmt/users/all",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
        ],
      },
      {
        title: "Customer Management",
        icon: UsersIcon,
        href: "/app/mgmt/device",
        items: [
          {
            title: "My Customers",
            href: "/app/mgmt/customer/my",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.SALES_MANAGER,
              USER_ROLES.USER,
              USER_ROLES.CLIENT,
              USER_ROLES.SALES_AGENT,
              USER_ROLES.OWNER,
            ],
          },
          {
            title: "All Customers",
            href: "/app/mgmt/customer/all",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
          {
            title: "Discount Settings",
            href: "/app/mgmt/discount/all",
            role: [
              USER_ROLES.ADMIN,
              USER_ROLES.OWNER,
              USER_ROLES.SALES_MANAGER,
            ],
          },
        ],
      },
    ],
  },
];

export default UserNavConfig;
