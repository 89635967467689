import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { useNavigate } from "react-router-dom";

import {
  removeClient,
  editClientTrigger,
  viewClientTrigger,
  blockClient,
  fetchMyClient,
} from "../../../../actions/clientAction";

function MyCustomersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myclient = useSelector((state) => state?.client?.myClients);
  // console.log(" myclient: ", myclient);
  const myclients = myclient ? myclient : [];
  const [modalBlock, setModalBlock] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [servi, setServi] = useState({});

  const viewClient = (data) => {
    // console.log("handle view project", data);
    dispatch(viewClientTrigger(data));
    navigate("/app/mgmt/customer/new-customer");
  };

  useEffect(() => {}, [myclients]);

  useEffect(() => {
    dispatch(fetchMyClient());
  }, []);

  const handleDelete = (clientId) => {
    // console.log("clientId", clientId);
    dispatch(removeClient(clientId));
  };

  const handleUpdate = (data) => {
    dispatch(editClientTrigger(data));
    navigate("/app/mgmt/customer/new-customer");
  };

  const handleBlock = (clientId) => {
    // console.log("clientId", clientId);
    dispatch(blockClient(clientId));
  };
  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={myclients}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleUpdate,
          true,
          handleBlock,
          viewClient,
          modalBlock,
          setModalBlock
        )}
        getRowId={(row) => row.clientId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default MyCustomersList;
