import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { Button, Grid, Card, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  removeUser,
  editUserTrigger,
  blockClient,
  fetchUsers,
  changePasswordTrigger,
} from "../../../../actions/usersAction";

function AllUsersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // console.log(" Users : ", user.users);
  const [isShown, setIsShown] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [servi, setServi] = useState({});

  const viewUsers = (data) => {
    // console.log("handle view project", data);
    dispatch(editUserTrigger(data));
    navigate("/app/mgmt/user/user-viewprofile");
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const handleUpdate = (data) => {
    dispatch(editUserTrigger(data));
    navigate("/app/mgmt/user/create");
  };

  useEffect(() => {}, [user.users]);
  const handleDelete = (userId) => {
    // console.log("userId", userId);
    dispatch(removeUser(userId));
  };

  const handleBlock = (userId) => {
    // console.log("userId", userId);
    dispatch(blockClient(userId));
  };
  const handleChangePassword = (data) => {
    dispatch(changePasswordTrigger(data));
    // console.log("userId", data.userId);
    navigate("/app/mgmt/user/changePassword");
  };

  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={user.users}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleUpdate,
          true,
          modalBlock,
          setModalBlock,
          handleBlock,
          handleChangePassword
          // viewUsers
        )}
        getRowId={(row) => row.userId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 100]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default AllUsersList;
