import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import ChangePasswordForm from "./ChangePasswordForm";

function ChangePassword() {
  const changePasswordDataToEdit = useSelector((state) => state.userEdit);
  // console.log("changePasswordEdit:", changePasswordDataToEdit);
  const passwordDataToEdit = changePasswordDataToEdit?.passWordDataToEdit;
  // console.log("passwordDataToEdit:", passwordDataToEdit);

  const isPasswordEdit = changePasswordDataToEdit?.isPasswordEdit;
  // console.log("isPasswordEdit:", isPasswordEdit);
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/app/mgmt/users/all");
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Change Password</title>
      </Helmet>
      <Grid mt={15} mb={10}>
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            {/* <CardHeader title="Change Password" /> */}
            <Divider />
            <CardContent>
              <ChangePasswordForm
                onSubmitSuccess={handleSubmitSuccess}
                isPasswordEdit={isPasswordEdit}
                passwordDataToEdit={passwordDataToEdit}
              />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </DashboardLayout>
  );
}

export default ChangePassword;
