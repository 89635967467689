import React from "react";
import { Helmet } from "react-helmet";

import { Main as MainLayout } from "layouts";

import { Grid, Typography } from "@mui/material";

import { APP_NAME } from "config";

function Home() {
    return (
        <MainLayout>
            <Helmet>
                <title>{APP_NAME} Home</title>
            </Helmet>
            <Grid sx={{ height: 600 }}>
                <Typography>
                    Welcome to Home Page
                </Typography>

            </Grid>

        </MainLayout>
    );
}

export default Home;