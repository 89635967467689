import axios from "utils/axios";

import {
  CREATE_QUTOTATION_URL,
  QUOTE_UPDATE_URL,
  QUOTE_REMOVE_URL,
  GET_MY_QUOTATION_LIST_URL,
  QUOTE_UPDATE_CUSTOMER_URL,
  QUOTE_UPDATE_ADD_ITEM_URL,
  GET_MY_QUOTATION_LIST_BY_ID_URL,
  FETCH_QUOTE_LIST_URL,
  FETCH_QUOTE_UPDATE_TNC,
  FETCH_QUOTE_BY_CRITERIA,
  QUOTE_UPDATE_STATUS_URL,
  ADD_TO_QOUTE,
  REMOVE_QOUTE_ITEMS,
  SEARCH_HOSE_ASSEMBLY_BY_CRITERIA,
  MY_QUOTE_REMOVE_URL,
} from "config/services";

class QuoteService {
  quoteGetById = (quoteId) =>
    new Promise((resolve, reject) => {
      let myQuotationUrl = `${GET_MY_QUOTATION_LIST_BY_ID_URL}${quoteId}`;
      axios
        .get(myQuotationUrl, {})
        .then((response) => {
          // console.log("clientService.fetchclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createQuote = (quoteInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", clientInfo);
      const payload = quoteInfo;
      axios
        .post(CREATE_QUTOTATION_URL, { payload })
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  removeQuote = (quoteId) =>
    new Promise((resolve, reject) => {
      // console.log("QuoteService.removeQuote called", quoteId);

      axios
        .post(QUOTE_REMOVE_URL, { payload: { quoteId } })
        .then((response) => {
          // console.log("QuoteService.removeQuote() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("QuoteService.removeQuote() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  removeMyQuotes = (quoteId) =>
    new Promise((resolve, reject) => {
      // console.log("QuoteService.removeQuote called", quoteId);

      axios
        .post(MY_QUOTE_REMOVE_URL, { payload: { quoteId } })
        .then((response) => {
          // console.log("QuoteService.removeQuote() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("QuoteService.removeQuote() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  removeQuoteItem = (skuid, quoteId) =>
    new Promise((resolve, reject) => {
      // console.log("QuoteService.removeQuoteItem called", skuid);

      axios
        .post(REMOVE_QOUTE_ITEMS, { payload: { skuid, quoteId } })
        .then((response) => {
          // console.log("QuoteService.removeQuoteItem() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("QuoteService.removeQuote() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateCustomerAddress = (quoteInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", clientInfo);
      const payload = quoteInfo;
      axios
        .post(QUOTE_UPDATE_CUSTOMER_URL, { payload })
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateuoteAddItem = (quoteItemInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", quoteItemInfo);
      const payload = quoteItemInfo;
      axios
        .post(QUOTE_UPDATE_ADD_ITEM_URL, { payload })
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  updateAddToQoute = (quoteItemInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", quoteItemInfo);
      const payload = quoteItemInfo;
      axios
        .post(ADD_TO_QOUTE, { payload })
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateCustomerTNC = (quoteInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", clientInfo);
      const payload = quoteInfo;
      axios
        .post(FETCH_QUOTE_UPDATE_TNC, { payload })
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  // removeClient = (payload) =>
  //   new Promise((resolve, reject) => {
  //     // console.log("clientService.removeclient called", payload);

  //     axios
  //       .post(QUOTE_REMOVE_URL, { payload })
  //       .then((response) => {
  //         // console.log("clientService.removeClient () resp:", response);
  //         if (response?.data?.error) {
  //           // console.log("Failed to fetch records");
  //           reject(response.data.message);
  //         } else {
  //           if (response?.data?.payload) {
  //             resolve(response?.data?.payload);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           // console.log("usersService.removeUser() error:", error);
  //           reject(error?.response?.data);
  //         }
  //         reject(error);
  //       });
  //   });

  updateQuote = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);

      axios
        .post(QUOTE_UPDATE_URL, { payload })
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  // fetchQuoteList = (pageNo) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .get(FETCH_QUOTE_LIST_URL, { params: { pageNo } })
  //       .then((response) => {
  //         if (response?.data?.error) {
  //           reject(response.data.message);
  //         } else {
  //           if (response?.data?.payload) {
  //             resolve(response?.data?.payload);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  fetchMyQuoteList = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_MY_QUOTATION_LIST_URL, {})
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  fetchQuoteByCriteria = (supplierId, quoteId, quoteDate, status, pageNo) => {
    return new Promise((resolve, reject) => {
      let params = "";

      if (supplierId !== undefined && supplierId !== "") {
        params += `&supplierId=${supplierId}`;
      }

      if (quoteId !== undefined && quoteId !== "") {
        params += `&quoteId=${quoteId}`;
      }

      if (quoteDate !== undefined && quoteDate !== "") {
        params += `&quoteDate=${quoteDate}`;
      }

      if (status !== undefined && status !== "") {
        params += `&status=${status}`;
      }

      let criteriaUrl = `${FETCH_QUOTE_BY_CRITERIA}?${params}&pageNo=${pageNo}`;

      axios
        .get(criteriaUrl, {})
        .then((response) => {
          // console.log("QuoteService.fetchQuoteByCriteria() resp:", response);
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  };

  updateQuoteStatus = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("1--> quote.updateQuoteStatus called", payload);

      axios
        .post(QUOTE_UPDATE_STATUS_URL, { payload })
        .then((response) => {
          // console.log("1--> quoteService.updateQuoteStatus () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("1--> quoteService.updateQuoteStatus() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const quoteService = new QuoteService();

export default quoteService;
