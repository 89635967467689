import axios from "utils/axios";

import {
  CREATE_CLIENT_URL,
  UPDATE_CLIENT_URL,
  REMOVE_CLIENT_URL,
  BLOCK_CLIENT_URL,
  GETALL_CLIENT_URL,
  GET_CLIENT_BY_ID_URL,
  GET_MY_CLIENT_URL,
} from "config/services";

class ClientService {

  fetchClient = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GETALL_CLIENT_URL , {})
        .then((response) => {
          // console.log("clientService.fetchclient() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchMyClient = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_MY_CLIENT_URL, {})
        .then((response) => {
          // console.log("clientService.fetchclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createClient = (clientInfo) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.createclient called", clientInfo);
      const payload = clientInfo;
      axios
        .post(CREATE_CLIENT_URL, {payload})
        .then((response) => {
          // console.log("clientService.createclient() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.addUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  
  removeClient = (clientId) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.removeclient called", payload);

      axios
        .post(REMOVE_CLIENT_URL, {payload:{clientId}})
        .then((response) => {
          // console.log("clientService.removeClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });


    updateClient = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);

      axios
        .post( UPDATE_CLIENT_URL, {payload})
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });  
    
    
    blockClient = (clientId) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.blockclient called", clientId);

      axios
        .post(BLOCK_CLIENT_URL, {payload:{clientId}})
        .then((response) => {
          // console.log("clientService.blockClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

    fetchClientById = (clientId) =>
    new Promise((resolve, reject) => {
      let detailsUrl = `${GET_CLIENT_BY_ID_URL}?clientId=${clientId}`;
      axios
        .get(detailsUrl, {})
        .then((response) => {
          // console.log("projectService.fetchProjectDetail() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.fetchProjectDetail() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

}

const clientService = new ClientService();

export default clientService;
