import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  MenuItem,
} from "@mui/material";
import { createAllocateDevice } from "../../../../actions/projectAction";

const AllocateDeviceForm = ({ onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userId = auth.user.userId;
  const projectDetail = useSelector((state) => state.projectDetail?.projectDetails);
  const projectInfo = projectDetail?.projectInfo;
  // console.log("projectDetails veiw project", projectDetail.projectDetails);
  // console.log("projectInfo veiw project", projectInfo);
  // console.log("SignInEmail: auth", auth, " user: ", userId);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        projectId: projectInfo?.projectId,
        deviceId: "",
        remarks: "",
      }}
      validationSchema={Yup.object().shape({
        projectId: Yup.string().required("Project ID is required"),
        deviceId: Yup.string().required("Device ID is required"),
        remarks: Yup.string().required("Remarks is required").max(500),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(createAllocateDevice(values));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Container maxWidth="md">
            <Card sx={{ boxShadow: 3, padding: 4, mb: 3, m: 3 }}>
              <form onSubmit={handleSubmit}>
                <Box mt={2} mb={2}>
                  <Typography variant="h6">Allocate Device</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="projectId"
                      name="projectId"
                      label="Project ID"
                      value={values.projectId}
                      onChange={handleChange}
                      fullWidth
                      disabled
                      error={touched.projectId && Boolean(errors.projectId)}
                      helperText={touched.projectId && errors.projectId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="deviceId"
                      name="deviceId"
                      label="Device ID"
                      fullWidth
                      value={values.deviceId}
                      onChange={handleChange}
                      error={touched.deviceId && Boolean(errors.deviceId)}
                      helperText={touched.deviceId && errors.deviceId}
                    />
                    {/* <Autocomplete
                      options={options}
                      onSelect={(option) => setValue(option.value)}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="remarks"
                      name="remarks"
                      label="Remarks"
                      multiline
                      rows={4}
                      fullWidth
                      value={values.remarks}
                      onChange={handleChange}
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" type="submit">
                      Allocate Device
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Container>
        );
      }}
    </Formik>
  );
};
export default AllocateDeviceForm;
