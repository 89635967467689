import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";

import clsx from "clsx";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Dashboard as DashboardLayout } from "layouts";
import { Helmet } from "react-helmet";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Link,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { DataGrid } from "@mui/x-data-grid";

// import { usdPrice, numberFormat } from "src/utils/formats";

import { useSnackbar } from "notistack";

// import {
//   uploadCPC,
//   addCPCDataToProjectAnalysis,
// } from "src/actions/projectAction";

// import { processCPCToAnalysis } from "src/utils/analysis";

import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";

// import Label from "src/components/Label";

import * as XLSX from "xlsx/xlsx.mjs";
import { moneyFormatter } from "../../../../../utils/formatUtils";
import { uploadProducts } from "../../../../../actions/catalogAction";

const EXTENSIONS = ["xlsx", "xls", "csv"];

const useStyles = makeStyles((theme) => ({
  root: {},
}));
const headCells = [
  // { field: "buisnessId", headerName: "Business Id", width: 120 },
  // { field: "productId", headerName: "Product Id", width: 120 },
  {
    field: "name",
    headerName: "Product Name",
    width: 250,
  },
  {
    field: "skuid",
    headerName: "SKU",
    width: 200,
  },
  {
    field: "brandName",
    headerName: "Brand Name",
    width: 150,
  },
  {
    field: "brandPart",
    headerName: "Brand Part",
    width: 150,
  },
  {
    field: "catagory",
    headerName: "Catagory",
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    width: 150,
  },
  {
    field: "gst",
    headerName: "GST",
    width: 150,
  },
  {
    field: "uom",
    headerName: "Unit Of Measure",
    width: 150,
  },
  {
    field: "price",
    headerName: "MRP",
    valueGetter: (params) => moneyFormatter(params.row.price?.salesPrice),
    sortable: false,
    align: "right",
    width: 120,
  },
];
export const OldheadCells = [
  //{ field: "id", headerName: "Id", minWidth: 10 },

  { field: "SkuId", headerName: "SKU Id", minWidth: 125 },

  {
    field: "clientProvideDescription",

    headerName: "Client Provided Description",

    minWidth: 250,

    renderCell: (params) => {
      return <div style={{ whiteSpace: "normal" }}>{params.value}</div>;
    },
  },

  {
    field: "assemblyDescription",

    headerName: "Assembly Description",

    minWidth: 250,

    renderCell: (params) => {
      return <div style={{ whiteSpace: "normal" }}>{params.value}</div>;
    },
  },

  {
    field: "contractCode",

    headerName: "Contact Code",

    minWidth: 200,
  },

  {
    field: "costType",

    headerName: "Cost Type",

    minWidth: 200,
  },

  {
    field: "clientProvidedCost",

    headerName: "Client Provided Cost",

    minWidth: 100,

    // ...usdPrice,
  },

  //  { field: "assetId", headerName: "Asset Id", minWidth: 20 },

  //  { field: "assetName", headerName: "Asset Name", minWidth: 20 },

  //  { field: "tenantId", headerName: "Tenant Id", minWidth: 20 },

  //  { field: "tenantName", headerName: "Tenant Name", minWidth: 20 },

  {
    field: "costSource",

    headerName: "Cost Source",

    minWidth: 250,
  },
];

function ProductUploadList({ className, ...rest }) {
  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [pageSize, setPageSize] = useState(25);

  const [isUploadReady, setUploadReady] = useState(false);

  const [colDefs, setColDefs] = useState([]);

  const [productsData, setProductsData] = useState([]);

  const [tenants, setTenants] = useState([]);

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  // console.log("productsData: ", productsData);

  // console.log("Selected ProductsList: ", selectedProducts);

  const getExtension = (file) => {
    const parts = file.name.split(".");

    const extension = parts[parts.length - 1];

    return EXTENSIONS.includes(extension); // return boolean
  };

  // && data[2][0] === "Building Type:"

  const convertToJson = (headers, data) => {
    // console.log("convertToJson: headers", headers, data);

    const rows = [];

    data.forEach((row) => {
      // console.log("convertToJson: row: ", row);

      let rowData = {};

      if (row[0] !== undefined && row[1] !== undefined) {
        // SKU Id	Product Name	Category	UOM	Size	MRP	Brand Name	Brand Part	Description	GST
        rowData["skuid"] = row[0];

        rowData["name"] = row[1];

        rowData["catagory"] = row[2];
        rowData["uom"] = row[3];
        rowData["size"] = row[4];
        rowData["price"] = {
          mrp: row[5],
          salesPrice: row[5],
          rate: row[5],
        };
        rowData["brandName"] = row[6];
        rowData["brandPart"] = row[7];
        rowData["description"] = row[8];
        rowData["gst"] = row[9];

        // console.log("convertToJson rowData", rowData);

        rows.push(rowData);
      }
    });

    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];

    // console.log("importExcel: ", file);

    const reader = new FileReader();

    reader.onload = (event) => {
      //parse data

      const bstr = event.target.result;

      const workBook = XLSX.read(bstr, { type: "binary" });

      //get first sheet

      const workSheetName = workBook.SheetNames[0];

      const workSheet = workBook.Sheets[workSheetName];

      //convert to array

      // console.log("importExcel() : worksheet", workSheetName, workSheet);

      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

      // console.log("importExcel(): fileData: ", fileData)

      const headers = fileData[0];

      const heads = headers.map((head) => ({ title: head, field: head }));

      setColDefs(heads);

      //removing header

      fileData.splice(0, 1);

      setProductsData(convertToJson(headers, fileData));

      setUploadReady(true);

      // console.log("importExcel: data:", productsData);
    };

    if (file) {
      if (getExtension(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setProductsData([]);

      setColDefs([]);
    }
  };

  // const saveData = () =>{

  //   console.log("Upload Data", cpcData);

  // }

  async function saveData() {
    // console.log("Upload Data", productsData);

    try {
      const payload = productsData.map((product) => ({
        businessId: "0",
        type: "SIMPLE",
        skuid: product.skuid,
        category: product.catagory,
        name: product.name,
        description: product.description,
        hsnCode: "0",
        uom: product.uom,
        brandName: product.brandName,
        brandPart: product.brandPart,
        size: product.size,
        notes: "Uploaded Bulk",
        cost: {
          rate: product.price?.mrp || 0,
        },
        price: {
          mrp: product.price?.mrp || 0,
          rate: product.price?.rate || 0,
          salesPrice: product.price?.salesPrice || 0,
        },
        gst: product.gst || 0,
      }));
      await dispatch(uploadProducts(payload));
      setUploadReady(false);

      enqueueSnackbar("Products Uploaded Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Products Upload Failed", {
        variant: "error",
      });
    }
  }

  // const handleRowSelection = (e) => {
  //   // prints correct indexes of selected rows

  //   console.log("handleRowSelection", e, e.selectionModel);

  //   // cpcData

  //   // const unaddedCartItems = addedItems.filter( addedItem => {

  //   // return !cartItems.find( cartItem => cartItem.id === addedItem.productId );

  //   // });

  //   const selectedItems = e;

  //   // const cpcItems = selectedItems.filter( selectedItem => {

  //   //   return cpcData.find( item => item.cpcId === selectedItem);

  //   // });

  //   const productsItems = productsData.filter((productsItems) => {
  //     return selectedItems.find(
  //       (selectedItem) => productsItems.productId === selectedItem
  //     );
  //   });

  //   console.log("CPC Items: ", productsItems);

  //   // missing the first row selected

  //   setSelectedProducts(productsItems);

  //   // console.log(select);
  // };

  // console.log("CPCList: ", cpcData);

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Upload Products</title>
      </Helmet>
      <Grid
        container
        fullWidth
        spacing={3}
        margin={2}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Card fullWidth>
            <CardHeader title="Upload Products" />
            <Divider />
            <CardContent>
              <Grid mt={2}>
                {/* <div> */}
                {!isUploadReady && (
                  <label htmlFor="upload-assembly">
                    <input
                      style={{ display: "none" }}
                      id="upload-assembly"
                      name="upload-assembly"
                      type="file"
                      onChange={importExcel}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                    >
                      Upload Products
                    </Button>
                  </label>
                )}
                {isUploadReady && (
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    onClick={(e) => {
                      saveData(e);
                      navigate("/app/mgmt/catalog/products/all");
                    }}
                  >
                    Save Products
                  </Button>
                )}{" "}
                {/* </div> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardHeader title="List Of Products to Import " />
            <Divider />

            <CardContent>
              <Box fullWidth sx={{ height: 600, width: 1000 }}>
                <DataGrid
                  sx={{
                    ".MuiDataGrid-columnsPanel": {},

                    mt: 2,

                    mb: 2,
                  }}
                  // checkboxSelection
                  getRowId={(row) => row.skuid}
                  rows={productsData}
                  columns={headCells}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[25, 50, 75]}

                  // onSelectionModelChange={handleRowSelection}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ProductUploadList;
