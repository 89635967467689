import React, { useState, useEffect } from "react";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot } from "@mui/x-charts/LineChart";
import { useSelector } from "react-redux";
import { ChartsTooltip } from "@mui/x-charts";
import { Grid } from "@mui/material";

export const ChartsData = () => {
  const chartsData = useSelector((state) => state.dashBoard?.chartsData);

  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    if (chartsData && chartsData.currentMonthQuote) {
      const dataset = chartsData.currentMonthQuote.map((data) => ({
        date: new Date(data._id).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        }),
        count: data.count,
        totalPrice: data.totalAmount,
      }));

      setBarChartData(dataset);
    }
  }, [chartsData]);

  const series = barChartData
    ? [
        {
          type: "line",
          // stack: "",
          yAxisKey: "count",
          color: "red",
          data: barChartData?.map((data) => data?.count),
        },
        {
          type: "bar",
          yAxisKey: "totalPrice",
          color: "#000080",
          stack: "",
          data: barChartData?.map((data) => data?.totalPrice),
        },
      ]
    : [];
  if (!chartsData || !barChartData.length) {
    return null;
  }
  return (
    <div>
      <ChartContainer
        series={series}
        width={500}
        height={390}
        xAxis={[
          {
            id: "date",
            data: barChartData ? barChartData?.map((data) => data?.date) : [],
            scaleType: "band",
          },
        ]}
        yAxis={[
          {
            id: "count",
            scaleType: "linear",
            position: "left",
            label: "Count",
          },
          {
            id: "totalPrice",
            scaleType: "linear",
            position: "left",
            label: "Total Amount",
            labelProps: {
              dx: -40,
            },
          },
        ]}
      >
        <BarPlot />
        <LinePlot />
        <ChartsXAxis label="Date" position="bottom" axisId="date" />
        <ChartsYAxis label="Count" position="right" axisId="count" />
        <ChartsYAxis label="Total Amount" position="left" axisId="totalPrice" />
        <ChartsTooltip />
      </ChartContainer>
    </div>
  );
};
