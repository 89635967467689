import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
  ListItemText,
  List,
  ListItemAvatar,
  ListItem,
  CardHeader,
  Avatar,
  Button,
} from "@mui/material";
import { AccountCircle, Call, Email } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { Dashboard as DashboardLayout } from "layouts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editUserTrigger } from "../../../actions/usersAction";



const Profile = () => {
  const user = useSelector((state) => state?.auth?.user);
  // console.log(user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = user;

  const editProfile = () => {
    dispatch(editUserTrigger(data));
    navigate("/profile-update");
  };
  const changePassword = () => {
    // dispatch(editUserTrigger(data));
    navigate("/app/mgmt/user/changePasswordOwn");
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        mt={15}
        mb={10}
      >
        <Grid item xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>
                <CardHeader
                  title="Personal Information"
                  //   subheader="Edit"
                  // onClick={handleEdit}
                ></CardHeader>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  startIcon={<ModeEditOutlineIcon />}
                  onClick={changePassword}
                >
                  Change Password
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  startIcon={<ModeEditOutlineIcon />}
                  onClick={editProfile}
                >
                  Edit Profile
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <AccountCircle />
                  </ListItemAvatar>

                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body"
                          color="text.primary"
                        >
                          {user?.name}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* <Divider variant="inset" component="li" /> */}

                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Call />
                  </ListItemAvatar>
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {user?.mobile}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* <Divider variant="inset" component="li" /> */}

                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Email />
                  </ListItemAvatar>

                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {user?.email}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <PersonIcon />
                  </ListItemAvatar>

                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {user?.userType}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <BadgeIcon />
                  </ListItemAvatar>

                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {user?.role}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> */}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Profile;
