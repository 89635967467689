import axios from "axios";

const gateway_port = process.env.REACT_APP_API_PORT || 5000;
const gateway_protocol = process.env.REACT_APP_PROTOCOL || "https";
const api_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
const api_timeout = process.env.REACT_APP_API_TIMEOUT || 30000;

const port = ( gateway_port === "80" ) ? "" : ":" + gateway_port;

const url = gateway_protocol + "://" + api_hostname + port;

const instance = axios.create({
  baseURL: url,
  timeout: api_timeout,
});

export default instance;
