import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewProductForm from "./NewDiscountForm";
import { useSelector } from "react-redux";

function NewDiscount() {
  const discountEdit = useSelector((state) => state.user);
  const discountDataToEdit = discountEdit.discountDataToEdit;
  const isDiscountEdit = discountEdit.isDiscountEdit;
  // console.log("discount data to edit", discountDataToEdit, isDiscountEdit);
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/app/mgmt/discount/all");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Discount</title>
      </Helmet>
      <Grid mt={15} mb={10} sx={{ height: "65vh" }}>
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader
              title={isDiscountEdit ? "Edit Discount" : "Add New Discount"}
            />
            <Divider />
            <CardContent>
              <NewProductForm
                onSubmitSuccess={handleSubmitSuccess}
                data={discountDataToEdit}
                isDiscountEdit={isDiscountEdit}
              />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </DashboardLayout>
  );
}

export default NewDiscount;
