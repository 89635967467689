import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Button,
  Container,
} from "@mui/material";
import AllProjectsList from "./AllProjectsList";
import { useDispatch } from "react-redux";
import {
  createProjectTrigger,
  deleteProject,
  editProjectTrigger,
  fetchAllocateList,
  fetchMembersList,
  fetchProjectDetail,
  fetchProjectInfo,
} from "../../../../actions/projectAction";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function AllProjects() {
  const [servi, setServi] = useState({});
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (serviceRow) => {
    setServi(serviceRow);
    setIsShown((current) => !current);
    dispatch(createProjectTrigger());
    navigate("/app/mgmt/project/create");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - AllProjects</title>
      </Helmet>
      <Grid
        mt={15}
        mb={10}
        sx={{ display: "flex", justifyContent: "center", ml: 5 }}
      >
        <Card sx={{ boxShadow: 3, width: "70%" }}>
          <CardContent>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <CardHeader title={"All Project List"} />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "end" }}>
                <Button
                  // href="/app/mgmt/project/create"
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                >
                  <AddIcon />
                  New Project
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{mb:3}}/>
            <AllProjectsList />
          </CardContent>
        </Card>
      </Grid>
    </DashboardLayout>
  );
}

export default AllProjects;
