import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  Autocomplete,
} from "@mui/material";
import { USER_TYPES, BUSINESS_ID } from "config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createUsers, updateUser } from "../../../../actions/usersAction";

import { useNavigate } from "react-router-dom";

const UserProfile = ({ onSubmitSuccess, data, isUserEdit }) => {
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  // const name = auth.user.name;

  const navigate = useNavigate();

  // console.log("SignInEmail: auth", auth, " user: ",name);
  // const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        userId: data ? data.userId : "",
        name: data ? data.name : "",
        mobile: data ? data.mobile : "",
        email: data ? data.email : "",
        userType: data ? data.userType : "",
        businessId: BUSINESS_ID,
        password: data ? data.password : "",
      }}
      validationSchema={Yup.object().shape({
        // userId: Yup.number(),
        name: Yup.string().max(80),
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10),
        email: Yup.string().email().min(5).max(80),
        userType: Yup.string().max(100),
        password: Yup.string().max(100),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // if (data) {
          //   await dispatch(updateUser(values));
          // } else {
          //   await dispatch(createUsers(values));
          // }
          onSubmitSuccess(navigate("/app/mgmt/users/all"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  required
                  InputProps={{ readOnly: true }}
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.userType}
                  onChange={(event, newValue) => {
                    setFieldValue("userType", newValue);
                  }}
                  disablePortal
                  id="userType"
                  InputProps={{ readOnly: true }}
                  options={USER_TYPES}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="User Type"
                        required
                        error={Boolean(touched.userType && errors.userType)}
                        helperText={touched.userType && errors.userType}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  error={Boolean(touched.mobile && errors.mobile)}
                  fullWidth
                  helperText={touched.mobile && errors.mobile}
                  label="Enter Mobile Number"
                  name="mobile"
                  InputProps={{ readOnly: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 10 }}
                  required
                  value={values.mobile}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  type="email"
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{ readOnly: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  required
                  InputProps={{ readOnly: true }}
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // disabled={!isValid}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default UserProfile;
