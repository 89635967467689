import { Grid, Box } from "@mui/material";
import MainCard from "../../../components/MainCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const QuoteCardList = () => {
  const quotesData = useSelector(
    (state) => state?.dashBoard?.quotesData?.totalQuoteCount
  );
  // console.log(" quotesData: ", quotesData);
  useEffect(() => {}, [quotesData]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MainCard
            title={"Total Numbers Of Quotations"}
            // cardColor={item.backgroundColor}4400a5
            cardColor={"#000080"}
            currentData={quotesData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuoteCardList;
