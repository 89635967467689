/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  GET_ALL_CLIENT,
  GET_ALL_CLIENT_SUCCESS,
  GET_ALL_CLIENT_FAILURE,
  CLIENT_EDIT_TRIGGER,
  CLIENT_CREATE_TRIGGER,
  CLIENT_CREATE_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
  GET_CLIENT_BY_ID,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_BY_ID_FAILURE,
  CLIENT_VIEW_TRIGGER,
  GET_MY_CLIENT,
  GET_MY_CLIENT_SUCCESS,
  GET_MY_CLIENT_FAILURE,
} from "actions/clientAction";

import {
  REGISTER_ADDRESS_UPDATE_SUCCESS,
  OTHER_ADDRESS_UPDATE_SUCCESS,
  REGISTER_ADDRESS_EDIT_TRIGGER,
  OTHER_ADDRESS_EDIT_TRIGGER,
  OTHER_ADDRESS_TRIGGER,
  REGISTER_ADDRESS_TRIGGER,
} from "actions/registeredAddressAction";

const initialState = {
  clients: [],
  clientDataToEdit: undefined,
  isClientEdit: false,
  registerDataToEdit: undefined,
  isRegisterEdit: false,
  otherEditAddress: [],
  clientById: undefined,
  isClientView: false,
  clientDataToView: undefined,
  myClients: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLIENT:
      {
        return produce(state, (draft) => {
          draft.clients = [];
        });
      }
      break;

    case GET_ALL_CLIENT_SUCCESS:
      {
        const { client } = action.payload;
        // console.log("clientReducer: CLIENT_FETCH_SUCCESS ", client);

        return produce(state, (draft) => {
          draft.clients = client;
        });
      }
      break;

    case GET_ALL_CLIENT_FAILURE:
      {
        return produce(state, (draft) => {
          draft.clients = [];
        });
      }
      break;

    case GET_MY_CLIENT:
      {
        return produce(state, (draft) => {
          draft.myClients = [];
        });
      }
      break;
    case GET_MY_CLIENT_SUCCESS:
      {
        const { myclient } = action.payload;
        // console.log("clientReducer: GET_MY_CLIENT_SUCCESS ", myclient);

        return produce(state, (draft) => {
          draft.myClients = myclient;
        });
      }
      break;
    case GET_MY_CLIENT_FAILURE:
      {
        return produce(state, (draft) => {
          draft.myClients = [];
        });
      }
      break;
    case GET_CLIENT_BY_ID:
      {
        return produce(state, (draft) => {
          draft.clientById = [];
        });
      }
      break;

    case GET_CLIENT_BY_ID_SUCCESS:
      {
        const { clientById } = action.payload;
        console.log("projectReducer: PROJECT_FETCH_SUCCESS ", clientById, action.payload);

        return produce(state, (draft) => {
          draft.clientById = clientById;
        });
      }
      break;

    case GET_CLIENT_BY_ID_FAILURE:
      {
        return produce(state, (draft) => {
          draft.quote = [];
        });
      }
      break;
    case REGISTER_ADDRESS_EDIT_TRIGGER:
    case OTHER_ADDRESS_EDIT_TRIGGER:
      {
        // console.log("projectReducer() PROJECT_EDIT_TRIGGER", action.payload);
        const { otherAddress } = action.payload;
        // console.log("otherAddress", otherAddress, action.payload);
        return produce(state, (draft) => {
          draft.otherEditAddress = otherAddress;
        });
      }
      break;
    case CLIENT_EDIT_TRIGGER:
      {
        // console.log("projectReducer() PROJECT_EDIT_TRIGGER", action.payload);
        const { clientEdit } = action.payload;
        // console.log("clientReducerEdit", clientEdit);
        return produce(state, (draft) => {
          draft.isClientEdit = true;
          draft.clientDataToEdit = clientEdit;
        });
      }
      break;
    case CLIENT_VIEW_TRIGGER:
      {
        // console.log("projectReducer() CLIENT_VIEW_TRIGGER", action.payload);
        const { clientEdit } = action.payload;
        // console.log("clientReducerView", clientEdit);
        return produce(state, (draft) => {
          draft.isClientView = true;
          draft.clientDataToView = clientEdit;
        });
      }
      break;
    case OTHER_ADDRESS_TRIGGER:
    case REGISTER_ADDRESS_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.registerDataToEdit = undefined;
          draft.isRegisterEdit = false;
        });
      }
      break;
    case CLIENT_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.clientDataToEdit = undefined;
          draft.isClientEdit = false;
        });
      }
      break;
    case CLIENT_UPDATE_SUCCESS:
    case CLIENT_CREATE_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.clientDataToEdit = undefined;
          draft.isClientEdit = false;
        });
      }
      break;
    case REGISTER_ADDRESS_UPDATE_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.registerDataToEdit = undefined;
          draft.isRegisterEdit = false;
        });
      }
      break;
    case OTHER_ADDRESS_UPDATE_SUCCESS:
    case CLIENT_CREATE_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.clientDataToEdit = undefined;
          draft.isClientEdit = false;
        });
      }
      break;
    default:
      {
        return state;
      }
      break;
  }
};

export default clientReducer;
