import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  Autocomplete,
  Divider,
} from "@mui/material";
import { PRODUCT_TYPES, PRODUCT_CATEGORY, UOM, BUSINESS_ID } from "config";
import {
  updateAssembledProducts,
  createAssembleProduct,
} from "../../../../../actions/catalogAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const AssembleProduct = ({
  onSubmitSuccess,
  data,
  isAssembledProductEdit,
  handleNext,
  handleBack,
  activeStep,
}) => {
  const dispatch = useDispatch();

  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessId: BUSINESS_ID,
        skuId: data?.skuid ? data?.skuid : "",
        // products: "",
        name: data?.name ? data?.name : "",
        description: data?.description ? data?.description : "",
        hsnCode: data?.hsnCode ? data?.hsnCode : "",
        uom: data?.uom ? data?.uom : "Qty",
        discount: data?.discount ? data?.discount : 0,
        notes: data?.notes ? data?.notes : "",
      }}
      validationSchema={Yup.object().shape({
        skuId: Yup.string().required("SKU Id is required").max(100),
        name: Yup.string().required("Name is required").max(100),
        description: Yup.string().required(),

        // hsnCode: Yup.string().required("hsn Code Id is required").max(100),
        // uom: Yup.string()
        //   .required("Staus is required")
        //   .max(100),
        // discount: Yup.number().max(50).min(0),
        notes: Yup.string().max(1000),
      })}
      onSubmit={async (values, { setErrors, setuom, setSubmitting }) => {
        try {
          if (data) {
            await dispatch(
              updateAssembledProducts(data.assembledProductId, values)
            );
          } else {
            await dispatch(createAssembleProduct(values));
          }
          handleNext();
          // onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setuom({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Card sx={{ boxShadow: 3, padding: 2, mb: 3, width: "100%" }}>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <Typography variant="h5">
                  {isAssembledProductEdit
                    ? "Update Assembled Product"
                    : "Create Assemble Product"}
                </Typography>
              </Box>
              <Divider />
              <Grid container spacing={2} pt={2}>
                {/* <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="projectId"
                      name="projectId"
                      label="Project Id"
                      value={values.projectId}
                      onChange={handleChange}
                      error={touched.projectId && Boolean(errors.projectId)}
                      helperText={touched.projectId && errors.projectId}
                    />
                  </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    required
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="skuId"
                    name="skuId"
                    label="SKU Id"
                    required
                    value={values.skuId}
                    onChange={handleChange}
                    error={touched.skuId && Boolean(errors.skuId)}
                    helperText={touched.skuId && errors.skuId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    multiline
                    required
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="hsnCode"
                    name="hsnCode"
                    label="Hsn Code"
                    // required
                    value={values.hsnCode}
                    onChange={handleChange}
                    error={touched.hsnCode && Boolean(errors.hsnCode)}
                    helperText={touched.hsnCode && errors.hsnCode}
                  />
                </Grid>

                {/* <Grid item xs={12} md={6}>
                    <Autocomplete
                      fullWidth
                      value={values.products}
                      onChange={(event, newValue) => {
                        setFieldValue("products", newValue);
                      }}
                      disablePortal
                      id="products"
                      options={PRODUCT_CATEGORY}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Product Category" />
                        </>
                      )}
                    />
                  </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    value={values.uom}
                    onChange={(event, newValue) => {
                      setFieldValue("uom", newValue);
                    }}
                    disablePortal
                    id="uom"
                    options={UOM}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Uom"
                          error={Boolean(touched.uom && errors.uom)}
                          helperText={touched.uom && errors.uom}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="discount"
                    name="discount"
                    label="Discount(%)"
                    type="number"
                    value={values.discount}
                    onChange={handleChange}
                    error={touched.discount && Boolean(errors.discount)}
                    helperText={touched.discount && errors.discount}
                  />
                </Grid> */}
                <Grid item xs={6} sm={12}>
                  <TextField
                    fullWidth
                    id="notes"
                    name="notes"
                    label="Notes"
                    value={values.notes}
                    onChange={handleChange}
                    error={touched.notes && Boolean(errors.notes)}
                    helperText={touched.notes && errors.notes}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBackIcon />}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Grid item xs="auto">
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {isAssembledProductEdit
                            ? "Update Assemble Product"
                            : "Create Assemble Product"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        );
      }}
    </Formik>
  );
};
export default AssembleProduct;
