import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "./mse.png";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { dateFormatter, moneyFormatter } from "utils/formatUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mseLogo from "./mse.png";
import mindsclikLogo from "./logo_.png";

// Register font
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "500px",
  },
  pdfPage: {
    fontSize: 10,
    backgroundColor: "white",
    padding: "25px",
  },
  text: {
    marginTop: "3px",
  },
  section: {
    padding: "10px",
    flexGrow: 5,
    borderBottom: "1px solid black",
    backgroundColor: "white",
  },
  itemListHead: {
    flexDirection: "row",
    // backgroundColor: "#FFFF00", // Yellow background for header
    fontFamily: "Lato Bold",
  },
  itemList: {
    flexDirection: "row",
    fontSize: 8,
  },
  cell: {
    padding: 5,
    border: "0.3px solid black",
    textAlign: "center",
    overflow: "hidden",
  },
  table: {
    // padding: 4,
    // backgroundColor: "white",
    marginTop: "30px",
    // border: "1px solid black",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "2px solid grey",
    paddingBottom: "10px",
  },
  logoSection: {
    height: "50%",
    width: "30%",
    marginLeft: "25px",
    marginTop: "10px",
  },
  titleSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  orderIdSection: {
    fontFamily: "Lato Bold",
    padding: "10px",
    height: "auto",
    flexGrow: 1,
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "column-reverse",
    marginLeft: "350px",
  },
  signiture: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "50px",
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },
  invoiceT: {
    fontSize: "20px",
    color: "black",
    letterSpacing: "6px",
  },
  detailsHeader: {
    flexDirection: "row",
    border: "1px solid grey",
    padding: "5px",
    // backgroundColor: "blue", // Light blue color for Details section
    fontFamily: "Lato Bold",
    textAlign: "center",
  },
  hoseHeader: {
    flexDirection: "row",
    border: "1px solid grey",
    // backgroundColor: "#FFFF00", // Yellow color for Hose section
    fontFamily: "Lato Bold",
    padding: "5px",
    textAlign: "center",
  },
  fittingHeaderA: {
    flexDirection: "row",
    border: "1px solid grey",
    // backgroundColor: "blue", // Light green color for Fitting sections
    fontFamily: "Lato Bold",
    padding: "5px",
    textAlign: "center",
  },
  fittingHeaderB: {
    flexDirection: "row",
    border: "1px solid grey",
    // backgroundColor: "green", // Light green color for Fitting sections
    fontFamily: "Lato Bold",
    padding: "5px",
    textAlign: "center",
  },
  totalHeader: {
    flexDirection: "row",
    border: "1px solid grey",
    // backgroundColor: "white", // White color for Total section
    fontFamily: "Lato Bold",
    padding: "5px",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
  },
  tableRow: {
    flexDirection: "row",
    fontSize: 8,
  },
  smallCell: {
    padding: 2,
    border: "0.3px solid black",
    textAlign: "center",
    overflow: "hidden",
  },
  OrderIdSection: {
    fontFamily: "Lato Bold",
    padding: "10px",
    height: "auto",
    flexGrow: 1,
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "column-reverse",
    marginLeft: "70px",
  },
});

const AppendixInvoicePdfNewVersion = ({ quote, handleBack, activeStep }) => {
  const params = useParams();
  const navigate = useNavigate();

  const itemsData = quote?.items ? quote.items : [];
  // console.log("itemsData", itemsData);
  const businessName = quote?.supplierDetails?.businessName;
  const selectSupplierLogo = () => {
    switch (businessName) {
      case "M.S. Enterprises":
        return mseLogo; // Import your mse.png image
      case "Ishdev Enterprises":
        return mindsclikLogo; // Import your mindsclik_logo.png image
      default:
        return mseLogo; // Default logo if businessName doesn't match
    }
  };
  const MyDocument = () => (
    <Document>
      <Page size="A3" orientation="landscape" style={styles.pdfPage}>
        <View style={styles.header}>
          <View style={styles.logoSection}>
            <Image
              src={selectSupplierLogo()}
              style={{ width: 100, height: 80 }}
            />
          </View>
          <View style={styles.titleSection}>
            <Text style={styles.invoiceT}>Appendix</Text>
          </View>
          <View style={styles.orderIdSection}>
            <Text style={styles.text}>Quote Id: {quote?.quoteId}</Text>
            <Text style={styles.text}>
              Quote Date: {dateFormatter(quote?.quoteDate)}
            </Text>
            <Text style={styles.text}>Quote Status: {quote?.status}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.itemListHead}>
            <Text style={[styles.detailsHeader, { width: "27%" }]}>
              Details
            </Text>
            <Text style={[styles.hoseHeader, { width: "32%" }]}>Hose</Text>
            <Text style={[styles.fittingHeaderA, { width: "22%" }]}>
              Fitting at End A
            </Text>
            <Text style={[styles.fittingHeaderB, { width: "22%" }]}>
              Fitting at End B
            </Text>
            <Text style={[styles.totalHeader, { width: "18%" }]}>Total</Text>
          </View>

          <View style={styles.tableHeader}>
            <Text style={[styles.cell, { width: "5%" }]}>SN</Text>
            <Text style={[styles.cell, { width: "8%" }]}>MATERIAL CODE</Text>
            <Text style={[styles.cell, { width: "9%" }]}>Description</Text>
            <Text style={[styles.cell, { width: "5%" }]}>QTY</Text>
            <Text style={[styles.cell, { width: "7%" }]}>NAME</Text>
            <Text style={[styles.cell, { width: "5%" }]}>MRP</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Disc. %</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Qty</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Net/Unit</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Amount</Text>
            <Text style={[styles.cell, { width: "8%" }]}>Fitting A Type</Text>
            <Text style={[styles.cell, { width: "5%" }]}>MRP</Text>
            <Text style={[styles.cell, { width: "4%" }]}>Disc %</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Net</Text>
            <Text style={[styles.cell, { width: "8%" }]}>Fitting B Type</Text>
            <Text style={[styles.cell, { width: "5%" }]}>MRP</Text>
            <Text style={[styles.cell, { width: "4%" }]}>Disc %</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Net</Text>
            <Text style={[styles.cell, { width: "7%" }]}>Attachments</Text>
            <Text style={[styles.cell, { width: "5%" }]}>Net/Unit</Text>
            <Text style={[styles.cell, { width: "6%" }]}>Total Amount</Text>
          </View>

          {itemsData.map((product, idx) => (
            <View style={styles.tableRow} key={idx} wrap={false}>
              <Text style={[styles.cell, { width: "5%" }]}>{idx + 1}</Text>
              <Text style={[styles.cell, { width: "8%" }]}>
                {product?.skuid}
              </Text>
              <Text style={[styles.cell, { width: "9%" }]}>
                {product?.description}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>{product?.qty}</Text>
              <Text style={[styles.cell, { width: "7%" }]}>
                {product?.products?.filter((p) => p.category === "HOSE")[0]
                  ?.name || ""}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "HOSE")[0]
                    ?.mrp || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "HOSE")[0]
                    ?.discountPercent || ""
                )}
              </Text>

              <Text style={[styles.cell, { width: "5%" }]}>
                {product?.products?.filter((p) => p.category === "HOSE")[0]
                  ?.quantity || ""}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "HOSE")[0]
                    ?.salesPrice || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {(() => {
                  const hoseProduct = product?.products?.filter(
                    (p) => p.category === "HOSE"
                  )[0];
                  const salesPrice = hoseProduct?.salesPrice || 0;
                  const quantity = hoseProduct?.quantity || 0;
                  const total = salesPrice * quantity;
                  return moneyFormatter(total);
                })()}
              </Text>
              <Text style={[styles.cell, { width: "8%" }]}>
                {product?.products?.filter((p) => p.category === "FITTING")[0]
                  ?.name || ""}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "FITTING")[0]
                    ?.mrp || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "4%" }]}>
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "FITTING")[0]
                    ?.discountPercent || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {(() => {
                  const hoseProduct = product?.products?.filter(
                    (p) => p.category === "FITTING"
                  )[0];
                  const salesPrice = hoseProduct?.salesPrice || 0;
                  const quantity = hoseProduct?.quantity || 0;
                  const total = salesPrice * quantity;
                  return moneyFormatter(total);
                })()}
              </Text>
              <Text style={[styles.cell, { width: "8%" }]}>
                {product?.products?.filter((p) => p.category === "FITTING")[1]
                  ?.name || ""}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "FITTING")[1]
                    ?.mrp || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "4%" }]}>
                {moneyFormatter(
                  product?.products?.filter((p) => p.category === "FITTING")[1]
                    ?.discountPercent || ""
                )}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs.
                {(() => {
                  const hoseProduct = product?.products?.filter(
                    (p) => p.category === "FITTING"
                  )[1];
                  const salesPrice = hoseProduct?.salesPrice || 0;
                  const quantity = hoseProduct?.quantity || 0;
                  const total = salesPrice * quantity;
                  return moneyFormatter(total);
                })()}
              </Text>
              <Text style={[styles.cell, { width: "7%" }]}>
                {" "}
                {product?.products?.filter(
                  (p) => p.category === "FIRE SLEEVE"
                )[0]?.name || ""}
                {"\n"}
                {product?.products?.filter((p) => p.category === "ADAPTOR")[0]
                  ?.name || ""}
              </Text>
              <Text style={[styles.cell, { width: "5%" }]}>
                Rs. {moneyFormatter(product?.taxableAmount || 0)}
              </Text>
              <Text style={[styles.cell, { width: "6%" }]}>
                Rs. {moneyFormatter(product?.totalAmount || 0)}
              </Text>
            </View>
          ))}

          <Text
            style={{
              fontFamily: "Lato Bold",
              position: "relative",
              left: "88%",
              top: "20px",
            }}
          >
            Total Amount: Rs. {moneyFormatter(quote?.totalAmount || 0)}
          </Text>
        </View>

        <View style={styles.signiture}>
          <Text>For, M.S. Enterprises</Text>
        </View>
        <View style={styles.signiture}>
          <Text>Proprietor</Text>
        </View>
        <View style={styles.thankyou}>
          <Text style={styles.thankU}>
            *** Thank you for your business! ***
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PDFViewer style={styles.page}>
          <MyDocument />
        </PDFViewer>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back
          </Button>
          <Grid item xs="auto">
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/app/quotations/all")}
              >
                Finish & Go to Quote List
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppendixInvoicePdfNewVersion;
