
  class ClientAddress {
    constructor(clientAddressInfo) {
      if (clientAddressInfo) {
        this.clientId = clientAddressInfo.clientId;
        this.label = clientAddressInfo.label;
        this.address1 = clientAddressInfo.address1;
        this.address2 = clientAddressInfo.address2;
        this.state = clientAddressInfo.state;
        this.city = clientAddressInfo.city;
        this.registeredAddress = clientAddressInfo.registeredAddress;
        this.country = clientAddressInfo.country;
        this.pincode = clientAddressInfo.pincode;
      }
    }
  };
export default { ClientAddress };
export { ClientAddress };
