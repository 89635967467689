import clientService from "services/clientService";
import { ClientProfile } from "../interface/clientProfile";

export const CLIENT_CREATE = "@client/create";
export const CLIENT_CREATE_SUCCESS = "@client/create-success";
export const CLIENT_CREATE_FAILURE = "@client/create-failure";

export const GET_ALL_CLIENT = "@client/getall";
export const GET_ALL_CLIENT_SUCCESS = "@client/getall-success";
export const GET_ALL_CLIENT_FAILURE = "@client/getall-failure";

export const GET_MY_CLIENT = "@client/getMy";
export const GET_MY_CLIENT_SUCCESS = "@client/getMy-success";
export const GET_MY_CLIENT_FAILURE = "@client/getMy-failure";

export const GET_CLIENT_BY_ID = "@client/getclientbyid";
export const GET_CLIENT_BY_ID_SUCCESS = "@client/getclientbyid-success";
export const GET_CLIENT_BY_ID_FAILURE = "@client/getclientbyid-failure";

export const CLIENT_REMOVE = "@client/remove";
export const CLIENT_REMOVE_SUCCESS = "@client/remove-success";
export const CLIENT_REMOVE_FAILURE = "@client/remove-failure";

export const CLIENT_UPDATE = "@client/update";
export const CLIENT_UPDATE_SUCCESS = "@client/update-success";
export const CLIENT_UPDATE_FAILURE = "@client/update-failure";

export const CLIENT_EDIT_TRIGGER = "@client/edit-trigger";
export const CLIENT_CREATE_TRIGGER = "@client/create-trigger";
export const CLIENT_VIEW_TRIGGER = "@client/view-trigger";

export const CLIENT_BLOCK = "@client/block";
export const CLIENT_BLOCK_SUCCESS = "@client/block-success";
export const CLIENT_BLOCK_FAILURE = "@client/block-failure";

export function fetchClient() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_CLIENT,
      });
      const client = await clientService.fetchClient();

      // console.log("clientAction.fetchclient Resp:", client);

      dispatch({
        type: GET_ALL_CLIENT_SUCCESS,
        payload: {
          client,
        },
      });
    } catch (error) {
      // console.log("usersAction.fetchUsers: Error", error);
      dispatch({ type: GET_ALL_CLIENT_FAILURE });
      throw error;
    }
  };
}
export function fetchMyClient() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MY_CLIENT,
      });
      const myclient = await clientService.fetchMyClient();

      // console.log("clientAction.fetchclient Resp:", myclient);

      dispatch({
        type: GET_MY_CLIENT_SUCCESS,
        payload: {
          myclient,
        },
      });
    } catch (error) {
      // console.log("usersAction.fetchMyClient: Error", error);
      dispatch({ type: GET_MY_CLIENT_FAILURE });
      throw error;
    }
  };
}

export function fetchClientById(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CLIENT_BY_ID,
      });
      const clientById = await clientService.fetchClientById(clientId);

      console.log("clientAction.fetchclient Resp:", clientById);

      dispatch({
        type: GET_CLIENT_BY_ID_SUCCESS,
        payload: {
          clientById,
        },
      });
    } catch (error) {
      // console.log("usersAction.fetchUsers: Error", error);
      dispatch({ type: GET_CLIENT_BY_ID_FAILURE });
      throw error;
    }
  };
}

export function createClient(values) {
  return async (dispatch) => {
    try {
      const newClientInfo = new ClientProfile(values);
      dispatch({
        type: CLIENT_CREATE,
        payload: {
          newClientInfo,
        },
      });
      // console.log("clientAction.createClient:", newClientInfo);

      const resp = await clientService.createClient(newClientInfo);

      // console.log("clientAction.createClient: Resp:", resp);

      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchClient());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("usersAction.addusers:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CLIENT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function removeClient(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_REMOVE,
        payload: {
          clientId,
        },
      });
      // console.log("clientAction.removeClient:", clientId);

      const client = await clientService.removeClient(clientId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: CLIENT_REMOVE_SUCCESS,
        payload: {
          client,
        },
      });
      await dispatch(fetchClient());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: CLIENT_REMOVE_FAILURE });
      throw error;
    }
  };
}

export function updateClient(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_UPDATE,
        payload: {
          clientId,
        },
      });
      // console.log("clientAction.removeClient:", clientId);

      const client = await clientService.updateClient(clientId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        payload: {
          client,
        },
      });
      await dispatch(fetchClient());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: CLIENT_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function editClientTrigger(clientEdit) {
  return async (dispatch) => {
    // console.log("editClientTrigger() ", clientEdit);
    dispatch({
      type: CLIENT_EDIT_TRIGGER,
      payload: { clientEdit },
    });
  };
}

export function viewClientTrigger(clientEdit) {
  return async (dispatch) => {
    // console.log("editClientTrigger() ", clientEdit);
    dispatch({
      type: CLIENT_VIEW_TRIGGER,
      payload: { clientEdit },
    });
  };
}

export function createClientTrigger() {
  return async (dispatch) => {
    dispatch({
      type: CLIENT_CREATE_TRIGGER,
      payload: {},
    });
  };
}

export function blockClient(clientId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_BLOCK,
        payload: {
          clientId,
        },
      });
      // console.log("clientAction.blockClient:", clientId);

      const client = await clientService.blockClient(clientId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: CLIENT_BLOCK_SUCCESS,
        payload: {
          client,
        },
      });
      await dispatch(fetchClient());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: CLIENT_BLOCK_FAILURE });
      throw error;
    }
  };
}
