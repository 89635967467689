export const dateFormatter = (dbFormatDate) => {
  let formatedDate = dbFormatDate;
  const options = {
    // weekday: 'short',
    year: "2-digit",
    month: "short",
    day: "2-digit",
    // hour: 'numeric',
    // minute: 'numeric',
    // timeZone: 'Asia/Kolkata',
  };
  if (typeof dbFormatDate === "string") {
    formatedDate = new Date(dbFormatDate).toLocaleString("en-IN", options);
  }

  return formatedDate;
};

export const moneyFormatter = (amount) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    // style: 'currency',
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const addressFormatter = (address) => {
  let result;
  if (address) {
    result = [
      address?.address1 ? address?.address1 : "",
      address?.address2 ? address?.address2 : "",
      address?.state ? address?.state : "",
      address?.city ? address?.city : "",
      // supplierAddress?.country,
      // supplierAddress?.pincode,
    ].toString();
  }
  return result;
};
