import axios from "utils/axios";

import {
  GET_ALL_QUOTE_DASHBOARD,
  GET_ALL_USER_DASHBOARD,
  GET_ALL_CLIENT_DASHBOARD,
  GET_ALL_PRODUCT_DASHBOARD,
  CHARTS_DATA,
  PIE_CHART_DATA,
} from "config/services";

class DashboardService {
  fetchQuoteDashboard = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_QUOTE_DASHBOARD, {})
        .then((response) => {
          // console.log("dashboardService.fetchQuoteDashboard() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchQuoteDashboard() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchUserDashboard = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_USER_DASHBOARD, {})
        .then((response) => {
          // console.log("dashboardService.fetchUserDashboard() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchQuoteDashboard() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchClientDashboard = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_CLIENT_DASHBOARD, {})
        .then((response) => {
          // console.log(
          //   "dashboardService.fetchClientDashboard() resp:",
          //   response
          // );
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchClientDashboard() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchProductDashboard = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_PRODUCT_DASHBOARD, {})
        .then((response) => {
          // console.log(
          //   "dashboardService.fetchProductDashboard() resp:",
          //   response
          // );
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchProductDashboard() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchChartsData = () =>
    new Promise((resolve, reject) => {
      axios
        .get(CHARTS_DATA, {})
        .then((response) => {
          // console.log("dashboardService.fetchChartsData() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchChartsData() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchPieChartsData = () =>
    new Promise((resolve, reject) => {
      axios
        .get(PIE_CHART_DATA, {})
        .then((response) => {
          // console.log("dashboardService.fetchPieChartsData() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("dashboardService.fetchPieChartsData() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const dashboardService = new DashboardService();

export default dashboardService;
