import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { fetchQuoteByCriteria } from "../../../actions/quoteActions";
import { SUPPLIERS } from "../../../config";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

const options = [
  "DRAFT",
  "READY",
  "SUBMITTED",
  "ACCEPTED",
  "ORDER",
  "CANCELLED",
  "EXPIRED",
];

function SearchBox() {
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [quoteId, setQuoteId] = useState("");
  const [quoteDate, setQuoteDate] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedSupplier(null);
    setQuoteId("");
    setQuoteDate("");
    setStatus("");
  };
  console.log("qouteDate", quoteDate);
  const handleSupplierSelect = (event, value) => {
    setSelectedSupplier(value);
  };

  const handleFetch = () => {
    const supplierIdToSend = selectedSupplier
      ? selectedSupplier.supplierId
      : "";
    dispatch(
      fetchQuoteByCriteria(
        supplierIdToSend,
        quoteId,
        quoteDate || undefined,
        status,
        page
      )
    );
    navigate("/app/quotations/all");
  };
  const handleInputChange = (event, newInputValue) => {
    setStatus(newInputValue);
  };
  const handleOptionSelect = (event) => {
    setStatus(event.target.value);
  };
  const page = useSelector((state) => state.quote?.page);
  // console.log(" page: ", page);

  return (
    <Card sx={{ m: 1, p: 1 }}>
      <Box sx={{ p: 2, ml: 3 }}>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Autocomplete
            options={SUPPLIERS}
            getOptionLabel={(option) => option.businessName}
            value={selectedSupplier}
            onChange={handleSupplierSelect}
            style={{
              width: 230,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                placeholder="Supplier"
              />
            )}
            sx={{ marginRight: 1 }}
          />
          <TextField
            size="small"
            type="text"
            id="quoteId"
            name="quoteId"
            value={quoteId}
            placeholder="Quote Id"
            onChange={(event) => setQuoteId(event.target.value)}
            sx={{ marginRight: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              placeholder="Quote Date"
              value={quoteDate}
              onChange={(newValue) => setQuoteDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  value={quoteDate ? quoteDate : ""}
                  sx={{ marginRight: 1 }}
                />
              )}
            />
          </LocalizationProvider>

          <Autocomplete
            options={options}
            inputValue={status}
            onInputChange={handleInputChange}
            onChange={handleOptionSelect}
            style={{
              width: 230,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                placeholder="Status"
              />
            )}
            sx={{ marginRight: 1 }}
          />
          <Button size="small" variant="contained" onClick={handleFetch}>
            Search Quotations
          </Button>
        </form>
      </Box>
    </Card>
  );
}
export default SearchBox;
