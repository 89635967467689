import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { TextField, Button, Grid, Autocomplete } from "@mui/material";
import { useDispatch } from "react-redux";
import { PRODUCT_CATEGORY, BUSINESS_ID, CUSTOMER_CATEGORY } from "config";
import {
  addDiscount,
  updateDiscount,
} from "../../../../../actions/usersAction";
import { useNavigate } from "react-router-dom";

const NewDiscountForm = ({ onSubmitSuccess, data, isDiscountEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        discountId: data ? data?.discountId : "",
        businessId: BUSINESS_ID,
        productCategory: data?.productCategory ? data?.productCategory : "",
        customerCategory: data?.customerCategory ? data?.customerCategory : "",
        discount: data?.discount ? data?.discount : "",
      }}
      validationSchema={Yup.object().shape({
        // businessId: Yup.string().required("Business Id is required").max(10),
        // productCategory: Yup.string().required("productCategory is required").max(500),
        discount: Yup.string().required("discount is required").max(100),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (data) {
            await dispatch(updateDiscount(data.discountId, values));
          } else {
            await dispatch(addDiscount(values));
          }

          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.customerCategory}
                  onChange={(event, newValue) => {
                    setFieldValue("customerCategory", newValue);
                  }}
                  disablePortal
                  id="customerCategory"
                  options={CUSTOMER_CATEGORY}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Customer Category"
                        required
                        error={
                          touched.customerCategory &&
                          Boolean(errors.customerCategory)
                        }
                        helperText={
                          touched.customerCategory && errors.customerCategory
                        }
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.productCategory}
                  onChange={(event, newValue) => {
                    setFieldValue("productCategory", newValue);
                  }}
                  disablePortal
                  id="productCategory"
                  options={PRODUCT_CATEGORY}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Product Type"
                        required
                        error={
                          touched.productCategory &&
                          Boolean(errors.productCategory)
                        }
                        helperText={
                          touched.productCategory && errors.productCategory
                        }
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="discount"
                  name="discount"
                  label="Discount"
                  value={values.discount}
                  onChange={handleChange}
                  error={touched.discount && Boolean(errors.discount)}
                  helperText={touched.discount && errors.discount}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => navigate("/app/mgmt/discount/all")}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {isDiscountEdit ? "Update Discount" : "Add Discount"}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default NewDiscountForm;
