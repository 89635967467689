export function getProductsByCategory(data, category, size) {
  let results = [];
  if (size && size != "") {
    results = data.filter(
      (item) => item.category === category && item.size === size
    );
  } else {
    results = data.filter((item) => item.category === category);
  }
  return results;
}
