import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import { Grid } from "@mui/material";

import InvoicePDF from "./InvoicePdf";
import { useSelector } from "react-redux";

function Invoice() {
  const quote = useSelector((state) => state.quote.quote);
  // console.log("Quote : ", quote);
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - All Quote List</title>
      </Helmet>
      <Grid
        mt={15}
        mb={10}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <InvoicePDF quote={quote} />
      </Grid>
    </DashboardLayout>
  );
}

export default Invoice;
