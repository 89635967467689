import React from "react";
import { IconButton, SvgIcon, Button, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ApprovalIcon from "@mui/icons-material/Approval";
import PublishIcon from "@mui/icons-material/Publish";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InventoryIcon from "@mui/icons-material/Inventory";
import CancelIcon from "@mui/icons-material/Cancel";
import { ConfirmDialog } from "components";
import { dateFormatter } from "utils/formatUtils";
import FilePresentIcon from "@mui/icons-material/FilePresent";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  viewQuote,
  handleEditQuote,
  modalApproved,
  showApproved,
  setModalApproved,
  handleApproved,
  showSubmitted,
  modalSubmitted,
  setModalSubmitted,
  handleSubmitted,
  showAccepted,
  modalAccepted,
  setModalAccepted,
  handleAccepted,
  showOrdered,
  modalOrdered,
  setModalOrdered,
  handleOrdered,
  showCancel,
  modalCancel,
  setModalCancel,
  handleCancel,
  viewAppendixFile
) => [
  { field: "quoteId", headerName: "Quote Id", width: 120 },

  {
    field: "quoteDate",
    headerName: "Quote Date",
    valueGetter: (params) => dateFormatter(params.row.quoteDate),
    width: 200,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    // sortable: false,
    width: 450,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}
          <Tooltip title={"Edit"}>
            <IconButton
              onClick={() => {
                handleEditQuote(params.row.quoteId);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          {/* edit button */}
          <m>
            {showDelete && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this Qoute ,action can't be undone`}
                  isOpen={modal === params.row.quoteId && params.row.quoteId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.quoteId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <Tooltip title={"Delete"}>
                  <IconButton
                    onClick={() => setModal(params.id)}
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showApproved && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Quote Approval"
                  description={`Do you want to Approved the quote`}
                  isOpen={
                    modalApproved === params.row.quoteId && params.row.quoteId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModalApproved(false);
                    handleApproved(params.row.quoteId);
                  }}
                  cancelAction={() => setModalApproved(false)}
                />
                <Tooltip title={"Approved"}>
                  <IconButton
                    onClick={() => setModalApproved(params.id)}
                    color="primary"
                  >
                    <ApprovalIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showSubmitted && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Quote Submitted"
                  description={`Do you want to Submitted the quote`}
                  isOpen={
                    modalSubmitted === params.row.quoteId && params.row.quoteId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModalSubmitted(false);
                    handleSubmitted(params.row.quoteId);
                  }}
                  cancelAction={() => setModalSubmitted(false)}
                />
                <Tooltip title={"Submitted"}>
                  <IconButton
                    onClick={() => setModalSubmitted(params.id)}
                    color="primary"
                  >
                    <PublishIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showAccepted && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Quote Accepted"
                  description={`Do you want to Accepted the quote`}
                  isOpen={
                    modalAccepted === params.row.quoteId && params.row.quoteId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModalAccepted(false);
                    handleAccepted(params.row.quoteId);
                  }}
                  cancelAction={() => setModalAccepted(false)}
                />
                <Tooltip title={"Accepted"}>
                  <IconButton
                    onClick={() => setModalAccepted(params.id)}
                    color="primary"
                  >
                    <AssignmentTurnedInIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showOrdered && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Quote Ordered "
                  description={`Do you want to Ordered  the quote`}
                  isOpen={
                    modalOrdered === params.row.quoteId && params.row.quoteId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModalOrdered(false);
                    handleOrdered(params.row.quoteId);
                  }}
                  cancelAction={() => setModalOrdered(false)}
                />
                <Tooltip title={"Ordered"}>
                  <IconButton
                    onClick={() => setModalOrdered(params.id)}
                    color="primary"
                  >
                    <InventoryIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showCancel && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Quote Cancelled"
                  description={`Do you want to Cancelled the quote`}
                  isOpen={
                    modalCancel === params.row.quoteId && params.row.quoteId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModalCancel(false);
                    handleCancel(params.row.quoteId);
                  }}
                  cancelAction={() => setModalCancel(false)}
                />
                <Tooltip title={"Cancelled"}>
                  <IconButton
                    onClick={() => setModalCancel(params.id)}
                    color="primary"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <Button onClick={() => viewQuote(params.row.quoteId)}>
            <Tooltip title={"Pdf file"}>
              <SvgIcon fontSize="small">
                <PictureAsPdfIcon />
              </SvgIcon>
            </Tooltip>
          </Button>
          <Button onClick={() => viewAppendixFile(params.row.quoteId)}>
            <Tooltip title={"Appendix file"}>
              <SvgIcon fontSize="small">
                <FilePresentIcon />
              </SvgIcon>
            </Tooltip>
          </Button>
        </div>
      );
    },
  },
  {
    field: "tnc",
    headerName: "Terms And Condition",
    width: 250,
    editable: true,
  },
  {
    field: "supplierId",
    headerName: "Supplier Id",
    width: 120,
  },
];
