import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { moneyFormatter } from "../../../../../utils/formatUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Summary = ({ handleNext, handleBack, activeStep }) => {
  const quotes = useSelector((state) => state?.quote);
  // console.log(" quote: ", quotes);

  const quoteInProgress = quotes?.quoteInProgress?.amountSummary;
  // console.log(" quoteInProgress: ", quoteInProgress);

  const summaryData = [
    {
      title: "Sub Total",
      amount: moneyFormatter(quoteInProgress?.subTotal),
    },
    {
      title: "CGST",
      amount: moneyFormatter(quoteInProgress?.cgst),
    },
    {
      title: "SGST",
      amount: moneyFormatter(quoteInProgress?.sgst),
    },
    {
      title: "IGST",
      amount: moneyFormatter(quoteInProgress?.igst),
    },
    {
      title: "CESS",
      amount: moneyFormatter(quoteInProgress?.cess),
    },
    {
      title: "Discount %",
      amount: moneyFormatter(quoteInProgress?.discountPercent),
    },
    {
      title: "Discount Amount",
      amount: moneyFormatter(quoteInProgress?.discountAmount),
    },
    {
      title: "Total Tax",
      amount: moneyFormatter(quoteInProgress?.totalTax),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}></Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card sx={{ boxShadow: 3}}>
            <CardContent>
              {summaryData.map((item) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  //   rowGap={4}
                >
                  <Grid item>
                    <Typography variant="body1"> {item.title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{item.amount}</Typography>
                  </Grid>
                </Grid>
              ))}
              <Divider sx={{ border: "dashed 1px" }} />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                rowGap={4}
              >
                <Grid item>
                  <Typography variant="subtitle1">Total Amount</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    {moneyFormatter(quoteInProgress?.grandTotal)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
