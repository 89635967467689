import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";

export default function PieChartsData() {
  const pieChartsData = useSelector((state) => state.dashBoard?.pieChartsData);
  // console.log("pieChartsData", pieChartsData);
  const data = pieChartsData?.map((item, index) => ({
    id: index,
    value: item.count,
    label: item._id,
  }));
  if (!pieChartsData) {
    return;
  }
  return (
    <PieChart
      series={[
        {
          data: data,
        },
      ]}
      width={400}
      height={200}
    />
  );
}
