import authService from "services/authService";

import { initializeAuth } from "./initActions";
import {
  fetchMyProject,
  fetchProject,
  fetchProjectDetail,
} from "./projectAction";
import { fetchUsers } from "./usersAction";
import { fetchCustomProduct, fetchProduct } from "./catalogAction";
// import { UserProfile } from "interface/userProfileIf";

export const SIGNIN = "@auth/signin";
export const SIGNIN_SUCCESS = "@auth/signin-success";
export const SIGNIN_FAILURE = "@auth/signin-failure";

export const SILENT_LOGIN = "@auth/silent-login";
export const SIGNOUT = "@auth/signout";

export const SIGNIN_MOBILE_REQ_OTP = "@auth/signinmobilereqotp";
export const SIGNIN_MOBILE_REQ_OTP_SUCCESS =
    "@auth/signinmobilereqotp-success";
export const SIGNIN_MOBILE_REQ_OTP_FAILURE =
    "@auth/signinmobilereqotp-failure";

export const SIGNIN_MOBILE_OTP_VERIFY = "@auth/signinmobileotpverify";
export const SIGNIN_MOBILE_OTP_VERIFY_SUCCESS =
    "@auth/signinmobileotpverify-success";
export const SIGNIN_MOBILE_OTP_VERIFY_FAILURE =
    "@auth/signinmobileotpverify-failure";

export const SIGNUP_MOBILE = "@auth/signupmobile";
export const SIGNUP_MOBILE_SUCCESS = "@auth/signupmobile-success";
export const SIGNUP_MOBILE_FAILURE = "@auth/signupmobile-failure";

export const SIGNUP_MOBILE_VERIFY_OTP = "@auth/signupmobileverifyotp-otp";
export const SIGNUP_MOBILE_VERIFY_OTP_SUCCESS =
  "@auth/signupmobileverifyotp-success";
export const SIGNUP_MOBILE_VERIFY_OTP_FAILURE =
  "@auth/signupmobileverifyotp-failure";

export const UPDATE_PASSWORD = "@auth/update-password";
export const UPDATE_PASSWORD_SUCCESS = "@auth/update-password-success";
export const UPDATE_PASSWORD_FAILURE = "@auth/update-password-failure";

export const CHANGE_PASSWORD = "@auth/change-password";
export const CHANGE_PASSWORD_SUCCESS = "@auth/change-password-success";
export const CHANGE_PASSWORD_FAILURE = "@auth/change-password-failure";

export function signIn(email, password) {
    return async (dispatch) => {
        try {
            dispatch({ type: SIGNIN });
            const user = await authService.signinWithEmailAndPassword(
                email,
                password
            );

            dispatch({
                type: SIGNIN_SUCCESS,
                payload: {
                    user,
                },
            });

      // Initialize User Information
      dispatch(initializeAuth());
      dispatch(fetchProduct());
      dispatch(fetchCustomProduct());
      if (user.userType === "ADMIN" || user.userType === "OWNER") {
        dispatch(fetchUsers());
      }
    } catch (error) {
      dispatch({ type: SIGNIN_FAILURE });
      // throw error;
    }
  };
}

export function setUserData(user) {
    return (dispatch) =>
        dispatch({
            type: SILENT_LOGIN,
            payload: {
                user,
            },
        });
}

export function signout() {
    return async (dispatch) => {
        authService.signout();

        dispatch({
            type: SIGNOUT,
        });
    };
}


export function signInMobile(mobile) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNIN_MOBILE_REQ_OTP,
                payload: {
                    mobile,
                },
            });

            const payload = await authService.signinMobileReqOTP(mobile);

            dispatch({
                type: SIGNIN_MOBILE_REQ_OTP_SUCCESS,
                payload,
            });

        } catch (err) {

            let errMsg = err?.response?.data?.message;
            const error = { message: errMsg };

            dispatch({ type: SIGNIN_MOBILE_REQ_OTP_FAILURE });
            throw error;
        }
    };
}

export function signInMobileVerifyOTP(mobile, otp) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNIN_MOBILE_OTP_VERIFY,
                payload: {
                    mobile,
                },
            });

            const user = await authService.signinMobileVerifyOTP(mobile, otp);

            dispatch({
                type: SIGNIN_MOBILE_OTP_VERIFY_SUCCESS,
                payload: {
                    user,
                },
            });

            // Initialize User Information
            dispatch(initializeAuth());
            dispatch(fetchProject());
            dispatch(fetchMyProject());
            dispatch(fetchProjectDetail());
            dispatch(fetchUsers());
        } catch (error) {
            // console.log("AuthAction.signInWithMobileOTP: Error", error);

            // let errMsg = err?.response?.data?.message;
            // const error = { message: errMsg };

            dispatch({ type: SIGNIN_MOBILE_OTP_VERIFY_FAILURE });
            throw error;
        }
    };
}


export function signUpMobile(name, mobile) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNUP_MOBILE,
                payload: {
                    name,
                    mobile,
                },
            });
            // console.log("AuthAction.signupMobile:", name, mobile);
            const user = await authService.signUpMobile(name, mobile);

            // console.log("AuthAction.signupMobile: Resp:" , user);

            dispatch({
                type: SIGNUP_MOBILE_SUCCESS,
                payload: {
                    user,
                },
            });

        } catch (error) {
            // console.log("AuthAction.signupMobile: Error", error);
            dispatch({ type: SIGNUP_MOBILE_FAILURE });
            throw error;
        }
    };
}

export function signUpMobileVerifyOTP(mobile, name, otp) {
    return async (dispatch) => {
        try {
            dispatch({ type: SIGNUP_MOBILE_VERIFY_OTP });
            const user = await authService.signUpMobileVerifyOTP(mobile, name, otp);

            dispatch({
                type: SIGNUP_MOBILE_VERIFY_OTP_SUCCESS,
                payload: {
                    user,
                },
            });

            // Initialize User Information
            dispatch(initializeAuth());

        } catch (error) {
            dispatch({ type: SIGNUP_MOBILE_VERIFY_OTP_FAILURE });
            throw error;
        }
    };
}

export function updatePassword(newPassword, userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PASSWORD,
        payload: {
          newPassword,
          userId,
        },
      });
    //   console.log("usersAction.updatePassword:", newPassword, userId);

      const user = await authService.updatePassword(newPassword, userId);

      console.log("usersAction.updatePassword Resp:", user);
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: {
          user,
        },
      });
      await dispatch(fetchUsers());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: UPDATE_PASSWORD_FAILURE });
      throw error;
    }
  };
}

export function changeOwnPassword(password, newPassword) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: {
          password,
          newPassword,
        },
      });
      console.log("AuthAction.changeOwnPassword:", password, newPassword);

      const user = await authService.changeOwnPassword(password, newPassword);

      console.log("usersAction.changeOwnPassword Resp:", user);
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: {
          user,
        },
      });
      await dispatch(fetchUsers());
    } catch (error) {
      // console.log("AuthAction.changeOwnPassword: Error", error);
      dispatch({ type: CHANGE_PASSWORD_FAILURE });
      throw error;
    }
  };
}
