import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import {
  Button,
  IconButton,
  SvgIcon,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  createProjectTrigger,
  deleteProject,
  editProjectTrigger,
  fetchAllocateList,
  fetchMembersList,
  fetchProjectDetail,
  fetchProjectInfo,
} from "../../../../actions/projectAction";

function AllProjectsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector((state) => state.project);
  // console.log("projects",project.projects)
 
  const [modal, setModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);

 

  const handleEditProject = (data) => {
    dispatch(editProjectTrigger(data));
    navigate("/app/mgmt/project/create");
  };

  const viewProject = (data) => {
    // console.log("handle view project", data.projectId);
    // dispatch(fetchProjectInfo(data));
    // dispatch(fetchAllocateList(data.projectId));
    // dispatch(fetchMembersList(data.projectId));
    dispatch(fetchProjectDetail(data?.projectId));
    navigate("/app/mgmt/project/project-details");
  };
  useEffect(() => {}, [project?.projects]);
  const handleDelete = (projectId) => {
    dispatch(deleteProject(projectId));
  };
  return (
    <Box sx={{ width:"100%"}}>
      <DataGrid
        rows={project.projects}
        columns={headCells(
          true,
          handleDelete,
          viewProject,
          modal,
          setModal,
          handleEditProject
        )}
        getRowId={(row) => row.projectId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
}
export default AllProjectsList;
