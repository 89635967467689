import axios from "utils/axios";

import {
  PROJECT_LIST_URL,
  CREATE_PROJECT_URL,
  DELETE_PROJECT_URL,
  MY_PROJECT_LIST_URL,
  PROJECT_DETAIL_URL,
  ASSIGN_MEMBER_URL,
  ALLOCATE_DEVICE_URL,
  PROJECT_UPDATE_URL,
  ALLOCATE_LIST_URL,
  DELETE_PROJECT_DEVICE_URL,
  ASSIGN_MEMBER_LIST_URL,
  DELETE_ASSIGN_MEMBER,
} from "config/services";

class ProjectService {
  fetchProject = () =>
    new Promise((resolve, reject) => {
      axios
        .get(PROJECT_LIST_URL, {})
        .then((response) => {
          // console.log("projectService.fetchProject() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.fetchProject() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  // fetchAllocateList = (projectId) =>
  //   new Promise((resolve, reject) => {
  //     let allocateUrl = `${ALLOCATE_LIST_URL}?projectId=${projectId}`;
  //     axios
  //       .get(allocateUrl, {})
  //       .then((response) => {
  //         // console.log("projectService.fetchAllocateList() resp:", response)
  //         if (response?.data?.error) {
  //           // console.log("Failed to fetch records");
  //           reject(response.data.message);
  //         } else {
  //           if (response?.data?.payload) {
  //             resolve(response?.data?.payload);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           // console.log("projectService.fetchAllocateList() error:", error);
  //           reject(error?.response?.data);
  //         }
  //         reject(error);
  //       });
  //   });
  // fetchMembersList = (projectId) =>
  //   new Promise((resolve, reject) => {
  //     let membersUrl = `${ASSIGN_MEMBER_LIST_URL}?projectId=${projectId}`;
  //     axios
  //       .get(membersUrl, {})
  //       .then((response) => {
  //         // console.log("projectService.fetchMembersList() resp:", response)
  //         if (response?.data?.error) {
  //           // console.log("Failed to fetch records");
  //           reject(response.data.message);
  //         } else {
  //           if (response?.data?.payload) {
  //             resolve(response?.data?.payload);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           // console.log("projectService.fetchMembersList() error:", error);
  //           reject(error?.response?.data);
  //         }
  //         reject(error);
  //       });
  //   });

  fetchMyProject = () =>
    new Promise((resolve, reject) => {
      axios
        .get(MY_PROJECT_LIST_URL, {})
        .then((response) => {
          // console.log("projectService.fetchMyProject() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.fetchMyProject() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchProjectDetail = (projectId) =>
    new Promise((resolve, reject) => {
      let detailsUrl = `${PROJECT_DETAIL_URL}?projectId=${projectId}`;
      axios
        .get(detailsUrl, {})
        .then((response) => {
          // console.log("projectService.fetchProjectDetail() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.fetchProjectDetail() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createProjects = (newProjectInfo) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.createProjects called", newProjectInfo);
      const payload = newProjectInfo;
      axios
        .post(CREATE_PROJECT_URL, { payload })
        .then((response) => {
          // console.log("projectService.createProjects() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.addProjects() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  deleteProject = (projectId) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.deleteProject called", payload);
      axios
        .post(DELETE_PROJECT_URL, { payload: { projectId } })
        .then((response) => {
          // console.log("projectService.deleteProject() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.deleteProject() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  deletePojectDevice = (projectId, deviceId) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.deletePojectDevice called", payload);

      axios
        .post(DELETE_PROJECT_DEVICE_URL, { payload: { projectId, deviceId }  })
        .then((response) => {
          // console.log("projectService.deletePojectDevice() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.deletePojectDevice() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  deletePojectMember = (projectId, userId) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "projectService.deletePojectMember called",
      //   projectId,
      //   userId
      // );

      axios
        .post(DELETE_ASSIGN_MEMBER, { payload: { projectId, userId } })
        .then((response) => {
          // console.log("projectService.deletePojectMember() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.deletePojectMember() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateProjects = (newProjectInfo) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.updateProjects called", newProjectInfo);
      const payload = newProjectInfo;
      axios
        .post(PROJECT_UPDATE_URL, { payload })
        .then((response) => {
          // console.log("projectService.addRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.updateProjects() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createProjectMembers = (newMemberInfo) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.createProjectMembers called", newMemberInfo);
      const payload = newMemberInfo;
      axios
        .post(ASSIGN_MEMBER_URL, { payload })
        .then((response) => {
          // console.log("projectService.createProjectMembers() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.createProjectMembers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  createAllocateDevice = (newDeviceInfo) =>
    new Promise((resolve, reject) => {
      // console.log("projectService.createAllocateDevice called", newDeviceInfo);
      const payload = newDeviceInfo;
      axios
        .post(ALLOCATE_DEVICE_URL, { payload })
        .then((response) => {
          // console.log("projectService.createAllocateDevice() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("projectService.createAllocateDevice() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const projectService = new ProjectService();

export default projectService;
