/* eslint-disable no-param-reassign */
import produce from "immer";

import {
  QUOTE_FETCH_DASHBOARD,
  QUOTE_FETCH_DASHBOARD_SUCCESS,
  QUOTE_FETCH_DASHBOARD_FAILURE,
  USERS_FETCH_DASHBOARD,
  USERS_FETCH_DASHBOARD_SUCCESS,
  USERS_FETCH_DASHBOARD_FAILURE,
  CLIENTS_FETCH_DASHBOARD,
  CLIENTS_FETCH_DASHBOARD_SUCCESS,
  CLIENTS_FETCH_DASHBOARD_FAILURE,
  PRODUCTS_FETCH_DASHBOARD,
  PRODUCTS_FETCH_DASHBOARD_SUCCESS,
  PRODUCTS_FETCH_DASHBOARD_FAILURE,
  FETCH_CHARTS_DATA,
  FETCH_CHARTS_DATA_SUCCESS,
  FETCH_CHARTS_DATA_FAILURE,
  FETCH_PIE_CHARTS_DATA,
  FETCH_PIE_CHARTS_DATA_SUCCESS,
  FETCH_PIE_CHARTS_DATA_FAILURE,
} from "../actions/dashboardAction";
import { CHARTS_DATA } from "../config/services";

const initialState = {
  quotesData: [],
  usersData: [],
  clientsData: [],
  productsData: [],
  totalQuoteCount: 0,
  totalUserCount: 0,
  totalClientCount: 0,
  totalProductCount: 0,
  chartsData: [],
  totalPrice: 0,
  currentMonthQuoteCount: 0,
  pieChartsData: [],
  id: "",
  count: 0,
};

const dashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_FETCH_DASHBOARD:
      {
        return produce(state, (draft) => {
          draft.quotesData = [];
          draft.totalQuoteCount = 0;
        });
      }
      break;

    case QUOTE_FETCH_DASHBOARD_SUCCESS:
      {
        const { quotesData } = action.payload;
        // console.log(
        //   "dashBoardReducer: QUOTE_FETCH_DASHBOARD_SUCCESS ",
        //   quotesData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quotesData = quotesData?.quoteDetails;
          draft.totalQuoteCount =
            quotesData?.quoteDetails?.totalQuoteCount || 0;
        });
      }
      break;

    case QUOTE_FETCH_DASHBOARD_FAILURE:
      {
        return produce(state, (draft) => {
          draft.quotesData = [];
          draft.totalQuoteCount = 0;
        });
      }
      break;

    case USERS_FETCH_DASHBOARD:
      {
        return produce(state, (draft) => {
          draft.usersData = [];
          draft.totalUserCount = 0;
        });
      }
      break;

    case USERS_FETCH_DASHBOARD_SUCCESS:
      {
        const { usersData } = action.payload;
        // console.log(
        //   "dashBoardReducer: USERS_FETCH_DASHBOARD_SUCCESS ",
        //   usersData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.usersData = usersData?.userDetails;
          draft.totalUserCount = usersData?.userDetails?.totalUserCount || 0;
        });
      }
      break;

    case USERS_FETCH_DASHBOARD_FAILURE:
      {
        return produce(state, (draft) => {
          draft.usersData = [];
          draft.totalUserCount = 0;
        });
      }
      break;
    case CLIENTS_FETCH_DASHBOARD:
      {
        return produce(state, (draft) => {
          draft.clientsData = [];
          draft.totalClientCount = 0;
        });
      }
      break;

    case CLIENTS_FETCH_DASHBOARD_SUCCESS:
      {
        const { clientsData } = action.payload;
        // console.log(
        //   "dashBoardReducer: CLIENTS_FETCH_DASHBOARD_SUCCESS ",
        //   clientsData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.clientsData = clientsData?.clientDetails;
          draft.totalClientCount =
            clientsData?.clientDetails?.totalClientCount || 0;
        });
      }
      break;

    case CLIENTS_FETCH_DASHBOARD_FAILURE:
      {
        return produce(state, (draft) => {
          draft.clientsData = [];
          draft.totalClientCount = 0;
        });
      }
      break;

    case PRODUCTS_FETCH_DASHBOARD:
      {
        return produce(state, (draft) => {
          draft.productsData = [];
          draft.totalProductCount = 0;
        });
      }
      break;

    case PRODUCTS_FETCH_DASHBOARD_SUCCESS:
      {
        const { productsData } = action.payload;
        // console.log(
        //   "dashBoardReducer: PRODUCTS_FETCH_DASHBOARD_SUCCESS ",
        //   productsData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.productsData = productsData?.productDetails;
          draft.totalClientCount =
            productsData?.productDetails?.totalProductCount || 0;
        });
      }
      break;

    case PRODUCTS_FETCH_DASHBOARD_FAILURE:
      {
        return produce(state, (draft) => {
          draft.productsData = [];
          draft.totalProductCount = 0;
        });
      }
      break;

    case FETCH_CHARTS_DATA:
      {
        return produce(state, (draft) => {
          draft.chartsData = [];
          draft.totalPrice = 0;
          draft.currentMonthQuoteCount = 0;
        });
      }
      break;

    case FETCH_CHARTS_DATA_SUCCESS:
      {
        const { chartsData } = action.payload;
        // console.log(
        //   "dashBoardReducer: CHARTS_DATA_SUCCESS ",
        //   chartsData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.chartsData = chartsData?.quoteByDayMonth;
          draft.totalPrice = chartsData?.quoteByDayMonth?.totalPrice || 0;
          draft.currentMonthQuoteCount =
            chartsData?.quoteByDayMonth?.currentMonthQuoteCount || 0;
        });
      }
      break;

    case FETCH_CHARTS_DATA_FAILURE:
      {
        return produce(state, (draft) => {
          draft.chartsData = [];
          draft.totalPrice = 0;
          draft.currentMonthQuoteCount = 0;
        });
      }
      break;
    case FETCH_PIE_CHARTS_DATA:
      {
        return produce(state, (draft) => {
          draft.pieChartsData = [];
          // draft.totalPrice = 0;
          // draft.currentMonthQuoteCount = 0;
        });
      }
      break;

    case FETCH_PIE_CHARTS_DATA_SUCCESS:
      {
        const { pieChartsData } = action.payload;
        // console.log(
        //   "dashBoardReducer: FETCH_PIE_CHARTS_DATA_SUCCESS ",
        //   pieChartsData,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.pieChartsData = pieChartsData?.quoteByStatusInfo?.quoteByStatus;
          draft.id = pieChartsData?.quoteByStatusInfo?.quoteByStatus?.id;
          draft.count = pieChartsData?.quoteByStatusInfo?.quoteByStatus?.count;
        });
      }
      break;

    case FETCH_PIE_CHARTS_DATA_FAILURE:
      {
        return produce(state, (draft) => {
          draft.pieChartsData = [];
          // draft.totalPrice = 0;
          // draft.currentMonthQuoteCount = 0;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default dashBoardReducer;
