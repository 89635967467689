import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AddItems from "./AddItems";
import { Grid, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QuoteCalculater from "../../QuoteCalculater/QuoteCalculater";
import ProductsAddList from "./components/ProductsAddList/ProductsAddList";
import Summary from "./components/Summary";

export default function QuoteItems({ handleNext, handleBack, activeStep }) {
  const [alignment, setAlignment] = React.useState("dynamic");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const renderContent = () => {
    switch (alignment) {
      case "dynamic":
        // return <AddItems />;
      case "hoseAssembly":
        return <QuoteCalculater type={"QOUTE"} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ mb: 3 }}
          >
            <ToggleButton value="dynamic">Dynamic</ToggleButton>
            <ToggleButton value="hoseAssembly">Hose Assembly</ToggleButton>
          </ToggleButtonGroup> */}

          {renderContent()}
        </Grid>
        <Grid item xs={12}>
          <ProductsAddList />
        </Grid>
        <Grid item xs={12}>
          <Summary />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
            >
              Back
            </Button>
            <Grid item xs="auto">
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
