/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  // SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  SIGNOUT,
  SILENT_LOGIN,
  SIGNUP_MOBILE, SIGNUP_MOBILE_SUCCESS, SIGNUP_MOBILE_FAILURE,
  SIGNUP_MOBILE_VERIFY_OTP, SIGNUP_MOBILE_VERIFY_OTP_SUCCESS, 
  SIGNUP_MOBILE_VERIFY_OTP_FAILURE,
  SIGNIN_MOBILE_REQ_OTP,
  SIGNIN_MOBILE_REQ_OTP_FAILURE,
  SIGNIN_MOBILE_OTP_VERIFY, SIGNIN_MOBILE_OTP_VERIFY_SUCCESS, 
  SIGNIN_MOBILE_OTP_VERIFY_FAILURE
  
} from 'actions/authActions';

import { BUSINESS_TYPES, USER_ROLES } from 'config';


const initialState = {
  user: undefined,
  activeRole: USER_ROLES.USER,
  businessInfo : {
    type : BUSINESS_TYPES.GENERAL,
  },

  newUserInfo: null,
  signUpMobile: null,
  signInMobile: null,
  signUpRegisterName: null,
  signUpRegisterMobile: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {


    case SIGNIN_MOBILE_REQ_OTP: {
      const { mobile } = action.payload;

      // console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);

      return produce(state, (draft) => {
        draft.signInMobile = mobile;
      });
    }
    break;

    case SIGNIN_SUCCESS:    
    case SIGNIN_MOBILE_OTP_VERIFY_SUCCESS:
    case SILENT_LOGIN:
    case SIGNUP_MOBILE_VERIFY_OTP_SUCCESS: {
      const { user } = action.payload;

      // console.log("authReducer: SIGNIN_SUCCESS ", action.type, user)

      return produce(state, (draft) => {
        draft.user = user;
        // draft.user.avatar = "SP";
        draft.activeRole = user?.userType;  
        draft.role = user?.userType;    
        draft.businessInfo = {
          type : BUSINESS_TYPES.GENERAL,
        };



      });
    }
    break;

    case SIGNIN: {
    // case SIGNIN_WITH_MOBILE_OTP: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.activeRole = null;
        draft.role = null;

      });
    }
    break;

    case SIGNIN_FAILURE: 
    case SIGNIN_MOBILE_REQ_OTP_FAILURE:
    case SIGNIN_MOBILE_OTP_VERIFY_FAILURE:
    case SIGNUP_MOBILE_FAILURE:
    case SIGNUP_MOBILE_VERIFY_OTP_FAILURE: {
      return produce(state, (draft) => {
        draft.user = null;
        // Maybe store error
      });
    }
    break;


    case SIGNUP_MOBILE: {
      const {name,  mobile } = action.payload;
      //console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);
      return produce(state, (draft) => {
        draft.signUpRegisterName = name;
        draft.signUpRegisterMobile = mobile;

        draft.user = null;
        // console.log("mobile number =>>>>>>>>.",mobile)
      });
    }
    break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.newUserInfo = null;
        draft.signUpMobile = null;
        draft.signInMobile = null;
        draft.signUpRegisterName = null;
        draft.signUpRegisterMobile = null;
      });
    }
    break;

    default: {
      return state;
    }
    break;
  }
};

export default authReducer;
