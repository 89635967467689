import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Container,
} from "@mui/material";
import CustomerForm from "./Components/CustomerForm";
import AddressForm from "./Components/AddressForm";
import { Dashboard as DashboardLayout } from "layouts";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const ProfileCustomer = ({ onSubmitSuccess }) => {
  
  const clientEdit = useSelector((state) => state.clientEdit);
  const clientDataToEdit = clientEdit.clientDataToEdit;
  const isClientEdit = clientEdit.isClientEdit;
  // console.log("clientEdit :", clientEdit, clientDataToEdit, isClientEdit);

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Customers</title>
      </Helmet>
      <Grid container spacing={2} mt={15} mb={10}>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader title={"Customer Profile"} />
            <Divider />
            <CardContent>
              <CustomerForm
                onSubmitSuccess={onSubmitSuccess}
                data={clientDataToEdit}
                isClientEdit={isClientEdit}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader
              title={"Customer Profile Register Address"}
              onSubmitSuccess={onSubmitSuccess}
            />
            <Divider />
            <CardContent>
              <AddressForm />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

ProfileCustomer.propTypes = {
  onSubmitSuccess: PropTypes.func,
};

export default ProfileCustomer;
