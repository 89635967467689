import dashboardService from "../services/dashboardService";
export const QUOTE_FETCH_DASHBOARD = "@quote/fetch-dashboard";
export const QUOTE_FETCH_DASHBOARD_SUCCESS = "@quote/fetch-dashboard-success";
export const QUOTE_FETCH_DASHBOARD_FAILURE = "@quote/fetch-dashboard-failure";

export const USERS_FETCH_DASHBOARD = "@user/fetch-dashboard";
export const USERS_FETCH_DASHBOARD_SUCCESS = "@user/fetch-dashboard-success";
export const USERS_FETCH_DASHBOARD_FAILURE = "@user/fetch-dashboard-failure";

export const CLIENTS_FETCH_DASHBOARD = "@clients/fetch-dashboard";
export const CLIENTS_FETCH_DASHBOARD_SUCCESS =
  "@clients/fetch-dashboard-success";
export const CLIENTS_FETCH_DASHBOARD_FAILURE =
  "@clients/fetch-dashboard-failure";

export const PRODUCTS_FETCH_DASHBOARD = "@products/fetch-dashboard";
export const PRODUCTS_FETCH_DASHBOARD_SUCCESS =
  "@products/fetch-dashboard-success";
export const PRODUCTS_FETCH_DASHBOARD_FAILURE =
  "@products/fetch-dashboard-failure";

export const FETCH_CHARTS_DATA = "@charts/fetch-dashboard";
export const FETCH_CHARTS_DATA_SUCCESS = "@charts/fetch-dashboard-success";
export const FETCH_CHARTS_DATA_FAILURE = "@charts/fetch-dashboard-failure";

export const FETCH_PIE_CHARTS_DATA = "@pie-charts/fetch-dashboard";
export const FETCH_PIE_CHARTS_DATA_SUCCESS =
  "@pie-charts/fetch-dashboard-success";
export const FETCH_PIE_CHARTS_DATA_FAILURE =
  "@pie-charts/fetch-dashboard-failure";

export function fetchQuoteDashboard() {
  return async (dispatch) => {
    try {
      dispatch({
        type: QUOTE_FETCH_DASHBOARD,
      });
      const quotesData = await dashboardService.fetchQuoteDashboard();

      // console.log("dashboardAction.fetchQuoteDashboard Resp:", quotesData);

      dispatch({
        type: QUOTE_FETCH_DASHBOARD_SUCCESS,
        payload: {
          quotesData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchQuoteDashboard: Error", error);
      dispatch({ type: QUOTE_FETCH_DASHBOARD_FAILURE });
      throw error;
    }
  };
}

export function fetchUserDashboard() {
  return async (dispatch) => {
    try {
      dispatch({
        type: USERS_FETCH_DASHBOARD,
      });
      const usersData = await dashboardService.fetchUserDashboard();

      // console.log("dashboardAction.fetchUserDashboard Resp:", usersData);

      dispatch({
        type: USERS_FETCH_DASHBOARD_SUCCESS,
        payload: {
          usersData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchQuoteDashboard: Error", error);
      dispatch({ type: USERS_FETCH_DASHBOARD_FAILURE });
      throw error;
    }
  };
}
export function fetchClientDashboard() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLIENTS_FETCH_DASHBOARD,
      });
      const clientsData = await dashboardService.fetchClientDashboard();

      // console.log("dashboardAction.fetchClientDashboard Resp:", clientsData);

      dispatch({
        type: CLIENTS_FETCH_DASHBOARD_SUCCESS,
        payload: {
          clientsData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchClientDashboard: Error", error);
      dispatch({ type: CLIENTS_FETCH_DASHBOARD_FAILURE });
      throw error;
    }
  };
}
export function fetchProductDashboard() {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRODUCTS_FETCH_DASHBOARD,
      });
      const productsData = await dashboardService.fetchProductDashboard();

      // console.log("dashboardAction.fetchProductDashboard Resp:", productsData);

      dispatch({
        type: PRODUCTS_FETCH_DASHBOARD_SUCCESS,
        payload: {
          productsData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchProductDashboard: Error", error);
      dispatch({ type: PRODUCTS_FETCH_DASHBOARD_FAILURE });
      throw error;
    }
  };
}
export function fetchChartsData() {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_CHARTS_DATA,
      });
      const chartsData = await dashboardService.fetchChartsData();

      // console.log("dashboardAction.fetchChartsData Resp:", chartsData);

      dispatch({
        type: FETCH_CHARTS_DATA_SUCCESS,
        payload: {
          chartsData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchQuoteDashboard: Error", error);
      dispatch({ type: FETCH_CHARTS_DATA_FAILURE });
      throw error;
    }
  };
}
export function fetchPieChartsData() {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_PIE_CHARTS_DATA,
      });
      const pieChartsData = await dashboardService.fetchPieChartsData();

      // console.log("dashboardAction.fetchPieChartsData Resp:", pieChartsData);

      dispatch({
        type: FETCH_PIE_CHARTS_DATA_SUCCESS,
        payload: {
          pieChartsData,
        },
      });
    } catch (error) {
      // console.log("dashboardAction.fetchPieChartsData: Error", error);
      dispatch({ type: FETCH_PIE_CHARTS_DATA_FAILURE });
      throw error;
    }
  };
}
