import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { headCells } from "./utils";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addConfiguratorProduct,
  removeAssembleProduct,
} from "../../../../../actions/catalogAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CoPresentOutlined } from "@mui/icons-material";

const AddProduct = ({ handleNext, handleBack, activeStep }) => {
  const [inputValue, setInputValue] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataToEdit = useSelector((state) => state.configurator?.dataToEdit);
  const isAssembledProductEdit = useSelector(
    (state) => state.configurator?.isAssembledProductEdit
  );
  // console.log("dataToEdit =>>>>>", isAssembledProductEdit, dataToEdit);

  const assembledProductId = dataToEdit?.assembledProductId;
  // console.log("assembledProductId", assembledProductId);

  const product = useSelector((state) => state.product);
  const initialOptions = product?.products.map((product) => ({
    label: product.name,
    id: product.productId,
    size: product.size,
  }));

  const [options, setOptions] = useState(initialOptions);

  const filterOptionsBySize = (size) => {
    const filtered = options.filter((product) => product.size === size);
    setFilteredOptions(filtered);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (!newInputValue && selectedSize) {
      filterOptionsBySize(selectedSize);
    }
  };

  const handleOptionSelect = (event, value) => {
    // console.log("value", value);
    setSelectedOption(value);
    setSelectedSize(value?.size);
    if (value) {
      filterOptionsBySize(value?.size);
    }
  };
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };
  const handleButtonClick = () => {
    if (selectedOption) {
      const updatedOptions = options.filter(
        (product) => product.id !== selectedOption.id
      );
      setOptions(updatedOptions);

      dispatch(
        addConfiguratorProduct(selectedOption.id, assembledProductId, quantity)
      );

      setSelectedOption(null);
    }
  };

  const handleDelete = (productId) => {
    const deletedProduct = dataToEdit?.products.find(
      (product) => product.product.productId === productId
    );

    if (deletedProduct) {
      setFilteredOptions((prevOptions) => [
        ...prevOptions,
        {
          label: deletedProduct.product.name,
          id: deletedProduct.product.productId,
          size: deletedProduct.product.size,
        },
      ]);
    }

    dispatch(removeAssembleProduct(productId, assembledProductId));
  };

  // const handleButtonClick = () => {
  //   if (selectedOption) {
  //     dispatch(
  //       addConfiguratorProduct(selectedOption.id, assembledProductId, quantity)
  //     );
  //   }
  // };

  // const handleDelete = (productId) => {
  //   dispatch(removeAssembleProduct(productId, assembledProductId));
  // };

  useEffect(() => {}, [dataToEdit?.products]);
  // console.log("dataToEdit?.products", dataToEdit?.products);
  return (
    <Card sx={{ boxShadow: 3, p: 1, width: "100%" }}>
      <CardHeader title="Add Products" />
      <Divider />
      <Grid container spacing={2} pt={2}>
        <Grid item xs={6}>
          <Autocomplete
            options={filteredOptions.length > 0 ? filteredOptions : options}
            renderOption={(props, option) => (
              <li {...props}>
                <div>{option.label}</div>
                {/* <div style={{ marginLeft: "30px" }}>{option?.size}</div> */}
              </li>
            )}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleOptionSelect}
            value={selectedOption}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                required
                placeholder="Select With Product Name"
                label="Products"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Quantity"
            variant="outlined"
            required
            value={selectedOption ? quantity : ""}
            onChange={handleQuantityChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Add Product
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ height: 400, width: "100%", pt: 1 }}>
        <DataGrid
          rows={dataToEdit?.products}
          columns={headCells(true, handleDelete, modal, setModal)}
          getRowId={(row) => row.product?.productId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
        />
      </Box>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back
          </Button>
          <Grid item xs="auto">
            <Grid item xs="auto">
              <Button variant="contained" color="primary" onClick={handleNext}>
                Save Items & Go to Finalize Product
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default AddProduct;
