import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AddressContent from "../../../../../components/AddressContent";
import ConfirmDialog from "../../../../../components/ConfirmDialog";

import PropTypes from "prop-types";

import { deleteOtherAddress } from "../../../../../actions/registeredAddressAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
 
  
const OtherAddress = ({ onClick, addresses, onClickOther }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const clientEdit = useSelector((state) => state?.clientEdit);
  // console.log("clientEdit: ", clientEdit);
  const clientDataToEdit = clientEdit.clientDataToEdit;
  // console.log("clientDataToEdit: ", clientDataToEdit);

  const clientId = clientDataToEdit?.clientId ? clientDataToEdit?.clientId : [];
  // console.log("clientId: ", clientId);
  const [deleteValue, setDeleteValue] = useState();

  const handleClick = (item) => {
    setDeleteValue(item);
    setOpen(true);
  };

  // console.log("deleteValue", deleteValue);

  const handleClose = () => {
    dispatch(
      deleteOtherAddress({
        clientId: clientId,
        label: deleteValue.label ? deleteValue.label : {},
      })
    );
    setOpen();
    navigate("/app/mgmt/customer/my");
  };

  const handleCloseOrderAddressDelete = () => {
    setOpen();
  };
  useEffect(() => {}, [clientEdit]);
  return (
    <Grid>
      <Card sx={{ boxShadow: 3, mb: 3 }}>
        <CardHeader title={"Other Address"} />
        <Divider />
        {addresses.map((item) => (
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={10}>
                <CardContent>
                  <AddressContent
                    label={item.label}
                    address1={item.address1}
                    address2={item.address2}
                    state={item.state}
                    city={item.city}
                    country={item.country}
                    pincode={item.pincode}
                  />
                </CardContent>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title={"Delete Address"}>
                  <IconButton
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <ConfirmDialog
                    title="Are you Sure"
                    description={`Do you want to permanently delete this Other Address ,action can't be undone ${item.label}`}
                    isOpen={open}
                    confirmAction={handleClose}
                    cancelAction={handleCloseOrderAddressDelete}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        ))}
        <CardContent>
          <Button variant="outlined" fullWidth onClick={onClickOther}>
            Add Other Address
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

OtherAddress.prototype = {
  onClick: PropTypes.func,
  addresses: PropTypes.object,
  onClickOther: PropTypes.func,
};

export default OtherAddress;
