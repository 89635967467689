/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  PRODUCT_FETCH,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_FETCH_FAILURE,
  PRODUCT_CREATE_TRIGGER,
  PRODUCT_EDIT_TRIGGER,
  PRODUCT_UPDATE_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  CUSTOM_PRODUCT_FETCH,
  CUSTOM_PRODUCT_FETCH_SUCCESS,
  CUSTOM_PRODUCT_FETCH_FAILURE,
  CREATE_ASSEMBLE_PRODUCT_SUCCESS,
  ADD_CONFIG_PRODUCT_SUCCESS,
  ASSEMBLED_PRODUCT_EDIT_TRIGGER,
  ASSEMBLED_PRODUCT_CREATE_TRIGGER,
  ASSEMBLED_PRODUCT_UPDATE_SUCCESS,
  FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS,
  FETCH_HOSE_ASSEMBLY_BY_CRITERIA_SUCCESS,
  FETCH_TOTAL_AMOUNT,
  FETCH_TOTAL_AMOUNT_SUCCESS,
  FETCH_TOTAL_AMOUNT_FAILURE,
} from "actions/catalogAction";

const initialState = {
  products: [],
  productDataToEdit: undefined,
  isProductEdit: false,
  customProducts: [],
  assembledProduct: [],
  totalAmount: [],
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_FETCH:
      {
        return produce(state, (draft) => {
          draft.products = [];
          draft.count = 0;
        });
      }
      break;

    case PRODUCT_FETCH_SUCCESS:
      {
        const { products } = action.payload;
        // console.log("projectReducer: PROJECT_FETCH_SUCCESS ", products, action.payload);

        return produce(state, (draft) => {
          draft.products = products;
          draft.count = products?.length;
        });
      }
      break;

    case PRODUCT_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.products = [];
          draft.count = 0;
        });
      }
      break;
    case PRODUCT_UPDATE_SUCCESS:
    case ADD_PRODUCT_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.productDataToEdit = undefined;
          draft.isProductEdit = false;
        });
      }
      break;
    case PRODUCT_EDIT_TRIGGER:
      {
        console.log("catalogReducer() PRODUCT_EDIT_TRIGGER", action.payload);
        const { productEdit } = action.payload;
        return produce(state, (draft) => {
          draft.isProductEdit = true;
          draft.productDataToEdit = productEdit;
        });
      }
      break;

    case PRODUCT_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.productDataToEdit = undefined;
          draft.isProductEdit = false;
        });
      }
      break;

    case CUSTOM_PRODUCT_FETCH:
      {
        return produce(state, (draft) => {
          draft.customProducts = [];
          draft.count = 0;
        });
      }
      break;

    case CUSTOM_PRODUCT_FETCH_SUCCESS:
      {
        const { customProducts } = action.payload;
        // console.log("projectReducer: PROJECT_FETCH_SUCCESS ", customProducts, action.payload);

        return produce(state, (draft) => {
          draft.customProducts = customProducts;
          draft.count = customProducts?.length;
        });
      }
      break;

    case CUSTOM_PRODUCT_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.customProducts = [];
          draft.count = 0;
        });
      }
      break;

    // case ASSEMBLED_PRODUCT_UPDATE_SUCCESS:
    //   {
    //     const { customProductsUpdate } = action.payload;
    //     console.log(
    //       "catalogReducer() ASSEMBLED_PRODUCT_UPDATE_SUCCESS",
    //       customProductsUpdate,
    //       action.payload
    //     );
    //     return produce(state, (draft) => {
    //       draft.assembledProductDataToEdit = customProductsUpdate;
    //       draft.isAssembledProductEdit = true;
    //     });
    //   }
    //   // {
    //   //   return produce(state, (draft) => {
    //   //     draft.assembledProductDataToEdit = undefined;
    //   //     draft.isAssembledProductEdit = false;
    //   //   });
    //   // }
    //   break;
    case FETCH_HOSE_ASSEMBLY_BY_CRITERIA_SUCCESS:
      {
        const { requestList } = action.payload;
        // console.log("FETCH_HOSE_ASSEMBLY_BY_CRITERIA_SUCCESS", requestList);
        return produce(state, (draft) => {
          draft.assembledProduct = requestList;
        });
      }
      break;
    case FETCH_TOTAL_AMOUNT:
      {
        return produce(state, (draft) => {
          draft.totalAmount = [];
          // draft.count = 0;
        });
      }
      break;

    case FETCH_TOTAL_AMOUNT_SUCCESS:
      {
        const { totalAmount } = action.payload;
        // console.log("FETCH_TOTAL_AMOUNT_SUCCESS", totalAmount);
        return produce(state, (draft) => {
          draft.totalAmount = totalAmount?.totalPayableAmount;
        });
      }
      break;

    case FETCH_TOTAL_AMOUNT_FAILURE:
      {
        return produce(state, (draft) => {
          draft.totalAmount = [];
          // draft.count = 0;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default catalogReducer;
