import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { updatePassword } from "../../../../../actions/authActions";

// import { AdminEditPassWord } from "src/actions/mgmtAdminAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

function ChangePasswordForm({
  onSubmitSuccess,
  isPasswordEdit,
  passwordDataToEdit,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: passwordDataToEdit?.email || "",
        newPassword: "",
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().max(8).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", values);
        try {
          await dispatch(
            updatePassword(values.newPassword, passwordDataToEdit?.userId)
          );
          onSubmitSuccess();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Password Changed Successfully", {
            variant: "success",
          });
        } catch (err) {
          enqueueSnackbar("Password Not Changed,Something went wrong", {
            variant: "failure",
          });
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleKeyDown,
        handleKeyUp,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    fullWidth
                    helperText={touched.newPassword && errors.newPassword}
                    label="New Password"
                    margin="normal"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.newPassword}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!isPasswordEdit}
              style={{ marginBottom: "5em" }}
            >
              Chang Password
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

ChangePasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onClick: PropTypes.func,
};

export default ChangePasswordForm;
