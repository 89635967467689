import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Container,
} from "@mui/material";

import PropTypes from "prop-types";

const AddressContent = ({
  label,
  address1,
  address2,
  state,
  city,
  country,
  pincode,
}) => {
  return (
    <Grid sx={{ color: "#443A4F" }}>
      <Grid
        sx={{
          display: "inline-block",
          bgcolor: "#443A4F25",
          borderRadius: 1,
        }}
      >
        <Typography variant="body1" textTransform="capitalize">
          {label}
        </Typography>
      </Grid>
      <Typography variant="body1" textTransform="capitalize">
        {address1}
      </Typography>
      <Typography variant="body1" textTransform="capitalize">
        {address2}
      </Typography>
      <Typography
        variant="body1"
        // textAlign="center"
        textTransform="capitalize"
        m={"2px"}
        // sx={{ display: "inline-block" }}
      >
        {state}
      </Typography>
      <Typography
        variant="body1"
        // textAlign="center"
        textTransform="capitalize"
        m={"2px"}
        // sx={{ display: "inline-block" }}
      >
        {city}
      </Typography>
      <Typography
        variant="body1"
        // textAlign="center"
        // textTransform="capitalize"
        m={"2px"}
        // sx={{ display: "inline-block" }}
      >
        {country}
      </Typography>
      <Typography
        variant="body1"
        // textAlign="center"
        m={"2px"}
        textTransform="capitalize"
        // sx={{ display: "inline-block" }}
      >
        {pincode}
      </Typography>
    </Grid>
  );
};

AddressContent.propTypes = {
  label: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  address: PropTypes.string,
  pincode: PropTypes.string,
};

export default AddressContent;
