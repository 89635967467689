import {
  NewQuote,
  AddCustomerAddress,
  AddItem,
  AddTNC,
  AddToQoute,
} from "../interface/quoteIf";
import quoteService from "../services/quoteService";

export const QUOTE_CREATE = "@quote/create";
export const QUOTE_CREATE_SUCCESS = "@quote/create-success";
export const QUOTE_CREATE_FAILURE = "@quote/create-failure";

export const QUOTE_REMOVE = "@quote/remove";
export const QUOTE_REMOVE_SUCCESS = "@quote/remove-success";
export const QUOTE_REMOVE_FAILURE = "@quote/remove-failure";

export const REMOVE_QUOTE_ITEM = "@quote-item/remove";
export const REMOVE_QUOTE_ITEM_SUCCESS = "@quote-item/remove-success";
export const REMOVE_QUOTE_ITEM_FAILURE = "@quote-item/remove-failure";

export const QUOTE_UPDATE = "@quote/update";
export const QUOTE_UPDATE_SUCCESS = "@quote/update-success";
export const QUOTE_UPDATE_FAILURE = "@quote/update-failure";

export const QUOTET_CREATE_TRIGGER = "@quote/create-trigger";

export const QUOTE_UPDATE_CUSTOMER_ADDRESS = "@quote/updatecustomeraddress";
export const QUOTE_UPDATE_CUSTOMER_ADDRESS_SUCCESS =
  "@quote/updatecustomeraddress-success";
export const QUOTE_UPDATE_CUSTOMER_ADDRESS_FAILURE =
  "@quote/updatecustomeraddress-failure";

export const QUOTE_UPDATE_ADD_ITEM = "@quote/updateadditem";
export const QUOTE_UPDATE_ADD_ITEM_SUCCESS = "@quote/updateadditem-success";
export const QUOTE_UPDATE_ADD_ITEM_FAILURE = "@quote/updateadditem-failure";

export const QUOTE_UPDATE_STATUS_URL = "@quote/updatedstatus";
export const QUOTE_UPDATE_STATUS_URL_SUCCESS = "@quote/updatedstatus-success";
export const QUOTE_UPDATE_STATUS_URL_FAILURE = "@quote/updatedstatus-failure";

export const QUOTE_GET_BY_ID = "@quote/getbyquoteid";
export const QUOTE_GET_BY_ID_SUCCESS = "@quote/getbyquoteid-success";
export const QUOTE_GET_BY_ID_FAILURE = "@quote/getbyquoteid-failure";

export const GET_QUOTE_BY_ID_TO_UPDATE = "@quote/getquotebyidtoupdate";
export const GET_QUOTE_BY_ID_TO_UPDATE_SUCCESS =
  "@quote/getquotebyidtoupdate-success";
export const GET_QUOTE_BY_ID_TO_UPDATE_FAILURE =
  "@quote/getquotebyidtoupdate-failure";

export const QUOTE_FETCH = "@quote/fetch";
export const QUOTE_FETCH_SUCCESS = "@quote/fetch-success";
export const QUOTE_FETCH_FAILURE = "@quote/fetch-failure";

export const MY_QUOTE_FETCH = "@my-quote/fetch";
export const MY_QUOTE_FETCH_SUCCESS = "@my-quote/fetch-success";
export const MY_QUOTE_FETCH_FAILURE = "@my-quote/fetch-failure";

export const MY_QUOTE_REMOVE = "@quote/remove";
export const MY_QUOTE_REMOVE_SUCCESS = "@quote/remove-success";
export const MY_QUOTE_REMOVE_FAILURE = "@quote/remove-failure";

export const QUOTE_FETCH_TNC = "@quote/fetch-tnc";
export const QUOTE_FETCH_TNC_SUCCESS = "@quote/fetch-tnc-success";
export const QUOTE_FETCH_TNC_FAILURE = "@quote/fetch-tnc-failure";

export const FETCH_QUOTE_BY_CRITERIA = "@quote/fetch-criteria";
export const FETCH_QUOTE_BY_CRITERIA_SUCCESS = "@quote/fetch-criteria-success";
export const FETCH_QUOTE_BY_CRITERIA_FAILURE = "@quote/fetch-criteria-failure";

export function quoteGetById(quoteId) {
  // console.log("quoteId", quoteId);
  return async (dispatch) => {
    try {
      dispatch({
        type: QUOTE_GET_BY_ID,
        payload: {
          quoteId,
        },
      });
      // console.log("quoteRequestAction.quoteGetById:", quoteId);

      const quote = await quoteService.quoteGetById(quoteId);

      // console.log("quoteRequestAction.quoteGetById Resp:", quote);
      dispatch({
        type: QUOTE_GET_BY_ID_SUCCESS,
        payload: {
          quote,
        },
      });
      // await dispatch(fetchClient());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: QUOTE_GET_BY_ID_FAILURE });
      throw error;
    }
  };
}

export function getQuoteByIdToUpdate(quoteId) {
  // console.log("quoteId", quoteId);
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_QUOTE_BY_ID_TO_UPDATE,
        payload: {
          quoteId,
        },
      });
      // console.log("quoteRequestAction.quoteGetById:", quoteId);

      const quote = await quoteService.quoteGetById(quoteId);

      // console.log("quoteRequestAction.quoteGetById Resp:", quote);
      dispatch({
        type: GET_QUOTE_BY_ID_TO_UPDATE_SUCCESS,
        payload: {
          quote,
        },
      });
      // await dispatch(fetchClient());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: GET_QUOTE_BY_ID_TO_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function createQuote(values, clientInfo, supplierInfo) {
  // console.log(
  //   "values, clientInfo, supplierAddress",
  //   values,
  //   clientInfo,
  //   supplierInfo
  // );
  return async (dispatch) => {
    try {
      const newQuoteInfo = new NewQuote(values, clientInfo, supplierInfo);

      dispatch({
        type: QUOTE_CREATE,
        payload: {
          newQuoteInfo,
        },
      });
      // console.log("quoteRequestAction.createQuote:", newQuoteInfo);

      const respData = await quoteService.createQuote(newQuoteInfo);

      // console.log("quoteRequestAction.createQuote: respData:", respData);

      const quote = respData?.quote;
      // console.log("quoteRequestAction.createQuote: quotes:", quote);

      dispatch({
        type: QUOTE_CREATE_SUCCESS,
        payload: {
          quote,
        },
      });
      //   await dispatch(fetchRequest());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.AddRequest:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function removeQuote(quoteId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: QUOTE_REMOVE,
        payload: {
          quoteId,
        },
      });
      // console.log("quoteRequestAction.removeQuote:", quoteId);

      const quoteList = await quoteService.removeQuote(quoteId);
      // console.log("quoteRequestAction.removeQuote Resp:", quoteList);
      dispatch({
        type: QUOTE_REMOVE_SUCCESS,
        payload: {
          quoteList,
        },
      });
      // await dispatch(fetchQuoteByCriteria());
    } catch (error) {
      // console.log("quoteRequestAction.removeQuote: Error", error);
      dispatch({ type: QUOTE_REMOVE_FAILURE });
      throw error;
    }
  };
}

export function createQuoteCustomerTNC(values) {
  return async (dispatch) => {
    try {
      // console.log("values", values);
      const newQuoteAddCustomerTNCInfo = new AddTNC(values);
      // console.log("newQuoteAddCustomerTNCInfo", newQuoteAddCustomerTNCInfo);
      dispatch({
        type: QUOTE_FETCH_TNC,
        payload: {
          newQuoteAddCustomerTNCInfo,
        },
      });
      // console.log(
      //   "quoteRequestAction.createQuote:",
      //   newQuoteAddCustomerTNCInfo
      // );

      const resp = await quoteService.updateCustomerTNC(
        newQuoteAddCustomerTNCInfo
      );
      const quote = resp?.address;
      // console.log("quoteRequestAction.createQuote: Resp:", resp);

      dispatch({
        type: QUOTE_FETCH_TNC_SUCCESS,
        payload: {
          quote,
        },
      });
      //   await dispatch(fetchRequest());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.AddRequest:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_FETCH_TNC_FAILURE });
      throw error;
    }
  };
}

export function createQuoteCustomerAddress(values) {
  return async (dispatch) => {
    try {
      console.log("values", values);
      const newQuoteAddCustomerAddressInfo = new AddCustomerAddress(values);
      // console.log(
      //   "newQuoteAddCustomerAddressInfo",
      //   newQuoteAddCustomerAddressInfo
      // );
      dispatch({
        type: QUOTE_UPDATE_CUSTOMER_ADDRESS,
        payload: {
          newQuoteAddCustomerAddressInfo,
        },
      });
      console.log(
        "quoteRequestAction.createQuote:",
        newQuoteAddCustomerAddressInfo
      );

      const resp = await quoteService.updateCustomerAddress(
        newQuoteAddCustomerAddressInfo
      );

      console.log("quoteRequestAction.createQuote: Resp:", resp);

      dispatch({
        type: QUOTE_UPDATE_CUSTOMER_ADDRESS_SUCCESS,
        payload: {
          resp,
        },
      });
      //   await dispatch(fetchRequest());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.AddRequest:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_UPDATE_CUSTOMER_ADDRESS_FAILURE });
      throw error;
    }
  };
}

export function createQuoteItems(values) {
  return async (dispatch) => {
    try {
      const newQuoteAddItemInfo = new AddItem(values);

      dispatch({
        type: QUOTE_UPDATE_ADD_ITEM,
        payload: {
          newQuoteAddItemInfo,
        },
      });
      // console.log("createQuoteItems.createQuoteItem:", newQuoteAddItemInfo);

      const quote = await quoteService.updateuoteAddItem(newQuoteAddItemInfo);

      // console.log("createQuoteItems.createQuoteItem: Resp:", resp);

      dispatch({
        type: QUOTE_UPDATE_ADD_ITEM_SUCCESS,
        payload: quote,
      });
      await dispatch(fetchQuoteByCriteria());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.AddRequest:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_UPDATE_ADD_ITEM_FAILURE });
      throw error;
    }
  };
}
export function addToQoute(values) {
  return async (dispatch) => {
    try {
      const newQuoteAddItemInfo = new AddToQoute(values);

      dispatch({
        type: QUOTE_UPDATE_ADD_ITEM,
        payload: {
          newQuoteAddItemInfo,
        },
      });
      // console.log("createQuoteItems.createQuoteItem:", newQuoteAddItemInfo);

      const quote = await quoteService.updateAddToQoute(newQuoteAddItemInfo);

      // console.log("createQuoteItems.createQuoteItem: Resp:", resp);

      dispatch({
        type: QUOTE_UPDATE_ADD_ITEM_SUCCESS,
        payload: quote,
      });
      // await dispatch(fetchQuoteByCriteria());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("projectAction.AddRequest:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_UPDATE_ADD_ITEM_FAILURE });
      throw error;
    }
  };
}

export function removeQuoteItem(skuid, quoteId) {
  // console.log("QuoteAction.removeQuoteItem:", skuid, quoteId);
  return async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_QUOTE_ITEM,
        payload: {
          skuid,
          quoteId
        },
      });
      // console.log("QuoteAction.removeQuoteItem:", skuid, quoteId);

      const quoteDetails = await quoteService.removeQuoteItem(skuid, quoteId);

      // console.log(
      //   "catalogAction.removeAssembleProduct Resp:",
      //   assembledProduct
      // );
      dispatch({
        type: REMOVE_QUOTE_ITEM_SUCCESS,
        payload: {
          quoteDetails,
        },
      });
      // await dispatch(fetchQuoteByCriteria());
    } catch (error) {
      // console.log("catalogAction.removeAssembleProduct: Error", error);
      dispatch({ type: REMOVE_QUOTE_ITEM_FAILURE });
      throw error;
    }
  };
}

// export const fetchQuoteList = (page) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: QUOTE_FETCH });
//       const quoteList = await quoteService.fetchQuoteList(page);
//       dispatch({
//         type: QUOTE_FETCH_SUCCESS,
//         payload: {
//           quoteList,
//           page,
//         },
//       });
//     } catch (error) {
//       dispatch({ type: QUOTE_FETCH_FAILURE, payload: { error } });
//     }
//   };
// };

export const fetchMyQuoteList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: MY_QUOTE_FETCH });
      const myQuoteList = await quoteService.fetchMyQuoteList();
      dispatch({
        type: MY_QUOTE_FETCH_SUCCESS,
        payload: {
          myQuoteList,
        },
      });
    } catch (error) {
      dispatch({ type: MY_QUOTE_FETCH_FAILURE, payload: { error } });
    }
  };
};
export function removeMyQuotes(quoteId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: MY_QUOTE_REMOVE,
        payload: {
          quoteId,
        },
      });
      // console.log("quoteRequestAction.removeQuote:", quoteId);

      const myQuoteList = await quoteService.removeMyQuotes(quoteId);
      // console.log("quoteRequestAction.removeQuote Resp:", quoteList);
      dispatch({
        type: MY_QUOTE_REMOVE_SUCCESS,
        payload: {
          myQuoteList,
        },
      });
      await dispatch(fetchMyQuoteList());
    } catch (error) {
      // console.log("quoteRequestAction.removeQuote: Error", error);
      dispatch({ type: MY_QUOTE_REMOVE_FAILURE });
      throw error;
    }
  };
}

export function updateQuote(values, clientInfo, supplierAddress, quoteId) {
  // console.log("client info", values, clientInfo, supplierAddress, quoteId);
  return async (dispatch) => {
    try {
      const newQuoteInfo = new NewQuote(
        values,
        clientInfo,
        supplierAddress,
        quoteId
      );

      dispatch({
        type: QUOTE_UPDATE,
        payload: {
          newQuoteInfo,
        },
      });
      // console.log("catalogAction.updateQuote:", newQuoteInfo);

      const resp = await quoteService.updateQuote(newQuoteInfo);

      // console.log("catalogAction.updateQuote Resp:", resp);

      dispatch({
        type: QUOTE_UPDATE_SUCCESS,
        payload: {
          resp,
        },
      });
      // await dispatch(fetchQuoteList());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateQuote: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: QUOTE_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function createQuoteTrigger() {
  return async (dispatch) => {
    dispatch({
      type: QUOTET_CREATE_TRIGGER,
      payload: {},
    });
  };
}

export function fetchQuoteByCriteria(
  supplierId,
  quoteId,
  quoteDate,
  status,
  page
) {
  // console.log(
  //   "fetchQuoteByCriteria IN Action ====>>>>",
  //   supplierId,
  //   quoteId,
  //   quoteDate,
  //   status,
  //   page
  // );
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_QUOTE_BY_CRITERIA,
        payload: {
          supplierId,
          quoteId,
          quoteDate,
          status,
          page,
        },
      });

      const requestList = await quoteService.fetchQuoteByCriteria(
        supplierId,
        quoteId,
        quoteDate,
        status,
        page
      );

      // console.log("quoteService.fetchQuoteByCriteria Resp:", requestList);

      dispatch({
        type: FETCH_QUOTE_BY_CRITERIA_SUCCESS,
        payload: {
          requestList,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_QUOTE_BY_CRITERIA_FAILURE });
      throw error;
    }
  };
}

export function updateQuoteStatus(quoteId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: QUOTE_UPDATE_STATUS_URL,
        payload: {
          quoteId,
        },
      });
      // console.log("1--> quoteRequestAction.updateQuoteStatus:", quoteId);
      const quoteStatus = await quoteService.updateQuoteStatus(quoteId);
      // console.log("1--> quoteRequestAction.quoteStatus Resp:", quoteStatus);
      dispatch({
        type: QUOTE_UPDATE_STATUS_URL_SUCCESS,
        payload: {
          quoteStatus,
        },
      });
    } catch (error) {
      // console.log("quoteRequestAction.removeQuote: Error", error);
      dispatch({ type: QUOTE_UPDATE_STATUS_URL_FAILURE });
      throw error;
    }
  };
}
