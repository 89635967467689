import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

function AuthGuard({ children }) {
  const auth = useSelector((state) => state.auth);

  // console.log("AuthGaurd: ", auth);

  if (!auth.user) {
    return <Navigate to="/signin" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
