import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { CUSTOMER_CATEGORY, STATE, COUNTRIES } from "config";

import { createQuoteCustomerAddress } from "../../../../actions/quoteActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchClient, fetchClientById } from "../../../../actions/clientAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AddressForm = ({ handleNext, handleBack, activeStep }) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const handleChangeCheck = (event, values, setFieldValue) => {
    // console.log("event", event);
    // console.log("values", values);
    // console.log("setFieldValue", setFieldValue);
    setChecked(event.target.checked);

    if (!checked && values) {
      setFieldValue("shipBillToAddress", values.billToAddress);
      setFieldValue("shipAddress1", values.address1);
      setFieldValue("shipAddress2", values.address2);
      setFieldValue("shipCity", values.city);
      setFieldValue("shipState", values.state);
      setFieldValue("shipCountry", values.country);
      setFieldValue("shipPincode", values.pincode);
    }
  };

  // console.log("checked :", checked);

  useEffect(() => {
    dispatch(fetchClient());
  }, []);

  const client = useSelector((state) => state?.client);
  const clientInfo = client?.clientById;
  const clientAddresses = clientInfo?.addresses ? clientInfo.addresses : [];
  const registeredAddress = [
    clientInfo?.registeredAddress ? clientInfo.registeredAddress : [],
  ];
  const addressConcat = clientAddresses.concat(registeredAddress);
  // console.log("addressConcat", addressConcat);
  const address = addressConcat ? addressConcat : [];
  const quote = useSelector((state) => state?.quote);
  const quoteInProgress = quote?.quoteInProgress;
  const quoteId = quoteInProgress?.quoteId;
  const quoteDetails = quoteInProgress?.quoteDetails;
  const billToAddressDetails = quoteInProgress?.billToDetails?.address;
  const shipToAddressDetails = quoteInProgress?.shipToDetails?.address;
  const [billToAddress, setBillToAddress] = useState(
    address[0] ? address[0] : []
  );
  const [shipToAddress, setShipToAddress] = useState(
    address[0] ? address[0] : []
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        quoteId: quoteId,
        billToAddress: billToAddressDetails?.label
          ? billToAddressDetails?.label
          : "",
        // billToLabel: "",
        businessName: clientInfo?.name ? clientInfo?.name : "",
        contactPerson: clientInfo?.contactPerson
          ? clientInfo.contactPerson
          : "",
        gstIn: clientInfo?.gstin ? clientInfo.gstin : "",
        contact: clientInfo?.contact ? clientInfo.contact : "",
        email: clientInfo?.email ? clientInfo.email : "",
        address1: billToAddress?.address1,
        address2: billToAddress?.address2,
        city: billToAddress?.city,
        state: billToAddress?.state,
        country: billToAddress?.country,
        pincode: billToAddress?.pincode,
        shipBillToAddress: shipToAddressDetails?.label
          ? shipToAddressDetails?.label
          : "",
        // shipBillToLabel: "",
        shipBusinessName: clientInfo?.name ? clientInfo?.name : "",
        shipContactPerson: clientInfo?.contactPerson
          ? clientInfo.contactPerson
          : "",
        shipgstIn: clientInfo?.gstin ? clientInfo.gstin : "",
        shipContact: clientInfo?.contact ? clientInfo.contact : "",
        shipEmail: clientInfo?.email ? clientInfo.email : "",
        shipAddress1: shipToAddressDetails?.address1
          ? shipToAddressDetails?.address1
          : "",
        shipAddress2: shipToAddressDetails?.address2
          ? shipToAddressDetails?.address2
          : "",
        shipCity: shipToAddressDetails?.city ? shipToAddressDetails?.city : "",
        shipState: shipToAddressDetails?.state
          ? shipToAddressDetails?.state
          : "",
        shipCountry: shipToAddressDetails?.country
          ? shipToAddressDetails?.country
          : "",
        shipPincode: shipToAddressDetails?.pincode
          ? shipToAddressDetails?.pincode
          : "",
      }}
      // validationSchema={Yup.object({
      //     billToAddress: Yup.string()
      //       .required("billToAddress is required")
      //       .max(80),
      //     businessName: Yup.string().max(80),
      //     contact: Yup.string()
      //       .matches(
      //         /^[6-9][0-9]{9}/,
      //         "Please enter valid 10 digit mobile number"
      //       )
      //       .min(10)
      //       .max(10)
      //       .required("Please Enter your mobile"),
      //     contactPerson: Yup.string().min(5).max(80),
      //     email: Yup.string()
      //       .email()
      //       .min(5)
      //       .max(80)
      //       .required("Please enter valid email address"),
      //     address1: Yup.string().required("Address1 is required").max(200),
      //     address2: Yup.string().required("Address2 is required").max(200),
      //     gstIn: Yup.string().min(1).max(15),
      //     city: Yup.string().min(3).max(80),
      //     pincode: Yup.string().min(1).max(10),
      //     shipBillToAddress: Yup.string()
      //       .required("billToAddress is required")
      //       .max(80),
      //     shipBusinessName: Yup.string().max(80),
      //     shipContact: Yup.string()
      //       .matches(
      //         /^[6-9][0-9]{9}/,
      //         "Please enter valid 10 digit mobile number"
      //       )
      //       .min(10)
      //       .max(10)
      //       .required("Please Enter your mobile"),
      //     shipContactPerson: Yup.string().min(5).max(80),
      //     shipEmail: Yup.string()
      //       .email()
      //       .min(5)
      //       .max(80)
      //       .required("Please enter valid email address"),
      //     shipAddress1: Yup.string().required("Address1 is required").max(200),
      //     shipAddress2: Yup.string().required("Address2 is required").max(200),
      //     shipgstIn: Yup.string().min(1).max(15).required(),
      //     shipCity: Yup.string().min(3).max(80).required(),
      //     shipPincode: Yup.string().min(1).max(10).required(),
      // })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("values :", values);
        try {
          await dispatch(createQuoteCustomerAddress(values));
          handleNext();
          //   if (data) {
          //     await dispatch(updateClient(values));
          //   } else {
          //     await dispatch(createClient(values));
          //   }
          //   onSubmitSuccess(navigate("/app/mgmt/customer/my"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
      //       onSubmit={(values) => {
      //   console.log("Form: submit", values)
      // }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          value={
                            values?.billToAddress
                              ? address.find(
                                  (option) =>
                                    option.label === values.businessName
                                )
                              : billToAddress
                          }
                          onChange={(event, newValue) => {
                            setFieldValue("billToAddress", newValue);
                            setBillToAddress(newValue);
                          }}
                          disablePortal
                          id="billToAddress"
                          options={address}
                          required
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Bill to Address"
                                required
                                error={Boolean(
                                  touched.billToAddress && errors.billToAddress
                                )}
                                helperText={
                                  touched.billToAddress && errors.billToAddress
                                }
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="businessName"
                          id="businessName"
                          label="Business Name"
                          type="string"
                          value={values.businessName}
                          variant="outlined"
                          fullWidth
                          required
                          // defaultValue={supplierDetails.businessName}
                          defaultValue="Hello World"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.businessName && errors.businessName
                          )}
                          helperText={
                            touched.businessName && errors.businessName
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="contactPerson"
                          id="contactPerson"
                          label="Contact Person"
                          type="string"
                          value={values.contactPerson}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.contactPerson && errors.contactPerson
                          )}
                          helperText={
                            touched.contactPerson && errors.contactPerson
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="gstIn"
                          id="gstIn"
                          label="GSTIn"
                          type="string"
                          value={values.gstIn}
                          variant="outlined"
                          fullWidth
                          // required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.gstIn && errors.gstIn)}
                          helperText={touched.gstIn && errors.gstIn}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="contact"
                          id="contact"
                          label="Contact"
                          type="string"
                          value={values.contact}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="email"
                          id="email"
                          label="Email"
                          type="string"
                          value={values.email}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address1"
                          id="address1"
                          label="Address1"
                          type="string"
                          value={values.address1}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.address1 && errors.address1)}
                          helperText={touched.address1 && errors.address1}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address2"
                          id="address2"
                          label="Address2"
                          type="string"
                          value={values.address2}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.address2 && errors.address2)}
                          helperText={touched.address2 && errors.address2}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="city"
                          id="city"
                          label="City"
                          type="string"
                          value={values.city}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          value={values.state}
                          onChange={(event, newValue) => {
                            setFieldValue("state", newValue);
                          }}
                          disablePortal
                          id="state"
                          options={STATE}
                          required
                          //   isOptionEqualToValue={(option, value) =>
                          //     option.id === value.id
                          //   }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="State"
                                error={Boolean(touched.state && errors.state)}
                                helperText={touched.state && errors.state}
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          value={values.country}
                          onChange={(event, newValue) => {
                            setFieldValue("country", newValue);
                          }}
                          disablePortal
                          id="country"
                          options={COUNTRIES}
                          required
                          //   isOptionEqualToValue={(option, value) =>
                          //     option.id === value.id
                          //   }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Country"
                                error={Boolean(
                                  touched.country && errors.country
                                )}
                                helperText={touched.country && errors.country}
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="pincode"
                          id="pincode"
                          label="Pincode"
                          type="string"
                          value={values.pincode}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.pincode && errors.pincode)}
                          helperText={touched.pincode && errors.pincode}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    Save and Next
                  </Button>
                </Grid> */}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          value={
                            values?.shipBillToAddress
                              ? address.find(
                                  (option) =>
                                    option.label === values.businessName
                                )
                              : billToAddress
                          }
                          onChange={(event, newValue) => {
                            setFieldValue("shipBillToAddress", newValue);
                            setShipToAddress(newValue);
                          }}
                          disablePortal
                          id="shipBillToAddress"
                          options={address}
                          required
                          getOptionLabel={(option) => option.label}
                          //   isOptionEqualToValue={(option, value) =>
                          //     option.id === value.id
                          //   }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Ship to Address"
                                error={Boolean(
                                  touched.shipBillToAddress &&
                                    errors.shipBillToAddress
                                )}
                                helperText={
                                  touched.shipBillToAddress &&
                                  errors.shipBillToAddress
                                }
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(event) => {
                                handleChangeCheck(event, values, setFieldValue);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Same as Bill to Address"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="shipBusinessName"
                          id="shipBusinessName"
                          label="Business Name"
                          type="string"
                          value={values.shipBusinessName}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          shipContactPerson
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipBusinessName && errors.shipBusinessName
                          )}
                          helperText={
                            touched.shipBusinessName && errors.shipBusinessName
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipContactPerson"
                          id="shipContactPerson"
                          label="Contact Person"
                          type="string"
                          value={values.shipContactPerson}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          shipgstIn
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipContactPerson &&
                              errors.shipContactPerson
                          )}
                          helperText={
                            touched.shipContactPerson &&
                            errors.shipContactPerson
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipgstIn"
                          id="shipgstIn"
                          label="GSTIn"
                          type="string"
                          value={values.shipgstIn}
                          variant="outlined"
                          fullWidth
                          // required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.shipgstIn && errors.shipgstIn)}
                          helperText={touched.shipgstIn && errors.shipgstIn}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipContact"
                          id="shipContact"
                          label="Contact"
                          type="string"
                          value={values.shipContact}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipContact && errors.shipContact
                          )}
                          helperText={touched.shipContact && errors.shipContact}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipEmail"
                          id="shipEmail"
                          label="Email"
                          type="string"
                          value={values.shipEmail}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.shipEmail && errors.shipEmail)}
                          helperText={touched.shipEmail && errors.shipEmail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="shipAddress1"
                          id="shipAddress1"
                          label="Address1"
                          type="string"
                          value={values.shipAddress1}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipAddress1 && errors.shipAddress1
                          )}
                          helperText={
                            touched.shipAddress1 && errors.shipAddress1
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="shipAddress2"
                          id="shipAddress2"
                          label="Address2"
                          type="string"
                          value={values.shipAddress2}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipAddress2 && errors.shipAddress2
                          )}
                          helperText={
                            touched.shipAddress2 && errors.shipAddress2
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipCity"
                          id="shipCity"
                          label="City"
                          type="string"
                          value={values.shipCity}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(touched.shipCity && errors.shipCity)}
                          helperText={touched.shipCity && errors.shipCity}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          value={values.shipState}
                          onChange={(event, newValue) => {
                            setFieldValue("shipState", newValue);
                          }}
                          disablePortal
                          id="shipState"
                          options={STATE}
                          required
                          //   isOptionEqualToValue={(option, value) =>
                          //     option.id === value.id
                          //   }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="State"
                                error={Boolean(
                                  touched.shipState && errors.shipState
                                )}
                                helperText={
                                  touched.shipState && errors.shipState
                                }
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          value={values.shipCountry}
                          onChange={(event, newValue) => {
                            setFieldValue("shipCountry", newValue);
                          }}
                          disablePortal
                          id="shipCountry"
                          options={COUNTRIES}
                          required
                          //   isOptionEqualToValue={(option, value) =>
                          //     option.id === value.id
                          //   }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Country"
                                error={Boolean(
                                  touched.shipCountry && errors.shipCountry
                                )}
                                helperText={
                                  touched.shipCountry && errors.shipCountry
                                }
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="shipPincode"
                          id="shipPincode"
                          label="Pincode"
                          type="string"
                          value={values.shipPincode}
                          variant="outlined"
                          fullWidth
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 80 }}
                          error={Boolean(
                            touched.shipPincode && errors.shipPincode
                          )}
                          helperText={touched.shipPincode && errors.shipPincode}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    Save and Next
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBackIcon />}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Grid item xs="auto">
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save Address & Go Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default AddressForm;

// ShipToAddressForm: {
//   shipBillToAddress: Yup.string()
//     .required("billToAddress is required")
//     .max(80),
//     shipBusinessName: Yup.string().max(80),
//     shipContact: Yup.string()
//     .matches(
//       /^[6-9][0-9]{9}/,
//       "Please enter valid 10 digit mobile number"
//     )
//     .min(10)
//     .max(10)
//     .required("Please Enter your mobile"),
//     shipContactPerson: Yup.string().min(5).max(80),
//     shipEmail: Yup.string()
//     .email()
//     .min(5)
//     .max(80)
//     .required("Please enter valid email address"),
//     shipAddress1: Yup.string().required("Address1 is required").max(200),
//     shipAddress2: Yup.string().required("Address2 is required").max(200),
//     shipgstIn: Yup.string().min(1).max(15).required(),
//     shipCity: Yup.string().min(3).max(80).required(),
//     shipPincode: Yup.string().min(1).max(10).required(),
// },

{
  /* <ShipToAddressForm
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    checked={checked}
                    onChangeCheck={handleChangeCheck}
                  /> */
}
{
  /* <BillToAddressForm
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    optionLabelBillingAddress={clientAddress}
                    getOptionLabel={(option) => option.label}
                    // handleBillToAddressChange={}
                  /> */
}
