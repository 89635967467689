export const APP_NAME = process.env.REACT_APP_APP_NAME
  ? process.env.REACT_APP_APP_NAME
  : "MindsClik App";
export const APP_VERSION = "1.0.0";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const ENABLE_REDUX_LOGGER = false;

export const OTP_COUNTER = 30;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
};

export const BUSINESS_TYPES = {
  GENERAL: "general",
};

export const USER_ROLES = {
  ADMIN: "ADMIN", // SALES MANAGER
  USER: "USER", // SALES AGENT
  CLIENT: "CLIENT",
  // ENGINEER: "ENGINEER",
  // VENDOR: "VENDOR",
  SALES_AGENT: "SALES_AGENT",
  SALES_MANAGER: "SALES_MANAGER",
  // SUPER_ADMIN: "SUPER_ADMIN",
  OWNER: "OWNER",
};

export const USER_TYPES = [
  "USER",
  "SALES_AGENT",
  "SALES_MANAGER",
  "ADMIN",
  "OWNER",
  "SUPPORT",
];
export const BUSINESS_ID = "0";
export const PRIORITY = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];
export const STATUS = ["NEW", "ASSIGN", "IN PROGRESS", "COMPLETE", "DONE"];

export const CUSTOMER_CATEGORY = ["A", "B", "C", "D"];
export const COUNTRIES = ["India"];

export const STATE = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
export const PRODUCT_TYPES = ["SIMPLE", "ASSEMBLED", "SERVICE"];
export const PRODUCT_CATEGORY = [
  "HOSE",
  "FITTING",
  "ADAPTOR",
  "WIRE BREADING",
  "FIRE SLEEVE",
  "GLASS FIBER",
  "SPRING",
];
//export const PRODUCT_CATEGORY = ["COILS", "TUBES", "PANEL", "HEADERS", "BOILERS", "HEAT EXCHANGERS", "MISC"];
export const UOM = ["Kg", "Mtr", "Qty"];

export const SUPPLIERS = [
  {
    businessName: "M.S. Enterprises",
    supplierId: 1,
    address: {
      label: "office",
      address1: "Ashish 4-A Gorepeth",
      address2: "Opp GS College",
      state: "Maharashtra",
      city: "Nagpur",
      country: "India",
      pincode: 440010,
    },
    contactPerson: "Pallavi Mahindrakar",
    email: "msenterprisesngp10@gmail.com",
    contact: "8888825806",
    gstin: "27AANPD1129N1ZK",
  },
  {
    businessName: "Ishdev Enterprises",
    supplierId: 2,
    address: {
      label: "office",
      address1: "Hrithik 4-A Gorepeth",
      address2: "Opp GS College",
      state: "Maharashtra",
      city: "Nagpur",
      country: "India",
      pincode: 440010,
    },
    contactPerson: "Pallavi Mahindrakar",
    email: "msenterprisesngp10@gmail.com",
    contact: "8888825806",
    gstin: "27AANPD1129N1ZK",
  },
  // {
  //   businessName: "TPP Boiler Pvt Ltd",
  //   supplierId: 1,
  //   address: {
  //     label: "office",
  //     address1: "69‐70, 4Th Floor, Ashok Chembers,",
  //     address2: "Devaji Ratanji Marg (Bharuch Street), Masjid Bunder (E),",
  //     state: "Maharashtra",
  //     city: "Mumbai",
  //     country: "India",
  //     pincode: 400009,
  //   },
  //   contactPerson: " Himanshu Desai",
  //   email: "tppboilers@gmail.com",
  //   contact: "+91‐22‐23484046",
  //   gstin: "24AADCT1409Q1Z6",
  // },
];
