import MainCard from "../../../components/MainCard";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ClientCard = () => {
  const clientsData = useSelector(
    (state) => state?.dashBoard?.clientsData?.totalClientCount
  );
  // console.log(" clientsData: ", clientsData);
  useEffect(() => {}, [clientsData]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MainCard
            title={"Total Numbers Of Customers"}
            cardColor={"#000080"}
            currentData={clientsData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientCard;
