import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { Button, Grid, Card, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  deleteAssembleProduct,
  editAssembledProductTrigger,
  fetchCustomProduct,
  fetchCustomProductBySkuId,
} from "../../../../../actions/catalogAction";
// import { removeUser } from "../../../../actions/usersAction";

function AllCustomProductsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customProducts = useSelector(
    (state) => state.customProduct?.customProducts
  );
  // console.log("customProducts : ", customProducts);
  const [isShown, setIsShown] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [servi, setServi] = useState({});

  const handleEditProduct = (data) => {
    dispatch(editAssembledProductTrigger(data));
    dispatch(fetchCustomProductBySkuId(data?.skuid));
    navigate("/app/mgmt/catalog/configurators/create");
  };

  useEffect(() => {}, [customProducts]);
  useEffect(() => {
    try {
      dispatch(fetchCustomProduct());
    } catch (error) {
      // console.log(error);
    }
  }, []);
  const handleDelete = (assembledProductId) => {
    dispatch(deleteAssembleProduct(assembledProductId));
  };
  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={customProducts}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleEditProduct
        )}
        getRowId={(row) => row.assembledProductId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default AllCustomProductsList;
