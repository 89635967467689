import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewProductForm from "./NewProductForm";
import { useSelector } from "react-redux";

function NewProduct() {
  const productEdit = useSelector((state) => state.productEdit);
  const productDataToEdit = productEdit.productDataToEdit;
  const isProductEdit = productEdit.isProductEdit;
  // console.log("projectData to edit", productDataToEdit, isProductEdit);
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/app/mgmt/catalog/products/all");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Product</title>
      </Helmet>
      <Grid mt={15} mb={10}>
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader
              title={isProductEdit ? "Edit Product" : "Add New Product"}
            />
            <Divider />
            <CardContent>
              <NewProductForm
                onSubmitSuccess={handleSubmitSuccess}
                data={productDataToEdit}
                isProductEdit={isProductEdit}
              />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </DashboardLayout>
  );
}

export default NewProduct;
