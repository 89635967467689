import axios from "utils/axios";

import {
  UPDATE_REGISTER_ADDRESS,
  DELETE_REGISTER_ADDRESS,
  UPDATE_OTHER_ADDRESS,
  DELETE_OTHER_ADDRESS,
} from "config/services";

class RegisteredAddressService {
  removeClientRegisteredAddress = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.removeclient called", payload);

      axios
        .post(DELETE_REGISTER_ADDRESS, { payload })
        .then((response) => {
          // console.log("clientService.removeClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateClientRegisteredAddress = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);

      axios
        .post(UPDATE_REGISTER_ADDRESS, { payload })
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  removeClientOtherAddress = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.removeclient called", payload);

      axios
        .post(DELETE_OTHER_ADDRESS, { payload })
        .then((response) => {
          // console.log("clientService.removeClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateClientOtherAddress = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("clientService.updateClient called", payload);

      axios
        .post(UPDATE_OTHER_ADDRESS, { payload })
        .then((response) => {
          // console.log("clientService.updateClient () resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("usersService.removeUser() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const registeredAddressService = new RegisteredAddressService();

export default registeredAddressService;
