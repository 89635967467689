import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import ProductInformation from "./ProductInformation";
import AddProduct from "./AddProduct";
import FinalizeProduct from "./FinalizeProduct";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";
import CustomizedStepper from "../../../../../components/CustomizedStepper/CustomizedStepper";

const Configurator = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Product Information",
      data: (
        <ProductInformation
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Add Products",
      data: (
        <AddProduct
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Preview And Create",
      data: (
        <FinalizeProduct
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
  ];
  const isLastStep = activeStep === steps.length - 1;
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Configurator</title>
      </Helmet>
      <CardContent>
        <CustomizedStepper
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </CardContent>
    </DashboardLayout>
  );
};

export default Configurator;
