import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Autocomplete,
  TextField,
  Typography,
  Popover,
  List,
  ListItem,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DataGrid } from "@mui/x-data-grid";

import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { getProductsByCategory } from "utils/productUtils";
import {
  CalculateTotalAmount,
  createHoseAssembly,
  fetchHoseAssemblyByCriteria,
  fetchProduct,
} from "../../../actions/catalogAction";
import { BUSINESS_ID, CUSTOMER_CATEGORY } from "../../../config";
import { addToQoute } from "../../../actions/quoteActions";
import { headCells } from "./utils";
import AssembledProductList from "./AssembledProductList";
import { useNavigate } from "react-router-dom";

const QuoteCalculater = ({ type, onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  // console.log("Received Row Data in OtherComponent:", selectedRow);
  const products = selectedRow?.products || [];
  // console.log("products", products);

  const allQuantity = products
    .map((product) => product?.quantity)
    .filter((quantity) => quantity);

  // console.log("AllQuantity", allQuantity);

  // const qunatityOfAdaptors = selectedRow?.products?.quantity;
  // console.log("qunatityOfAdaptors", qunatityOfAdaptors);
  const quote = useSelector((state) => state?.quote);
  // console.log(" quote: ", quote);
  const quoteInProgress = quote?.quoteInProgress;
  // console.log(" quoteInProgress: ", quoteInProgress);
  const quoteId = quoteInProgress?.quoteId;
  // console.log(" quoteId: ", quoteId);
  const product = useSelector((state) => state.product?.products || []);
  const totalAmount = useSelector((state) => state.product?.totalAmount);
  // console.log("totalAmount: ", totalAmount);
  const CalculateAmount = totalAmount?.totalPrice?.salesPrice;
  // console.log("CalculateAmount", CalculateAmount);

  const [size, setSize] = useState(undefined);
  const [fittingOptions, setFittingOptions] = useState([]);
  const [adaptorsOptions, setAdaptorsOptions] = useState([]);
  const [sleevesOptions, setSleevesOptions] = useState([]);
  // console.log("sizes ", size);
  // console.log("fittingOptions ", fittingOptions);
  // console.log("adaptorsOptions ", adaptorsOptions);
  // console.log("sleevesOptions ", sleevesOptions);

  const filteredProducts = getProductsByCategory(product, "HOSE", size);
  // console.log("Filtered Products: ", filteredProducts);
  const fittingList = getProductsByCategory(product, "FITTING", size);
  // console.log("fittingList ", fittingList);
  const adaptorsList = getProductsByCategory(product, "ADAPTOR", size);
  // console.log("adaptorsList ", adaptorsList);
  const sleevesList = getProductsByCategory(product, "FIRE SLEEVE", size);
  // console.log("sleevesList ", sleevesList);

  const initialNames = products
    .map((product) => product?.product?.name)
    .filter((name) => name);
  // console.log("initialNames", initialNames);
  const filteredHoseNames = initialNames.filter((name) =>
    filteredProducts.find((product) => product.name === name)
  );
  const filteredFittingNames = initialNames.filter((name) =>
    fittingList.find((product) => product.name === name)
  );
  const filteredAdaptorNames = initialNames.filter((name) =>
    adaptorsList.find((product) => product.name === name)
  );
  const filteredSleevesNames = initialNames.filter((name) =>
    sleevesList.find((product) => product.name === name)
  );
  // console.log("Filtered Hose Names:", filteredHoseNames);
  // console.log("Filtered Fitting Names:", filteredFittingNames);
  // console.log("Filtered Adaptor Names:", filteredAdaptorNames);
  // console.log("Filtered Sleeves Names:", filteredSleevesNames);
  const [length, setLength] = useState("");
  const [adptQty, setAdptQty] = useState("");
  const [materialCode, setMaterialCode] = useState("");

  const [selectedProductDescription, setSelectedProductDescription] =
    useState("");
  // const [calculatedAmount, setCalculatedAmount] = useState("");
  const [isAllFieldsFilled, setAllFieldsFilled] = useState(false);
  const handleFieldChange = (values) => {
    const isMaterialCodeMatching = values.materialCode === selectedRow?.skuid;

    const isAllFieldsFilled =
      values.materialCode &&
      (isMaterialCodeMatching ? !selectedRow?.skuid : values.materialCode);

    setAllFieldsFilled(isAllFieldsFilled);
  };
  const FormikValuesObserver = () => {
    const formik = useFormikContext();

    useEffect(() => {
      handleFieldChange(formik.values);
    }, [formik.values]);

    return null;
  };

  // const calculateAmount = (values, setFieldValue) => {
  //   console.log("values.", values);
  //   let totalAmount = 0;

  //   if (values.hoseName) {
  //     totalAmount += values.hoseName.price?.salesPrice * (values.length || 1);
  //   }

  //   if (values.adaptors) {
  //     totalAmount += values.adaptors.price?.salesPrice * (values.adptQty || 1);
  //   }

  //   if (values.fittingA) {
  //     totalAmount += values.fittingA.price?.salesPrice;
  //   }

  //   if (values.fittingB) {
  //     totalAmount += values.fittingB.price?.salesPrice;
  //   }

  //   if (values.sleeves) {
  //     totalAmount += values.sleeves.price?.salesPrice * (values.length || 1);
  //   }

  //   console.log("Total Amount:", totalAmount);

  //   setFieldValue("amount", totalAmount);
  // };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleFetchData = () => {
    if (materialCode) {
      dispatch(fetchHoseAssemblyByCriteria(materialCode));
      setDialogOpen(true);
    }
  };

  const handleFetchAmount = async (values, setFieldValue) => {
    const amount = await dispatch(
      CalculateTotalAmount(
        values.hoseName,
        values.length,
        values.fittingA,
        values.fittingB,
        values.sleeves,
        values.adaptors,
        values.adptQty,
        values.customerCategory
      )
    );
    setFieldValue("amount", amount?.totalPayableAmount?.totalPrice?.salesPrice);
  };
  useEffect(() => {}, [products, totalAmount]);
  useEffect(() => {
    dispatch(fetchProduct());
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        quoteId: quoteId,
        businessId: BUSINESS_ID,
        length: allQuantity[0] ? allQuantity[0] : "",
        hoseName: filteredHoseNames[0] ? filteredHoseNames[0] : "",
        fittingA: filteredFittingNames[0] ? filteredFittingNames[0] : "",
        fittingB:
          filteredFittingNames[0] || filteredFittingNames[1]
            ? filteredFittingNames[0] || filteredFittingNames[1]
            : "",
        sleeves: filteredSleevesNames[0] ? filteredSleevesNames[0] : "",
        adaptors: filteredAdaptorNames[0] ? filteredAdaptorNames[0] : "",
        adptQty: allQuantity[3] ? allQuantity[3] : "",
        amount: selectedRow?.totalPrice?.salesPrice
          ? selectedRow?.totalPrice?.salesPrice
          : "",

        description: selectedRow?.description
          ? selectedRow?.description
          : selectedProductDescription,
        materialCode: selectedRow?.skuid ? selectedRow?.skuid : materialCode,
        hsnCode: selectedRow?.hsnCode ? selectedRow?.hsnCode : "",
        customerCategory: selectedRow?.customerCategory
          ? selectedRow?.customerCategory
          : "",

        qty: 1,
      }}
      validationSchema={Yup.object({
        materialCode: Yup.string().required("Material code is required"),
        // discount: Yup.number(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("values :====>>>>", values);
        try {
          if (type === "QOUTE") {
            await dispatch(addToQoute(values));
          } else {
            await dispatch(createHoseAssembly(values));
            onSubmitSuccess(navigate("/app/mgmt/catalog/configurators/all"));
          }
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormikValuesObserver />
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center", mb: 3 }}
              >
                <Grid item xs={12} sm={12} md={9}>
                  <TextField
                    name="materialCode"
                    id="materialCode"
                    label="Material Code (15 Char)"
                    type="string"
                    value={values.materialCode}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setMaterialCode(e.target.value);
                      handleChange(e);
                    }}
                    required
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(touched.materialCode && errors.materialCode)}
                    helperText={touched.materialCode && errors.materialCode}
                    // onClick={handleMaterialCodeClick}
                  />
                  <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    fullWidth
                    maxWidth="lg"
                    // sx={{ height: 400 }}
                  >
                    <DialogContent>
                      <Grid item xs={12} sm={12} md={12}>
                        <Card sx={{ boxShadow: 3, mb: 3 }}>
                          <CardHeader title={"Assembled Product List"} />
                          <Divider />
                          <CardContent>
                            <AssembledProductList
                              setSelectedRow={setSelectedRow}
                              setDialogOpen={setDialogOpen}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                </Grid>
                {/* {type && ( */}
                <Grid item xs={12} sm={8} md={1} sx={{ mb: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleFetchData}
                  >
                    Search
                  </Button>
                </Grid>
                {type !== "QOUTE" && (
                  <Grid item xs={12} sm={8} md={2} sx={{ mb: 1 }}>
                    <Autocomplete
                      fullWidth
                      value={values.customerCategory}
                      onChange={(event, newValue) => {
                        setFieldValue("customerCategory", newValue);
                      }}
                      disablePortal
                      id="customerCategory"
                      options={CUSTOMER_CATEGORY}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Category"
                            required
                            error={Boolean(
                              touched.customerCategory &&
                                errors.customerCategory
                            )}
                            helperText={
                              touched.customerCategory &&
                              errors.customerCategory
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>
                )}
                {/* )} */}
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={12} sm={2} md={3}>
                  {/* <Autocomplete
                    fullWidth
                    value={hoseProductValue}
                    onChange={(event, newValue) => {
                      console.log("newValue: ", newValue);
                      setFieldValue(
                        "hoseProduct",
                        newValue ? newValue.name : null
                      );
                      handleChangeProduct(newValue, setFieldValue);
                    }}
                    disablePortal
                    id="hoseProduct"
                    options={filteredProducts}
                    getOptionLabel={(option) => option.name}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Hose Pipe List"
                          required
                          error={Boolean(
                            touched.hoseProduct && errors.hoseProduct
                          )}
                          helperText={touched.hoseProduct && errors.hoseProduct}
                        />
                      </>
                    )}
                  /> */}
                  <Autocomplete
                    fullWidth
                    value={
                      filteredProducts.find(
                        (product) => product.name === values.hoseName
                      ) || null
                    }
                    id="hoseName"
                    disablePortal
                    options={filteredProducts}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "hoseName",
                        newValue ? newValue.name : null
                      );

                      // Filter other lists based on selected hoseName's size
                      const selectedSize = newValue ? newValue.size : undefined;
                      const filteredFittingList = fittingList.filter(
                        (fitting) => fitting.size === selectedSize
                      );
                      // console.log("filteredFittingList", filteredFittingList);
                      const filteredAdaptorsList = adaptorsList.filter(
                        (adaptor) => adaptor.size === selectedSize
                      );
                      const filteredSleevesList = sleevesList.filter(
                        (sleeve) => sleeve.size === selectedSize
                      );

                      setFittingOptions(filteredFittingList);
                      setAdaptorsOptions(filteredAdaptorsList);
                      setSleevesOptions(filteredSleevesList);
                      setFieldValue("fittingA", "");
                      setFieldValue("fittingB", "");
                      setFieldValue("sleeves", "");
                      setFieldValue("adaptors", "");

                      // Set other Autocomplete options similarly
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Hose Pipe"
                          required
                          error={Boolean(touched.hoseName && errors.hoseName)}
                          helperText={touched.hoseName && errors.hoseName}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1}>
                  <TextField
                    name="length"
                    id="length"
                    label="Length (Mtr)"
                    type="string"
                    value={values.length}
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setLength(e.target.value);
                      handleChange(e);
                    }}
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(touched.length && errors.length)}
                    helperText={touched.length && errors.length}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={4}>
                  {/* <Autocomplete
                    fullWidth
                    value={
                      filteredProducts.find(
                        (product) => product.name === values.fittingA
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      console.log("newValue: ", newValue);
                      setFieldValue("fittingA", newValue);
                      // handleFittingAChange(newValue, setFieldValue);
                    }}
                    disablePortal
                    id="fittingA"
                    options={fittingList}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Fitting A"
                          required
                          error={Boolean(touched.fittingA && errors.fittingA)}
                          helperText={touched.fittingA && errors.fittingA}
                        />
                      </>
                    )}
                  /> */}
                  <Autocomplete
                    fullWidth
                    value={
                      fittingList.find(
                        (product) => product.name === values.fittingA
                      ) || null
                    }
                    disablePortal
                    required
                    onChange={(event, newValue) => {
                      // console.log("new Vlaue", newValue);
                      setFieldValue(
                        "fittingA",
                        newValue ? newValue.name : null
                      );

                      // setFittingAValue(newValue);
                      // handleFittingAChange(newValue, setFieldValue);
                    }}
                    id="fittingA"
                    options={fittingOptions ? fittingOptions : fittingList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Fitting A"
                          required
                          error={Boolean(touched.fittingA && errors.fittingA)}
                          helperText={touched.fittingA && errors.fittingA}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={4}>
                  <Autocomplete
                    fullWidth
                    value={
                      fittingList.find(
                        (product) => product.name === values.fittingB
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      // console.log("newValue: ", newValue);
                      setFieldValue(
                        "fittingB",
                        newValue ? newValue.name : null
                      );

                      // setFittingBValue(newValue);
                      // handleFittingBChange(newValue, setFieldValue);
                    }}
                    disablePortal
                    id="fittingB"
                    options={fittingOptions ? fittingOptions : fittingList}
                    getOptionLabel={(option) => option.name}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Fitting B"
                          required
                          error={Boolean(touched.fittingB && errors.fittingB)}
                          helperText={touched.fittingB && errors.fittingB}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={3}>
                  <Autocomplete
                    fullWidth
                    value={
                      sleevesList.find(
                        (product) => product.name === values.sleeves
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      // console.log("newValue: ", newValue);
                      setFieldValue("sleeves", newValue ? newValue.name : null);

                      // setSleevesValue(newValue);
                      // handleSleevesChange(newValue, setFieldValue);
                    }}
                    disablePortal
                    id="sleeves"
                    options={sleevesOptions ? sleevesOptions : sleevesList}
                    getOptionLabel={(option) => option.name}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Sleeves"
                          // required
                          error={Boolean(touched.sleeves && errors.sleeves)}
                          helperText={touched.sleeves && errors.sleeves}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={3}>
                  <Autocomplete
                    fullWidth
                    value={
                      adaptorsList.find(
                        (product) => product.name === values.adaptors
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      // console.log("newValue: ", newValue);
                      setFieldValue(
                        "adaptors",
                        newValue ? newValue.name : null
                      );

                      // setAdaptorsValue(newValue);
                      // handleAdaptorsChange(newValue, setFieldValue);
                    }}
                    disablePortal
                    id="adaptors"
                    options={adaptorsOptions ? adaptorsOptions : adaptorsList}
                    getOptionLabel={(option) => option.name}
                    // required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Adaptors"
                          // required
                          error={Boolean(touched.adaptors && errors.adaptors)}
                          helperText={touched.adaptors && errors.adaptors}
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                  <TextField
                    name="adptQty"
                    id="adptQty"
                    label="Adpt Qty"
                    type="string"
                    value={values.adptQty}
                    variant="outlined"
                    fullWidth
                    // required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(touched.adptQty && errors.adptQty)}
                    helperText={touched.adptQty && errors.adptQty}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <TextField
                    name="amount"
                    id="amount"
                    label="Amount"
                    type="string"
                    value={values.amount}
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />

                  {/* <Typography>{calculatedAmount}</Typography> */}
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleFetchAmount(values, setFieldValue)}
                  >
                    Fetch Total Amount
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    name="description"
                    id="description"
                    label="Discription (Hose Pipe List)"
                    type="string"
                    value={values.description}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={(e) => {
                      // setSelectedProductDescription(e.target.value);
                      handleChange(e);
                    }}
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  {/* <Typography>{selectedProductDescription}</Typography> */}
                </Grid>
                {type === "QOUTE" && (
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <TextField
                      name="hsnCode"
                      id="hsnCode"
                      label="Hsn Code"
                      type="string"
                      value={values.hsnCode}
                      variant="outlined"
                      fullWidth
                      // required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 80 }}
                      error={Boolean(touched.hsnCode && errors.hsnCode)}
                      helperText={touched.hsnCode && errors.hsnCode}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={1.5} md={1.5}>
                  <TextField
                    name="qty"
                    id="qty"
                    label="Qty"
                    type="string"
                    value={values.qty}
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(touched.qty && errors.qty)}
                    helperText={touched.qty && errors.qty}
                  />
                </Grid>
                {type === "QOUTE" ? (
                  <Grid item xs={12} sm={2} md={2}>
                    <Button variant="contained" color="primary" type="submit">
                      Add To quotation
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={2.5} md={2.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isAllFieldsFilled}
                    >
                      Create Hose Assembly
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default QuoteCalculater;
