import React from "react";
import { IconButton, SvgIcon, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfirmDialog } from "components";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleEditProduct
) => [
  {
    field: "product.brandName ",
    headerName: "Brand Name",
    width: 120,
    valueGetter: (params) => params?.row?.product?.brandName,
  },
  {
    field: "brandName",
    headerName: "Name",
    valueGetter: (params) => params?.row?.product?.name,
    width: 120,
    editable: true,
  },
  {
    field: "productId",
    headerName: "Product Id",
    valueGetter: (params) => params?.row?.product?.productId,
    width: 120,
    editable: true,
  },
  {
    field: "skuid",
    headerName: "SKU ID",
    valueGetter: (params) => params?.row?.product?.skuid,
    width: 100,
    editable: true,
  },
  {
    field: "size",
    headerName: "Size",
    valueGetter: (params) => params?.row?.product?.size,
    width: 100,
    editable: true,
  },
  {
    field: "uom",
    headerName: "UOM",
    valueGetter: (params) => params?.row?.product?.uom,
    width: 80,
    editable: true,
  },
  { field: "quantity", headerName: "quantity", width: 80 },
  {
    field: "price",
    headerName: "Price",
    valueGetter: (params) => params?.row?.product?.price.mrp,
    sortable: false,
    width: 80,
  },
  {
    field: "salesPrice",
    headerName: "Sales Price",
    valueGetter: (params) => params?.row?.product?.price.salesPrice,
    sortable: false,
    width: 100,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}

          <IconButton
            onClick={() => {
              handleEditProduct(params.row);
            }}
          >
            {/* <EditIcon /> */}
          </IconButton>

          {/* edit button */}
          <m>
            {showDelete && params.row.product.productId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this Product ,action can't be undone`}
                  isOpen={
                    modal === params.row.product.productId &&
                    params.row.product.productId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.product.productId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <IconButton onClick={() => setModal(params.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </m>
          {/* <Button onClick={()=>viewProject(params.row)}>
            <VisibilityIcon />
          </Button> */}
        </div>
      );
    },
  },
];
