import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { Button, Grid, Card, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { deleteProduct } from "actions/catalogAction";
import {
  editProductTrigger,
  fetchProduct,
} from "../../../../../actions/catalogAction";

function AllProductsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const product = useSelector((state) => state.product);
  // console.log(" products : ", product.products);
  const [isShown, setIsShown] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [servi, setServi] = useState({});

  const handleEditProduct = (data) => {
    dispatch(editProductTrigger(data));
    navigate("/app/mgmt/catalog/products/create");
  };

  useEffect(() => {}, [product.products]);
  useEffect(() => {
    try {
      dispatch(fetchProduct());
    } catch (error) {
      // console.log(error);
    }
  }, []);
  const handleDelete = (productId) => {
    dispatch(deleteProduct(productId));
  };
  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={product.products}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleEditProduct
        )}
        getRowId={(row) => row.productId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default AllProductsList;
