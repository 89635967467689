import React from "react";
import { IconButton, SvgIcon, Button, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";

import { ConfirmDialog } from "components";
import { useNavigate } from "react-router-dom";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleUpdate,
  showBlock,
  handleBlock,
  viewClient,
  modalBlock,
  setModalBlock
) => [
  { field: "clientId", headerName: "Client Id", width: 100 },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    editable: false,
  },
  // {
  //   field: "category",
  //   headerName: "Category",
  //   width: 100,
  //   editable: true,
  // },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
  },
  {
    field: "contact",
    headerName: "Mobile",
    width: 150,
    editable: false,
  },
  {
    field: "contactPerson",
    headerName: "Contact Person",
    width: 200,
    editable: false,
  },
  {
    field: "gstin",
    headerName: "GSTIN",
    width: 200,
    editable: false,
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 100,
    editable: false,
    renderCell: (params) => (
      <span>{params.value ? 'Yes' : 'No'}</span>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };
      // const navigate = useNavigate();
      return (
        <div className="cellAction">
          {/* edit button */}
          <Tooltip title={"Edit Customer"}>
            <IconButton onClick={() => handleUpdate(params.row)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* edit button */}
          <>
            {showDelete && params.row.clientId && (
              <>
                <ConfirmDialog
                  title="Delete Customer"
                  description={`Do you want to permanently delete Customer:${params.row.name}(${params.row.clientId})? This action can't be undone`}
                  isOpen={modal === params.row.clientId && params.row.clientId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.row.clientId);
                    setModal(false);
                    handleDelete(params.row.clientId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <Tooltip title={"Delete Customer"}>
                  <IconButton onClick={() => setModal(params.id)} color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
          <>
            {showBlock && params.row.clientId && (
              <>
                <ConfirmDialog
                  title="Block Customer"
                  description={`Do you want to permanently block Customer:${params.row.name}(${params.row.clientId})? This action can't be undone`}
                  isOpen={
                    modalBlock === params.row.clientId && params.row.clientId
                  }
                  confirmAction={() => {
                    // console.log("Block confirm: ", params.row.clientId);
                    setModalBlock(false);
                    handleBlock(params.row.clientId);
                  }}
                  cancelAction={() => setModalBlock(false)}
                />
                <Tooltip title={"Block Customer"}>
                  <IconButton onClick={() => setModalBlock(params.id)} color="primary">
                    <BlockSharpIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
          {/* <IconButton onClick={() => viewClient(params.row)}>
            <VisibilityIcon />
          </IconButton> */}
        </div>
      );
    },
  },
];
