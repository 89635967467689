import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewProjectDetail from "./ViewProjectDetail";

function ProjectDetails() {
  const navigate = useNavigate();
  // const handleSubmitSuccess = () => {
  //   // navigate("/app/mgmt/project/all");
  // };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Project Details</title>
      </Helmet>
      <Grid container sx={{ mt: 5 }}>
        <ViewProjectDetail />
      </Grid>
    </DashboardLayout>
  );
}

export default ProjectDetails;
