import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { STATE } from "config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  updateRegisterAddress,
  updateOtherAddress,
} from "../../../../../actions/registeredAddressAction";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

const RegisterAddressForm = ({
  onSubmitSuccess,
  registeredAddressdata,
  isClientEdit,
  open,
  onClick,
  onClose,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const clientEdit = useSelector((state) => state?.clientEdit);
  const clientDataToEdit = clientEdit.clientDataToEdit;
  const clientId = clientDataToEdit?.clientId ? clientDataToEdit?.clientId : [];

  // console.log("isClientEdit ==>>", isClientEdit);

  // console.log("registeredAddressdata ==>>", registeredAddressdata);
  // console.log("SignInEmail: auth", auth, " user: ",name);
  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        clientId: clientId,
        label: registeredAddressdata?.registeredAddress?.label
          ? registeredAddressdata?.registeredAddress?.label
          : "",
        address1: registeredAddressdata?.registeredAddress?.address1
          ? registeredAddressdata?.registeredAddress?.address1
          : "",
        address2: registeredAddressdata?.registeredAddress?.address2
          ? registeredAddressdata?.registeredAddress?.address2
          : "",
        city: registeredAddressdata?.registeredAddress?.city
          ? registeredAddressdata?.registeredAddress?.city
          : "",
        state: registeredAddressdata?.registeredAddress?.state
          ? registeredAddressdata?.registeredAddress?.state
          : "",
        country: "india",
        pincode: registeredAddressdata?.registeredAddress?.pincode
          ? registeredAddressdata?.registeredAddress?.pincode
          : "",
      }}
      validationSchema={Yup.object().shape({
        label: Yup.string().required("Name is required").max(80),
        address1: Yup.string().required("Name is required").max(200),
        address2: Yup.string().required("Name is required").max(200),
        city: Yup.string().required("Name is required").max(80),
        state: Yup.string().required("Name is required").max(80),
        country: Yup.string().required("Name is required").max(80),
        country: Yup.string().required("Name is required").max(80),
        pincode: Yup.string().required("User Type is required").max(100),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (registeredAddressdata) {
            await dispatch(updateRegisterAddress(values));
          } else {
            await dispatch(updateRegisterAddress(values));
          }
          onSubmitSuccess(navigate("/app/mgmt/customer/my"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Dialog
            fullScreen={fullScreen}
            sx={{
              top: 100,
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle id="responsive-dialog-title">
                {"Update Address"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      id="label"
                      name="label"
                      label="Address lable"
                      required
                      value={values.label}
                      onChange={handleChange}
                      error={touched.label && Boolean(errors.label)}
                      helperText={touched.label && errors.label}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      id="address1"
                      name="address1"
                      label="Address1"
                      multiline
                      rows={3}
                      required
                      value={values.address1}
                      onChange={handleChange}
                      error={touched.address1 && Boolean(errors.address1)}
                      helperText={touched.address1 && errors.address1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      id="address2"
                      name="address2"
                      label="Address2"
                      multiline
                      rows={3}
                      required
                      value={values.address2}
                      onChange={handleChange}
                      error={touched.address2 && Boolean(errors.address2)}
                      helperText={touched.address2 && errors.address2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      fullWidth
                      id="city"
                      name="city"
                      label="City"
                      required
                      value={values.city}
                      onChange={handleChange}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                      fullWidth
                      value={values.state}
                      onChange={(event, newValue) => {
                        setFieldValue("state", newValue);
                      }}
                      disablePortal
                      id="serviceType"
                      options={STATE}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="State"
                            required
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      fullWidth
                      id="country"
                      name="country"
                      label="Country"
                      required
                      value={values.country}
                      onChange={handleChange}
                      error={touched.country && Boolean(errors.country)}
                      helperText={touched.country && errors.country}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      fullWidth
                      id="pincode"
                      name="pincode"
                      label="Pincode"
                      required
                      value={values.pincode}
                      onChange={handleChange}
                      error={touched.pincode && Boolean(errors.pincode)}
                      helperText={touched.pincode && errors.pincode}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  autoFocus
                  onClick={onClose}
                >
                  close
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  // disabled={!isValid}
                  onClick={onClick}
                >
                  {isClientEdit ? "Update Address" : "Create Address"}
                </Button>
                {/* <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={onClick}
                >
                  <Alert
                    onClose={onClick}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    This is a success message!
                  </Alert>
                </Snackbar> */}
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

RegisterAddressForm.prototype = {
  onClick: PropTypes.func,
  open: PropTypes.func,
  onClose: PropTypes.func,
  isClientEdit: PropTypes.bool,
  data: PropTypes.object,
};

export default RegisterAddressForm;
