/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  QUOTE_GET_BY_ID,
  QUOTE_GET_BY_ID_SUCCESS,
  QUOTE_GET_BY_ID_FAILURE,
  QUOTE_CREATE,
  QUOTE_CREATE_SUCCESS,
  QUOTE_CREATE_FAILURE,
  QUOTE_FETCH,
  QUOTE_FETCH_SUCCESS,
  QUOTE_FETCH_FAILURE,
  QUOTE_UPDATE_ADD_ITEM_SUCCESS,
  QUOTE_EDIT_TRIGGER,
  QUOTET_CREATE_TRIGGER,
  GET_QUOTE_BY_ID_TO_UPDATE,
  GET_QUOTE_BY_ID_TO_UPDATE_SUCCESS,
  FETCH_QUOTE_BY_CRITERIA,
  FETCH_QUOTE_BY_CRITERIA_SUCCESS,
  FETCH_QUOTE_BY_CRITERIA_FAILURE,
  QUOTE_FETCH_TNC_SUCCESS,
  REMOVE_QUOTE_ITEM_SUCCESS,
  MY_QUOTE_FETCH,
  MY_QUOTE_FETCH_SUCCESS,
  MY_QUOTE_FETCH_FAILURE,
} from "actions/quoteActions";

const initialState = {
  quoteInProgress: undefined,
  quoteList: [],
  page: 1,
  count: 0,
  totalRecords: 0,
  quoteDataToEdit: undefined,
  isQuoteEdit: false,
  requestList: [],
  criteria: {
    supplierId: "",
    quoteId: "",
    quoteDate: "",
    status: "",
  },
  myQuoteList: [],
};

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_UPDATE_ADD_ITEM_SUCCESS:
      {
        const { quote } = action.payload;
        // console.log(
        //   "quoteReducer: QUOTE_CREATE_SUCCESS ",
        //   quote,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quoteInProgress = quote;
        });
      }
      break;
    case REMOVE_QUOTE_ITEM_SUCCESS:
      {
        const { quoteDetails } = action.payload;
        // console.log(
        //   "quoteReducer: REMOVE_QUOTE_ITEM_SUCCESS ",
        //   quoteDetails,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quoteInProgress = quoteDetails?.quoteDetails;
        });
      }
      break;
    case QUOTE_CREATE_SUCCESS:
      {
        const { quote } = action.payload;
        // console.log(
        //   "quoteReducer: QUOTE_CREATE_SUCCESS ",
        //   quote,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quoteInProgress = quote;
          draft.isQuoteEdit = true;
        });
      }
      break;
    // case REMOVE_QUOTE_ITEM_SUCCESS :
    //   {
    //     const { quote } = action.payload;
    //     console.log(
    //       "quoteReducer: REMOVE_QUOTE_ITEM_SUCCESS ",
    //       quote,
    //       action.payload
    //     );

    //     return produce(state, (draft) => {
    //       draft.quoteInProgress = quote;
    //     });
    //   }
    //   break;
    case QUOTE_GET_BY_ID:
      {
        return produce(state, (draft) => {
          draft.quote = [];
        });
      }
      break;
    case QUOTE_GET_BY_ID_SUCCESS:
      {
        const { quote } = action.payload;
        // console.log(
        //   "Quote reducer: QUOTE_GET_BY_ID_SUCCESS ",
        //   quote,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quote = quote;
        });
      }
      break;
    case QUOTE_FETCH_TNC_SUCCESS:
    case GET_QUOTE_BY_ID_TO_UPDATE_SUCCESS:
      {
        const { quote } = action.payload;
        // console.log(
        //   "Quote reducer: GET_QUOTE_BY_ID_TO_UPDATE_SUCCESS ",
        //   quote,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.quoteInProgress = quote;
          draft.isQuoteEdit = true;
          draft.quoteDataToEdit = quote;
        });
      }
      break;

    case QUOTE_GET_BY_ID_FAILURE:
      {
        return produce(state, (draft) => {
          draft.quote = [];
        });
      }
      break;

    // case QUOTE_FETCH:
    //   {
    //     return produce(state, (draft) => {
    //       draft.quoteList = [];
    //       draft.count = 0;
    //     });
    //   }
    //   break;

    // case QUOTE_FETCH_SUCCESS:
    //   const { quoteList } = action.payload;
    //   console.log(
    //     "Quote reducer: QUOTE_FETCH_SUCCESS ",
    //     quoteList,
    //     action.payload
    //   );

    //   return produce(state, (draft) => {
    //     draft.quoteList = quoteList.result;
    //     draft.page = quoteList.page;
    //   });
    //   break;

    // case QUOTE_FETCH_FAILURE:
    //   {
    //     return produce(state, (draft) => {
    //       draft.quoteList = [];
    //       draft.count = 0;
    //     });
    //   }
    //   break;
    case QUOTET_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.quoteInProgress = undefined;
          draft.isQuoteEdit = false;
        });
      }
      break;

    case FETCH_QUOTE_BY_CRITERIA:
      {
        const { supplierId, quoteId, quoteDate, status, page } = action.payload;
        return produce(state, (draft) => {
          draft.requestList = [];
          draft.criteria = {
            supplierId: supplierId,
            quoteId: quoteId,
            quoteDate: quoteDate,
            status: status,
          };
          draft.page = page;
          draft.count = 0;
        });
      }
      break;
    case FETCH_QUOTE_BY_CRITERIA_SUCCESS:
      {
        const { requestList } = action.payload;
        // console.log(
        //   "Quote reducer: FETCH_QUOTE_BY_CRITERIA_SUCCESS ",
        //   requestList,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.requestList = requestList;
          draft.page = requestList.page;
          draft.totalRecords = requestList.totalRecords;
          draft.count = requestList?.length;
        });
      }
      break;
    case FETCH_QUOTE_BY_CRITERIA_FAILURE:
      {
        return produce(state, (draft) => {
          draft.requestList = [];
          draft.count = 0;
        });
      }
      break;

    case MY_QUOTE_FETCH:
      {
        return produce(state, (draft) => {
          draft.quoteList = [];
          draft.count = 0;
        });
      }
      break;

    case MY_QUOTE_FETCH_SUCCESS:
      const { myQuoteList } = action.payload;
      // console.log(
      //   "Quote reducer: QUOTE_FETCH_SUCCESS ",
      //   myQuoteList,
      //   action.payload
      // );

      return produce(state, (draft) => {
        draft.myQuoteList = myQuoteList;
      });
      break;

    case MY_QUOTE_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.myQuoteList = [];
          draft.count = 0;
        });
      }
      break;
    default:
      {
        return state;
      }
      break;
  }
};

export default quoteReducer;
