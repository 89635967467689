import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";

import { signUpMobileVerifyOTP, signUpMobile } from "actions/authActions";
import { OTP_COUNTER } from "config";

function SignUpMobileOTPVerifyForm({ className, onSubmitSuccess, name, mobile, ...rest }) {
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(OTP_COUNTER);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const resendOTP = () => {
    // console.log("Sending Request for sending OTP", mobile);
    try {
      dispatch(signUpMobile("", mobile));
      setCounter(OTP_COUNTER);
    } catch (error) {
      // console.log("resendOTP: error", error);
    }
    // dispatch()
  };

  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      validationSchema={Yup.object().shape({
        otp: Yup.string()
          .matches(
            /^[0-9]{4}/,
            "Please enter valid 4 digit OTP"
          )
          .min(4)
          .max(4)
          .required("Please Enter valid OTP"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(signUpMobileVerifyOTP(mobile, name, values.otp));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={4} maxWidth="sm">
            <Grid item xs={12}>
              <TextField
                name="otp"
                id="otp"
                label="OTP"
                value={values.otp}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 4 }}
                error={Boolean(touched.otp && errors.otp)}
                helperText={touched.otp && errors.otp}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: -2 }}
            >
              {counter > 0 && (
                <Box mt={3}>
                  <Typography
                    fontWeight={500}
                    align="center"
                    color="textSecondary"
                  >
                    You will receive OTP in{" "}
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      {" "}
                      {counter}
                    </span>{" "}
                    secs
                  </Typography>
                </Box>
              )}

              {counter <= 0 && (
                <Box mt={3}>
                  <Typography
                    fontWeight={500}
                    align="left"
                    color="textSecondary"
                  >
                    Not recevied OTP? {"  "}
                    <Link
                      component={"button"}
                      color={"primary"}
                      onClick={resendOTP}
                      underline={"none"}
                    >
                      Resend OTP
                    </Link>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >

            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ borderRadius: 30 }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpMobileOTPVerifyForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  mobile: PropTypes.string,
};

SignUpMobileOTPVerifyForm.default = {
  onSubmitSuccess: () => { },
};

export default SignUpMobileOTPVerifyForm;
