import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createProjects,
  updateProjects,
} from "../../../../actions/projectAction";

// const validationSchema = Yup.object().shape({
//   projectCode: Yup.string().required("Project code is required").max(10),
//   projectName: Yup.string().required("Project name is required").max(100),
//   description: Yup.string().required("Description is required").max(500),
//   regions: Yup.string().required("Region is required").max(100),
//   client: Yup.string().required("Client is required").max(100),
//   projectManager: Yup.string().required("Project Manager is required").max(100),
//   startDate: Yup.date(),
//   commissioningDate: Yup.date(),
//   remarks: Yup.string().required("Remarks is required").max(500),
// });

// const initialValues = {
//   projectCode: "",
//   projectName: "",
//   description: "",
//   regions: "",
//   client: "",
//   projectManager: "",
//   startDate: null,
//   commissioningDate: null,
//   remarks: "",
// };
// const handleSubmit = async (values) => {
//   try {
//     const response = await axios.post("api/projects/create", values);
//     console.log(response.data); // do something with response
//   } catch (error) {
//     console.error(error);
//   }
// };

// const handleSubmit = async (
//   values,
//   { setErrors, setStatus, setSubmitting }
// ) => {
//   console.log("Submitting the request", values);
//   // try {
//   //   await dispatch((values, date));
//   //   setStatus({ success: true });
//   //   setSubmitting(false);
//   // enqueueSnackbar("Upgraded", {
//   //   variant: "success",
//   // });
//   // onSubmitSuccess();
//   // await onClick();
//   // refreshPage()
//   // navigate("/app/management/search/businesses");
//   // } catch (err) {
//   // enqueueSnackbar("Something went wrong ", {
//   //   variant: "failure",
//   // });
//   //   setErrors({ submit: err.message });
//   //   setStatus({ success: false });
//   //   setSubmitting(false);
//   // }
//   try {
//     await dispatch(updateBusinessProfile(supplierId, values));
//     onSubmitSuccess();
//   } catch (error) {
//     // console.log("Form: error", error);

//     const message = error.message || "Something went wrong";
//     setStatus({ success: false });
//     setErrors({ submit: message });
//     setSubmitting(false);
//   }
// };

const NewProjectForm = ({ onSubmitSuccess, data, isProjectEdit }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userId = auth.user.userId;

  // console.log("SignInEmail: auth", auth, " user: ", userId);
  // console.log("data=>>>>> ", data);
  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        projectCode: data?.projectCode ? data?.projectCode : "",
        projectName: data?.projectName ? data?.projectName : "",
        description: data?.description ? data?.description : "",
        regions: data?.regions ? data?.regions : "",
        client: data?.client ? data?.client : "",
        projectManager: data?.projectManager ? data?.projectManager : "",
        startDate: data?.startDate ? data?.startDate : null,
        commissioningDate: data?.commissioningDate
          ? data?.commissioningDate
          : null,
        remarks: data?.remarks ? data?.remarks : "",
      }}
      validationSchema={Yup.object().shape({
        projectCode: Yup.string().required("Project code is required").max(10),
        projectName: Yup.string().required("Project name is required").max(100),
        description: Yup.string().required("Description is required").max(500),
        regions: Yup.string().required("Region is required").max(100),
        client: Yup.string().required("Client is required").max(100),
        projectManager: Yup.string()
          .required("Project Manager is required")
          .max(100),
        startDate: Yup.date(),
        commissioningDate: Yup.date(),
        remarks: Yup.string().required("Remarks is required").max(500),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (data) {
            await dispatch(updateProjects(data.projectId, values));
          } else {
            await dispatch(createProjects(userId, values));
          }

          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {/* <Box mt={2} mb={2}>
                    <Typography variant="h6">Creat New Project</Typography>
                  </Box> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="projectCode"
                  name="projectCode"
                  label="Project Code"
                  value={values.projectCode}
                  onChange={handleChange}
                  error={touched.projectCode && Boolean(errors.projectCode)}
                  helperText={touched.projectCode && errors.projectCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="projectName"
                  name="projectName"
                  label="Project Name"
                  value={values.projectName}
                  onChange={handleChange}
                  error={touched.projectName && Boolean(errors.projectName)}
                  helperText={touched.projectName && errors.projectName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="regions"
                  name="regions"
                  label="Regions"
                  value={values.regions}
                  onChange={handleChange}
                  error={touched.regions && Boolean(errors.regions)}
                  helperText={touched.regions && errors.regions}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="client"
                  name="client"
                  label="Client"
                  value={values.client}
                  onChange={handleChange}
                  error={touched.client && Boolean(errors.client)}
                  helperText={touched.client && errors.client}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="projectManager"
                  name="projectManager"
                  label="Project Manager"
                  value={values.projectManager}
                  onChange={handleChange}
                  error={
                    touched.projectManager && Boolean(errors.projectManager)
                  }
                  helperText={touched.projectManager && errors.projectManager}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Start Date"
                    name="startDate"
                    value={values.startDate}
                    onChange={(date) => setFieldValue("startDate", date)}
                    renderInput={(params) => <TextField {...params} />}
                    onBlur={handleBlur}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    inputVariant="outlined"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Commission Date"
                    name="commissioningDate"
                    value={values.commissioningDate}
                    onChange={(date) =>
                      setFieldValue("commissioningDate", date)
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onBlur={handleBlur}
                    error={
                      touched.commissioningDate &&
                      Boolean(errors.commissioningDate)
                    }
                    helperText={
                      touched.commissioningDate && errors.commissioningDate
                    }
                    inputVariant="outlined"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Remarks"
                  name="remarks"
                  multiline
                  rows={3}
                  value={values.remarks}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.remarks && Boolean(errors.remarks)}
                  helperText={touched.remarks && errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // disabled={!isValid}
                >
                  {isProjectEdit ? "Update Project" : "Create Project"}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default NewProjectForm;
