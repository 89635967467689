import * as React from "react";
import Box from "@mui/material/Box";
import AssembleProduct from "./AssembleProductForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProductInformation = ({ handleNext, handleBack, activeStep }) => {
  const isAssembledProductEdit = useSelector((state) => state.configurator?.isAssembledProductEdit);
  const dataToEdit = useSelector((state) => state.configurator?.dataToEdit);
  // console.log("assembledProductInProgress",isAssembledProductEdit, dataToEdit);

  const navigate = useNavigate();
  // const handleSubmitSuccess = () => {
  //   // handleNext();
  // };
  return (
    <Box sx={{ minWidth: 500 }} title="Product Information">
      <AssembleProduct
        // onSubmitSuccess={handleSubmitSuccess}
        data={dataToEdit}
        isAssembledProductEdit={isAssembledProductEdit}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
      />
    </Box>
  );
};
export default ProductInformation;
