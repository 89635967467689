import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Container,
} from "@mui/material";
import NewUserForm from "./NewUserForm";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

function NewUser({onSubmitSuccess}) {
  const userEdit = useSelector((state) => state.userEdit);
  // console.log("userEdit:", userEdit);
  const userDataToEdit = userEdit.userDataToEdit;
  // console.log("userDataToEdit:", userDataToEdit);
  const isUserEdit = userEdit.isUserEdit;
  // console.log("isUserEdit:", isUserEdit);
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New User</title>
      </Helmet>
      <Grid mt={15} mb={10}>
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader title={isUserEdit ? `Update User (${userDataToEdit.userId}) `: " New User"} />
            <Divider />
            <CardContent>
              <NewUserForm
                onSubmitSuccess={onSubmitSuccess}
                data={userDataToEdit}
                isUserEdit={isUserEdit}
              />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </DashboardLayout>
  );
}

export default NewUser;
