import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";
import AddIcon from "@mui/icons-material/Add";

import {
  Grid,
  CardHeader,
  Card,
  Divider,
  CardContent,
  Button,
} from "@mui/material";
import AllUsersList from "./AllUsersList";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  createUserTrigger
  } from "../../../../actions/usersAction";


function AllUsers() {
  const [servi, setServi] = useState({});
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (serviceRow) => {
    setServi(serviceRow);
    setIsShown((current) => !current);
    dispatch(createUserTrigger());
    navigate("/app/mgmt/user/create");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - All Users</title>
      </Helmet>
      <Grid
        mt={15}
        mb={10}
        sx={{ display: "flex", justifyContent: "flex-start"}}
      >
        <Card sx={{ boxShadow: 3, width: "100%" }}>
          <CardContent>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <CardHeader title={"All Users List"} />
              </Grid>

              <Grid item xs={6} sx={{ textAlign: "end" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                >
                  <AddIcon />
                  Create User
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} />
            <AllUsersList />
          </CardContent>
        </Card>
      </Grid>
    </DashboardLayout>
  );
}

export default AllUsers;
