
class ClientProfile {
    constructor(clientInfo) {
      if (clientInfo) {
        this.clientId = clientInfo.clientId;
        this.businessId = clientInfo.businessId;
        this.name = clientInfo.name;
        this.email = clientInfo.email;
        this.contact = clientInfo.contact;
        this.contactPerson = clientInfo.contactPerson;
        this.registeredAddress = clientInfo.registeredAddress;
        this.gstin = clientInfo.gstin;
        this.pan = clientInfo.pan;
        this.category = clientInfo.category;
        this.notes = clientInfo.notes;
        this.registeredAddress = clientInfo.registeredAddress;
        this.addresses = clientInfo.addresses;
      }
    }
  };

export default { ClientProfile };
export { ClientProfile };
