class AddProject {
  constructor(projectId, values) {
    if ((projectId, values)) {
      // this.userId = userId;
      this.projectId = projectId;
      this.projectCode = values.projectCode;
      this.projectName = values.projectName;
      this.description = values.description;
      this.regions = values.regions;
      this.client = values.client;
      this.projectManager = values.projectManager;
      this.startDate = values.startDate;
      this.commissioningDate = values.commissioningDate;
      this.remarks = values.remarks;
    }
  }
}

class AssignMember {
  constructor(values) {
    if (values) {
      this.userId = values.userId;
      this.userName = values.userName;
      this.projectId = values.projectId;
      this.role = values.role;
      this.remarks = values.remarks;
    }
  }
}
class AllocateDevice {
  constructor(values) {
    if (values) {
      this.deviceId = values.deviceId;
      this.projectId = values.projectId;
      this.remarks = values.remarks;
    }
  }
}

export default { AddProject };
export { AddProject, AssignMember, AllocateDevice };
