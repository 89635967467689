import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Page from "./components/Page";
import Auth from "components/Auth";

import "react-lazy-load-image-component/src/effects/blur.css";
import "react-image-lightbox/style.css";
import "aos/dist/aos.css";
import ReactGA from 'react-ga';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
// console.log("GA_TRACKING_ID",GA_TRACKING_ID);

ReactGA.initialize(GA_TRACKING_ID);

function App() {

  // TODO_SP Please check what param to go for pageview
  useEffect(() => {
    ReactGA.pageview(window.location.pathname+window.location.search);
  }, []);

  window.dataLayer.push({
    event: 'pageview'
  });

  return (
      <Page>
        <BrowserRouter>
          <Auth>
            <Routes />
          </Auth>
        </BrowserRouter>
      </Page>
  );
}

export default App;
