import React from "react";
import {
  Grid,
  Box,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOMER_CATEGORY, STATE } from "config";
import { createQuoteCustomerTNC } from "../../../actions/quoteActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TermsConditions = ({ handleNext, handleBack, activeStep }) => {
  const dispatch = useDispatch();

  const quotes = useSelector((state) => state?.quote);
  // console.log(" quote: ", quotes);

  const quoteInProgress = quotes?.quoteInProgress;
  // console.log(" quoteInProgress: ", quoteInProgress);

  const quoteId = quoteInProgress?.quoteId;
  // console.log(" quoteId: ", quoteId);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        quoteId: quoteId,
        termsAndConditions: quoteInProgress?.tnc ? quoteInProgress?.tnc : "",
      }}
      validationSchema={Yup.object({
        termsAndConditions: Yup.string().min(1).max(400),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, isClientProfile }
      ) => {
        try {
          await dispatch(createQuoteCustomerTNC(values));
          handleNext();
          //   if (data) {
          //     await dispatch(updateClient(values));
          //   } else {
          //     await dispatch(createClient(values));
          //   }
          //   onSubmitSuccess(navigate("/app/mgmt/customer/my"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
      // onSubmit={(values) => {
      //   console.log("Form: submit", values)
      // }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="termsAndConditions"
                    id="termsAndConditions"
                    label="Terms & Conditions"
                    type="string"
                    value={values.termsAndConditions}
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    rows={6}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(
                      touched.termsAndConditions && errors.termsAndConditions
                    )}
                    helperText={
                      touched.termsAndConditions && errors.termsAndConditions
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                  Save Tnc and Preview
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBackIcon />}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Grid item xs="auto">
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save Tnc and Preview
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default TermsConditions;
