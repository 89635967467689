import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AllocateDeviceForm from "./AllocateDeviceForm";

function AllocateDevice() {
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/app/mgmt/project/project-details");
  };
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Project Member</title>
      </Helmet>
      <Grid>
        <AllocateDeviceForm onSubmitSuccess={handleSubmitSuccess} />
      </Grid>
    </DashboardLayout>
  );
}

export default AllocateDevice;
