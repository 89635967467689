import React from "react";
import { IconButton, SvgIcon, Button, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";

import { ConfirmDialog } from "components";
import Lock from "@mui/icons-material/Lock";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleUpdate,
  showBlock,
  modalBlock,
  setModalBlock,
  handleBlock,
  handleChangePassword,
  viewProject
) => [
  { field: "userId", headerName: "ID", width: 80 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 150,
    
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    
  },
  {
    field: "userType",
    headerName: "User Type",
    sortable: false,
    width: 100,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}

          <Tooltip title={"Edit user"}>
            <IconButton onClick={() => handleUpdate(params.row)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>

          {/* edit button */}
          <m>
            {showDelete && params.row.userId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this User ,action can't be undone`}
                  isOpen={modal === params.row.userId && params.row.userId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.userId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <IconButton onClick={() => setModal(params.row.userId)} color="primary">
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </m>
          <m>
            {showBlock && params.row.userId && (
              <>
                <ConfirmDialog
                  title="Block Customer"
                  description={`Do you want to permanently block User: ${params.id}? This action can't be undone`}
                  isOpen={modalBlock === params.row.userId && params.row.userId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleBlock(params.row.userId);
                  }}
                  cancelAction={() => setModalBlock(false)}
                />
                <Tooltip title={"Block Customer"}>
                  <IconButton onClick={() => setModalBlock(params.row.userId)} color="primary">
                    <BlockSharpIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
          <m>
            {showBlock && params.row.userId && (
              <>
                <Tooltip title={"Change Password"}>
                  <IconButton onClick={() => handleChangePassword(params.row)} color="primary">
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </m>
        </div>
      );
    },
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 80,
    editable: true,
    renderCell: (params) => <span>{params.value ? "Yes" : "No"}</span>,
  },
  {
    field: "isDelete",
    headerName: "Deleted",
    sortable: false,
    width: 80,
    renderCell: (params) => <span>{params.value ? "Yes" : "No"}</span>,
  },
];
