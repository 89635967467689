//Auth Services
export const SIGNIN_EMAIL_PASS_URL = "/api/auth/signin";
export const SIGNIN_MOBILE_REQ_OTP_URL = "/api/auth/signin/send-otp";
export const SIGNIN_MOBILE_VERIFY_OTP_URL = "/api/auth/signin/validate-otp";

export const SIGNIN_SILENT_URL = "/api/auth/silent-login";
export const SIGNOUT_URL = "/api/auth/signout";

export const SIGNUP_MOBILE_URL = "/api/auth/signup/otp";
export const SIGNUP_MOBILE_VERIFY_OTP_URL = "/api/auth/signup/mobile";

export const PROJECT_LIST_URL = "/api/projects/list";
export const CREATE_PROJECT_URL = "/api/projects/create";
export const DELETE_PROJECT_URL = "/api/projects/delete";
export const PROJECT_UPDATE_URL = "/api/projects/update";

export const MY_PROJECT_LIST_URL = "/api/projects/mylist";
export const PROJECT_DETAIL_URL = "/api/projects/details";

export const ASSIGN_MEMBER_URL = "/api/projects/members/assign";
export const ASSIGN_MEMBER_LIST_URL = "/api/projects/members/list";
export const DELETE_ASSIGN_MEMBER = "/api/projects/members/remove";

export const ALLOCATE_DEVICE_URL = "/api/projects/devices/allocate";
export const ALLOCATE_LIST_URL = "/api/projects/devices/list";
export const DELETE_PROJECT_DEVICE_URL = "/api/projects/devices/remove";

export const USERS_LIST_URL = "/api/user/list";
export const CREATE_USER_URL = "/api/user/add"; // new add user for qouatation app
export const REMOVE_USER_URL = "/api/user/remove";
export const UPDATE_USER_URL = "/api/user/update";
export const BLOCK_USER_URL = "/api/user/block";
export const CHANGE_USER_TYPE_URL = "/api/user/change-type";

export const DEVICE_LIST_URL = "/api/device/list";
export const CREATE_DEVICE_URL = "/api/device/create";
export const DELETE_DEVICE_URL = "/api/device/delete";
export const DEVICE_UPDATE_URL = "/api/device/update";

export const CREATE_REQUEST_URL = "/api/service/request/create";
export const ALL_REQUEST_LIST_URL = "/api/service/request/all";
export const REQUEST_LIST_URL = "/api/service/request/list";

export const CREATE_CLIENT_URL = "/api/client/add";
export const UPDATE_CLIENT_URL = "/api/client/update";
export const REMOVE_CLIENT_URL = "/api/client/remove";
export const BLOCK_CLIENT_URL = "/api/client/block";
export const GETALL_CLIENT_URL = "/api/client/list";
export const GET_CLIENT_BY_ID_URL = "/api/client/info";

export const FETCH_PRODUCT_LIST_URL = "/api/catalog/product/list";
export const FETCH_CUSTOM_PRODUCT_LIST_URL =
  "/api/catalog/configurator/product/list";

export const CREATE_ASSEMBLE_PRODUCT_URL =
  "/api/catalog/configurator/product/create";
export const ADD_CONFIGURATOR_PRODUCT = "/api/catalog/configurator/product/add";
export const ADD_PRODUCT_URL = "/api/catalog/product/add";
export const DELETE_PRODUCT_URL = "/api/catalog/product/delete";
export const REMOVE_CONFIGURATOR_PRODUCT_URL =
  "/api/catalog/configurator/product/remove";
export const DELETE_ASSEMBLED_PRODUCT_URL =
  "/api/catalog/configurator/product/delete";
export const PRODUCT_UPDATE_URL = "/api/catalog/product/update";
export const ASSEMBLED_PRODUCT_UPDATE_URL =
  "/api/catalog/configurator/product/update";

export const GET_CONFIGURATOR_PRODUCT_BY_ID =
  "/api/catalog/configurator/product/view";

//Register_Address_API_URL
export const UPDATE_REGISTER_ADDRESS = "/api/client/registered/address/update";
export const DELETE_REGISTER_ADDRESS = "/api/client/registered/address/delete";

//Other_Address_API_URL
export const UPDATE_OTHER_ADDRESS = "/api/client/address/add";
export const DELETE_OTHER_ADDRESS = "/api/client/address/delete";

//Quotation_CREATE_API_URL
export const CREATE_QUTOTATION_URL = "/api/quote/create";
export const GET_MY_QUOTATION_LIST_URL = "/api/quote/my";
export const GET_MY_QUOTATION_LIST_BY_ID_URL = "/api/quote/view?quoteId=";
export const QUOTE_REMOVE_URL = "/api/quote/delete";
export const QUOTE_UPDATE_URL = "/api/quote/update";
export const QUOTE_UPDATE_CUSTOMER_URL = "/api/quote/update/customer";
export const QUOTE_UPDATE_STATUS_URL = "/api/quote/update/quote-status";
export const QUOTE_UPDATE_ADD_ITEM_URL = "/api/quote/update/add-item";
export const FETCH_QUOTE_LIST_URL = "/api/quote/list";
export const FETCH_QUOTE_UPDATE_TNC = "/api/quote/update/tnc";

export const FETCH_QUOTE_BY_CRITERIA = "/api/quote/criteria";

export const CREATE_HOSE_ASSEMBLE_PRODUCT_URL =
  "/api/catalog/hose/assembly/create";

export const ADD_TO_QOUTE = "/api/quote/update/add-to-quote";
export const REMOVE_QOUTE_ITEMS = "/api/quote/remove-quote-item";
export const SEARCH_HOSE_ASSEMBLY_BY_CRITERIA =
  "/api/catalog/hose/assembly/criteria";

export const GET_ALL_QUOTE_DASHBOARD = "/api/quote/dashboard/total-quote";
export const GET_ALL_USER_DASHBOARD = "/api/user/dashboard/totalUser";
export const GET_ALL_CLIENT_DASHBOARD = "/api/client/dashboard/totalClient";
export const GET_ALL_PRODUCT_DASHBOARD = "/api/catalog/dashboard/totalProduct";
export const GET_ALL_ASSEMBLE_PRODUCT_DASHBOARD =
  "/api/catalog/dashboard/totalAssembleProduct";
export const MY_QUOTE_REMOVE_URL = "/api/quote/remove";
export const CALCULATE_TOTAL_AMOUNT_URL = "/api/catalog/hose/assembly/calculate";
export const UPLOAD_PRODUCTS_URL = "/api/catalog/product/multiple-product";

export const UPDATE_PASSWORD_URL = "/api/auth/update-password";
export const CHANGE_PASSWORD_URL = "/api/auth/change-password";

export const CHARTS_DATA = "/api/quote/dashboard/trends/month-by-day";
export const PIE_CHART_DATA = "/api/quote/dashboard/by-quote-status";

export const ADD_DISCOUNT_URL = "/api/quote/discount/add";
export const FETCH_DISCOUNT_URL = "/api/quote/discount/view";
export const DELETE_DISCOUNT_URL = "/api/quote/discount/delete";
export const UPDATE_DISCOUNT_URL = "/api/quote/discount/update";

export const GET_MY_CLIENT_URL = "/api/client/my-list";
