import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import CustomerForm from "./Components/CustomerForm";

import { Dashboard as DashboardLayout } from "layouts";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import AddressContent from "../../../../components/AddressContent";
import CustomerAddress from "../CustomerAddress/CustomerAddress";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchClient,
  editClientTrigger,
} from "../../../../actions/clientAction";
import {
  editRegisterAddressTrigger,
  editOtherAddressTrigger,
  addOtherAddressTrigger,
} from "../../../../actions/registeredAddressAction";
import OtherAddressForm from "./Components/OtherAddressForm";
import RegisterAddressForm from "./Components/RegisterAddressForm";

const NewCustomer = ({ onSubmitSuccess }) => {
  const clientEdit = useSelector((state) => state.clientEdit);
  // console.log("clientEdit",clientEdit);
  const clientDataToEdit = clientEdit.clientDataToEdit;
  const otherAddressDataToEdit = clientEdit.clientDataToEdit?.addresses;
  const isClientEdit = clientEdit.isClientEdit;
  const isClientView = clientEdit.isClientView;
  const clientDataToView= clientEdit.clientDataToView;
  // console.log("clientDataToView",clientDataToView);
  const otherAddresses = clientDataToEdit?.addresses
    ? clientDataToEdit?.addresses
    : [];
  // console.log("otherAddress: ", otherAddresses);
   const registeredAddress = clientDataToEdit?.registeredAddress
    ? clientDataToEdit?.registeredAddress
    : [];
  // console.log("registeredAddress: ", registeredAddress);

  const [openOtherForm, setOpenOtherForm] = React.useState(false);
  const [openRegisteForm, setOpenRegisteForm] = React.useState(false);

  const handleClickOpenOtherForm = () => {
    setOpenOtherForm(true);
  };

  const handleCloseOtherForm = () => {
    setOpenOtherForm(false);
  };

  const handleClickOpenRegisterForm = () => {
    setOpenRegisteForm(true);
  };

  const handleCloseRegisterForm = () => {
    setOpenRegisteForm(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleUpdateOtherAddress = () => {
    // console.log("dataupadate :",data);
    dispatch(editOtherAddressTrigger(otherAddressDataToEdit));
    handleClickOpenOtherForm();
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - New Customers</title>
      </Helmet>
      <Grid container spacing={4} mt={15} mb={10}>
        <Grid item xs={12} sm={12} md={8}>
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardHeader
              title={isClientEdit ? "Update Customer" : " New Customer"}
            />
            <Divider />
            <CardContent>
              <CustomerForm
                onSubmitSuccess={onSubmitSuccess}
                data={clientDataToEdit}
                isClientEdit={isClientEdit}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <CustomerAddress
            onClick={handleClickOpenRegisterForm}
            onClickOther={handleUpdateOtherAddress}
            registeredAddress={registeredAddress}
            otherAddresses={otherAddresses}
          />
          <Grid>
          <RegisterAddressForm
            open={openRegisteForm}
            onClick={handleClickOpenRegisterForm}
            onClose={handleCloseRegisterForm}
            onSubmitSuccess={onSubmitSuccess}
            registeredAddressdata={clientDataToEdit}
            isClientEdit={isClientEdit}
          />
          </Grid>
          <Grid>
          <OtherAddressForm
            openOther={openOtherForm}
            onClick={handleClick}
            onClose={handleCloseOtherForm}
            onSubmitSuccess={onSubmitSuccess}
            data={otherAddressDataToEdit}
            isClientEdit={isClientEdit}
            openOtherAddressAlert= {open}
            openOtherAddresshandleAlertClose={handleClose}
          />
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

NewCustomer.propTypes = {
  onSubmitSuccess: PropTypes.func,
};

export default NewCustomer;
