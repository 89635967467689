import React from "react";
import { Grid, Box, Button, Card } from "@mui/material";
import { Helmet } from "react-helmet";
import { Dashboard as DashboardLayout } from "layouts";

// import ProductsAddList from "./components/ProductsAddList/ProductsAddList";
import QuoteCalculater from "./QuoteCalculater";
import PropTypes from "prop-types";

const QuoteCalciItems = ({ onSubmitSuccess }) => {
  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} Quote Calculater</title>
      </Helmet>
      <Card sx={{ m: 1, p: 1, height: "94vh" }}>
        <Box sx={{ p: 2, ml: 3 }}>
          <Grid container spacing={2} sx={{ mt: "80px" }}>
            <Grid item xs={12}>
              <QuoteCalculater onSubmitSuccess={onSubmitSuccess} />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </DashboardLayout>
  );
};
QuoteCalciItems.propTypes = {
  onSubmitSuccess: PropTypes.func,
};
export default QuoteCalciItems;
