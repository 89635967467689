/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  USER_FETCH,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_EDIT_TRIGGER,
  USER_CREATE_TRIGGER,
  USER_CREATE_SUCCESS,
  UPDATE_USER_SUCCESS,
  CHANGE_PASSOWRD_EDIT_TRIGGER,
  DISCOUNT_FETCH,
  DISCOUNT_FETCH_SUCCESS,
  DISCOUNT_FETCH_FAILURE,
  DISCOUNT_EDIT_TRIGGER,
  DISCOUNT_CREATE_TRIGGER,
  UPDATE_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_SUCCESS,
} from "actions/usersAction";

const initialState = {
  users: [],
  userDataToEdit: undefined,
  isUserEdit: false,
  passWordDataToEdit: undefined,
  isPasswordEdit: false,
  count: 0,
  discount: [],
  discountDataToEdit: undefined,
  isDiscountEdit: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH:
      {
        return produce(state, (draft) => {
          draft.users = [];
          draft.count = 0;
        });
      }
      break;

    case USER_FETCH_SUCCESS:
      {
        const { users } = action.payload;
        // console.log(
        //   "userReducer: USER_FETCH_SUCCESS ",
        //   users.users,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.users = users.users;
          draft.count = users?.users.length;
        });
      }
      break;

    case USER_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.users = [];
          draft.count = 0;
        });
      }
      break;
    case USER_EDIT_TRIGGER:
      {
        // console.log("projectReducer() PROJECT_EDIT_TRIGGER", action.payload);
        const { userEdit } = action.payload;
        // console.log("clientReducerEdit", userEdit);
        return produce(state, (draft) => {
          draft.isUserEdit = true;
          draft.userDataToEdit = userEdit;
        });
      }
      break;
    case CHANGE_PASSOWRD_EDIT_TRIGGER:
      {
        // console.log(
        //   "projectReducer() CHANGE_PASSOWRD_EDIT_TRIGGER",
        //   action.payload
        // );
        const { changePasswordEdit } = action.payload;
        // console.log("clientReducerEdit", changePasswordEdit);
        return produce(state, (draft) => {
          draft.isPasswordEdit = true;
          draft.passWordDataToEdit = changePasswordEdit;
        });
      }
      break;
    case USER_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.userDataToEdit = undefined;
          draft.isUserEdit = false;
        });
      }
      break;
    case UPDATE_USER_SUCCESS:
    case USER_CREATE_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.userDataToEdit = undefined;
          draft.isUserEdit = false;
        });
      }
      break;
    case UPDATE_DISCOUNT_SUCCESS:
    case ADD_DISCOUNT_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.discountDataToEdit = undefined;
          draft.isDiscountEdit = false;
        });
      }
      break;
    case DISCOUNT_EDIT_TRIGGER:
      {
        // console.log("userReducer() DISCOUNT_EDIT_TRIGGER", action.payload);
        const { discountEdit } = action.payload;
        // console.log("UserReducer", discountEdit);
        return produce(state, (draft) => {
          draft.isDiscountEdit = true;
          draft.discountDataToEdit = discountEdit;
        });
      }
      break;
    case DISCOUNT_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.discountDataToEdit = undefined;
          draft.isDiscountEdit = false;
        });
      }
      break;
    case DISCOUNT_FETCH:
      {
        return produce(state, (draft) => {
          draft.discount = [];
          draft.count = 0;
        });
      }
      break;

    case DISCOUNT_FETCH_SUCCESS:
      {
        const { discount } = action.payload;
        // console.log(
        //   "userReducer: DISCOUNT_FETCH_SUCCESS ",
        //   discount,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.discount = discount;
          // draft.count = users?.users.length;
        });
      }
      break;

    case DISCOUNT_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.discount = [];
          draft.count = 0;
        });
      }
      break;
    default:
      {
        return state;
      }
      break;
  }
};

export default userReducer;
