import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { TextField, Button, Grid, Divider, Autocomplete } from "@mui/material";
import { SUPPLIERS } from "config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { createQuote, updateQuote } from "../../../actions/quoteActions";
import { fetchClient, fetchClientById } from "../../../actions/clientAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addressFormatter } from "../../../utils/formatUtils";

const ClientInfoForm = ({ handleNext, handleBack, activeStep }) => {
  const dispatch = useDispatch();
  const [supplierInfo, setSupplierInfo] = useState(SUPPLIERS[0]);
  const [currentClientInfo, setCurrentClientInfo] = useState(null);
  // console.log("currentClientInfo", currentClientInfo);
  const supplierAddress = supplierInfo?.address;
  const quote = useSelector((state) => state?.quote);
  const quoteInProgress = quote?.quoteInProgress;
  // console.log("quoteInProgress", quoteInProgress);
  const quoteId = quoteInProgress?.quoteId;
  const clientId = quote?.quoteInProgress?.clientId;
  // console.log("clientId", clientId);
  const isQuoteEdit = quote?.isQuoteEdit;
  // console.log("isQuoteEdit", isQuoteEdit);
  const supplierUpdateAdd = quoteInProgress?.supplierDetails?.address;

  const clients = useSelector((state) => state?.client.clients);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    dispatch(fetchClient());
  }, [dispatch]);

  useEffect(() => {
    setCurrentClientInfo(quoteInProgress?.clientBusinessName || {});
  }, [quoteInProgress]);

  const handleClientBusinessNameChange = (event, newValue, setFieldValue) => {
    if (newValue) {
      setFieldValue("clientBusinessName", newValue.name);
      const selectedClient = clients.find(
        (client) => client.name === newValue.name
      );
      setCurrentClientInfo(selectedClient);
      setFieldValue("concernedSalesPerson", selectedClient.contactPerson);
      // Dispatch fetchClientById with the new client ID

      dispatch(fetchClientById(selectedClient.clientId));
    } else {
      setFieldValue("clientBusinessName", "");
      setFieldValue("concernedSalesPerson", "");
      setCurrentClientInfo(null);
    }
  };

  const handleSupplierBusinessNameChange = (event, newValue, setFieldValue) => {
    if (newValue) {
      setFieldValue("businessName", newValue.businessName);
      setSupplierInfo(newValue);
    } else {
      setFieldValue("businessName", "");
      setSupplierInfo(SUPPLIERS[0]);
    }
  };

  const createAndUpdateAddress = addressFormatter(supplierAddress)
    ? addressFormatter(supplierAddress)
    : addressFormatter(supplierUpdateAdd);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        supplierId: supplierInfo.supplierId,
        businessName:
          quoteInProgress?.supplierDetails?.businessName ||
          supplierInfo.businessName,
        clientBusinessName:
          quoteInProgress?.clientBusinessName || currentClientInfo?.name,
        concernedSalesPerson:
          quoteInProgress?.quoteDetails?.concernedSalesPerson ||
          currentClientInfo?.contactPerson ||
          "",
        enquiryDate: quoteInProgress?.quoteDetails?.enquiryDate || new Date(),
        paymentTerms: "",
        enquiry: quoteInProgress?.quoteDetails?.enquiry || "",
        quoteDate: quoteInProgress?.quoteDate || new Date(),
        clientId: isQuoteEdit ? clientId : currentClientInfo?.clientId,
      }}
      validationSchema={Yup.object({
        // Add your validation schema here
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (isQuoteEdit) {
            await dispatch(
              updateQuote(
                values,
                currentClientInfo?.name,
                supplierInfo,
                quoteId
              )
            );
          } else {
            await dispatch(
              createQuote(values, currentClientInfo, supplierInfo)
            );
            await dispatch(fetchClientById(currentClientInfo?.clientId));
          }

          handleNext();
        } catch (error) {
          // console.log("Form: error", error);
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="businessName"
                    value={
                      values.businessName
                        ? SUPPLIERS.find(
                            (option) =>
                              option.businessName === values.businessName
                          )
                        : supplierInfo
                    }
                    onChange={(event, newValue) =>
                      handleSupplierBusinessNameChange(
                        event,
                        newValue,
                        setFieldValue
                      )
                    }
                    options={SUPPLIERS}
                    getOptionLabel={(options) => options.businessName}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Supplier Name" required />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Quotation Date"
                      sx={{ width: "100%" }}
                      onChange={(value) =>
                        setFieldValue("quoteDate", value, true)
                      }
                      defaultValue={new Date()}
                      value={values.quoteDate}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Quotation Date"
                          name="quoteDate"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="supplierAddress"
                    id="supplierAddress"
                    label="Supplier Address"
                    type="string"
                    multiline
                    rows={6}
                    value={createAndUpdateAddress}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={0.5}
              md={0.5}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={12} sm={7.5} md={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    value={
                      values.clientBusinessName
                        ? clients.find(
                            (option) =>
                              option.name === values.clientBusinessName
                          ) || currentClientInfo
                        : null
                    }
                    onChange={(event, newValue) =>
                      handleClientBusinessNameChange(
                        event,
                        newValue,
                        setFieldValue
                      )
                    }
                    disablePortal
                    id="clientBusinessName"
                    options={clients}
                    getOptionLabel={(option) => option.name}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client's Business Name"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="concernedSalesPerson"
                    id="concernedSalesPerson"
                    label="Contact Person"
                    type="string"
                    value={values.concernedSalesPerson}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Enquiry Date"
                      sx={{ width: "100%" }}
                      onChange={(value) =>
                        setFieldValue("enquiryDate", value, true)
                      }
                      defaultValue={new Date()}
                      value={values.enquiryDate}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Enquiry Date"
                          name="enquiryDate"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="paymentTerms"
                    id="paymentTerms"
                    label="Payment Terms"
                    type="string"
                    multiline
                    rows={2}
                    value={values.paymentTerms}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="enquiry"
                    id="enquiry"
                    label="Client's Enquiry"
                    type="string"
                    multiline
                    rows={6}
                    value={values.enquiry}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {isQuoteEdit ? "Update Quotation" : "Create Quotation"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ClientInfoForm;
