import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PRODUCT_TYPES, PRODUCT_CATEGORY, UOM, BUSINESS_ID } from "config";
import {
  addProduct,
  updateProducts,
} from "../../../../../actions/catalogAction";

const NewProductForm = ({ onSubmitSuccess, data, isProductEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const auth = useSelector((state) => state.auth);
  //   const userId = auth.user.userId;
  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessId: BUSINESS_ID,
        productType: data?.type ? data?.type : "",
        skuId: data?.skuid ? data?.skuid : "",
        productCategory: data?.category ? data?.category : "",
        name: data?.name ? data?.name : "",
        description: data?.description ? data?.description : "",
        hsnCode: data?.hsnCode ? data?.hsnCode : "",
        uom: data?.uom ? data?.uom : "",
        brandName: data?.brandName ? data?.brandName : "",
        brandPart: data?.brandPart ? data?.brandPart : "",
        size: data?.size ? data?.size : "",
        mrp: data?.price?.mrp ? data?.price?.mrp : "",
        priceRate: data?.price?.rate ? data?.price?.rate : "",
        salesPrice: data?.price?.salesPrice ? data?.price?.salesPrice : "",
        costRate: data?.cost?.rate ? data?.cost?.rate : "",
        gst: data?.gst ? data?.gst : "",
        notes: data?.notes ? data?.notes : "",
      }}
      validationSchema={Yup.object().shape({
        // businessId: Yup.string().required("Business Id is required").max(10),
        // productType: Yup.string().required("productType is required").max(500),

        skuId: Yup.string().required("SKU Id is required").max(100),
        // // productCategory: Yup.string().required("productCategory is required").max(100),
        name: Yup.string().required("Name is required").max(100),
        description: Yup.string().required("Description is required").max(500),
        hsnCode: Yup.string().required("hsn Code Id is required").max(100),
        // // uom: Yup.string()
        // //   .required("Staus is required")
        // //   .max(100),
        brandName: Yup.string().required("Brand Name Id is required").max(100),
        brandPart: Yup.string().required("Brand Part Id is required").max(100),
        size: Yup.string().required("Size is required").max(100),
        gst: Yup.string().required("GST is required").max(100),
        priceRate: Yup.string().required("priceRate is required").max(100),
        salesPrice: Yup.string().required("salesPrice is required").max(100),
        costRate: Yup.string().required("costRate is required").max(100),
        gst: Yup.string().required("GST is required").max(100),
        notes: Yup.string().required("notes is required").max(100),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (data) {
            await dispatch(updateProducts(data.productId, values));
          } else {
            await dispatch(addProduct(values));
          }

          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  fullWidth
                  value={values.productType}
                  onChange={(event, newValue) => {
                    setFieldValue("productType", newValue);
                  }}
                  disablePortal
                  id="productType"
                  options={PRODUCT_TYPES}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Product Type"
                        required
                        error={
                          touched.productType && Boolean(errors.productType)
                        }
                        helperText={touched.productType && errors.productType}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="skuId"
                  name="skuId"
                  label="SKU Id"
                  required
                  value={values.skuId}
                  onChange={handleChange}
                  error={touched.skuId && Boolean(errors.skuId)}
                  helperText={touched.skuId && errors.skuId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="hsnCode"
                  name="hsnCode"
                  label="Hsn Code"
                  required
                  value={values.hsnCode}
                  onChange={handleChange}
                  error={touched.hsnCode && Boolean(errors.hsnCode)}
                  helperText={touched.hsnCode && errors.hsnCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.productCategory}
                  onChange={(event, newValue) => {
                    setFieldValue("productCategory", newValue);
                  }}
                  disablePortal
                  id="productCategory"
                  options={PRODUCT_CATEGORY}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Product Category"
                        required
                        error={
                          touched.productCategory &&
                          Boolean(errors.productCategory)
                        }
                        helperText={
                          touched.productCategory && errors.productCategory
                        }
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.uom}
                  onChange={(event, newValue) => {
                    setFieldValue("uom", newValue);
                  }}
                  disablePortal
                  id="uom"
                  options={UOM}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Uom"
                        required
                        error={touched.uom && Boolean(errors.uom)}
                        helperText={touched.uom && errors.uom}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  required
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="brandName"
                  name="brandName"
                  label="Brand Name"
                  required
                  value={values.brandName}
                  onChange={handleChange}
                  error={touched.brandName && Boolean(errors.brandName)}
                  helperText={touched.brandName && errors.brandName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="brandPart"
                  name="brandPart"
                  label="Brand Part"
                  required
                  value={values.brandPart}
                  onChange={handleChange}
                  error={touched.brandPart && Boolean(errors.brandPart)}
                  helperText={touched.brandPart && errors.brandPart}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="size"
                  name="size"
                  label="Size"
                  required
                  value={values.size}
                  onChange={handleChange}
                  error={touched.size && Boolean(errors.size)}
                  helperText={touched.size && errors.size}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="mrp"
                  name="mrp"
                  label="MRP"
                  value={values.mrp}
                  required
                  onChange={handleChange}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="priceRate"
                  name="priceRate"
                  label="Rate"
                  required
                  value={values.priceRate}
                  onChange={handleChange}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="salesPrice"
                  name="salesPrice"
                  label="Sales Price"
                  value={values.salesPrice}
                  required
                  onChange={handleChange}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="costRate"
                  name="costRate"
                  label="Cost"
                  value={values.costRate}
                  required
                  onChange={handleChange}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="gst"
                  name="gst"
                  label="Gst(%)"
                  required
                  value={values.gst}
                  onChange={handleChange}
                  error={touched.gst && Boolean(errors.gst)}
                  helperText={touched.gst && errors.gst}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="notes"
                  name="notes"
                  label="Note"
                  value={values.notes}
                  onChange={handleChange}
                  error={touched.notes && Boolean(errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/app/mgmt/catalog/products/all")}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {isProductEdit ? "Update Product" : "Add Product"}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default NewProductForm;
