import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";
import catalogReducer from "./catalogReducer";
import quoteReducer from "./quoteReducer";
import configuratorReducer from "./configuratorReducer";
import dashBoardReducer from "./dashBoardReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  projectEdit: projectReducer,
  myProject: projectReducer,
  projectDetail: projectReducer,
  product: catalogReducer,
  // customProducts: catalogReducer,
  customProduct: catalogReducer,
  // customProductsUpdate: catalogReducer,
  productEdit: catalogReducer,
  // assembledProductEdit: catalogReducer,
  configurator: configuratorReducer,
  user: userReducer,
  userDataToEdit: userReducer,
  isUserEdit: userReducer,
  userEdit: userReducer,
  form: formReducer,
  client: clientReducer,
  clientDataToEdit: clientReducer,
  isClientEdit: clientReducer,
  clientEdit: clientReducer,
  quote: quoteReducer,
  dashBoard: dashBoardReducer
});

export default rootReducer;
