/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  PROJECT_FETCH,
  PROJECT_FETCH_SUCCESS,
  PROJECT_FETCH_FAILURE,
  ALLOCATE_DEVICE_FETCH,
  ALLOCATE_DEVICE_FETCH_SUCCESS,
  ALLOCATE_DEVICE_FETCH_FAILURE,
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILURE,
  MY_PROJECT_FETCH,
  MY_PROJECT_FETCH_SUCCESS,
  MY_PROJECT_FETCH_FAILURE,
  PROJECT_DETAILS_FETCH,
  PROJECT_DETAILS_FETCH_SUCCESS,
  PROJECT_DETAILS_FETCH_FAILURE,
  PROJECT_EDIT_TRIGGER,
  MEMBER_LIST_FETCH,
  MEMBER_LIST_FETCH_SUCCESS,
  MEMBER_LIST_FETCH_FAILURE,
} from "actions/projectAction";
import {
  PROJECT_CREATE_TRIGGER,
  PROJECT_UPDATE_SUCCESS,
} from "../actions/projectAction";

const initialState = {
  projects: [],
  devices: [],
  members: [],
  myProjects: [],
  projectDetails: [],
  projectDataToEdit: undefined,
  isProjectEdit: false,
  count: 0,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_FETCH:
      {
        return produce(state, (draft) => {
          draft.projects = [];
          draft.count = 0;
        });
      }
      break;
    
    case PROJECT_FETCH_SUCCESS:
      {
        const { projects } = action.payload;
        // console.log("projectReducer: PROJECT_FETCH_SUCCESS ", projects, action.payload);

        return produce(state, (draft) => {
          draft.projects = projects;
          draft.count = projects?.length;
        });
      }
      break;

    case PROJECT_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.requirements = [];
          draft.count = 0;
        });
      }
      break;

    case ALLOCATE_DEVICE_FETCH:
      {
        return produce(state, (draft) => {
          draft.devices = [];
          draft.count = 0;
        });
      }
      break;

    case ALLOCATE_DEVICE_FETCH_SUCCESS:
      {
        const { devices } = action.payload;
        // console.log(
        //   "projectReducer: ALLOCATE_DEVICE_FETCH_SUCCESS ",
        //   devices,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.devices = devices;
          draft.count = devices?.length;
        });
      }
      break;

    case ALLOCATE_DEVICE_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.devices = [];
          draft.count = 0;
        });
      }
      break;

    case MEMBER_LIST_FETCH:
      {
        return produce(state, (draft) => {
          draft.members = [];
          draft.count = 0;
        });
      }
      break;

    case MEMBER_LIST_FETCH_SUCCESS:
      {
        const { members } = action.payload;
        // console.log(
        //   "projectReducer: MEMBER_LIST_FETCH_SUCCESS ",
        //   members,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.members = members;
          draft.count = members?.length;
        });
      }
      break;

    case MEMBER_LIST_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.devices = [];
          draft.count = 0;
        });
      }
      break;

    case PROJECT_UPDATE_SUCCESS:
    case PROJECT_CREATE_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.projectDataToEdit = undefined;
          draft.isProjectEdit = false;
        });
      }
      break;
    case MY_PROJECT_FETCH:
      {
        return produce(state, (draft) => {
          draft.myProjects = [];
          draft.count = 0;
        });
      }
      break;

    case MY_PROJECT_FETCH_SUCCESS:
      {
        const { myProjects } = action.payload;
        // console.log("projectReducer: MY_PROJECT_FETCH_SUCCESS ", myProjects, action.payload);

        return produce(state, (draft) => {
          draft.myProjects = myProjects;
          draft.count = myProjects?.length;
        });
      }
      break;

    case MY_PROJECT_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.myProjects = [];
          draft.count = 0;
        });
      }
      break;

    case PROJECT_DETAILS_FETCH:
      {
        return produce(state, (draft) => {
          draft.projectDetails = [];
          draft.count = 0;
        });
      }
      break;

    case PROJECT_DETAILS_FETCH_SUCCESS:
      {
        const { projectDetails } = action.payload;
        // console.log(
        //   "projectReducer: PROJECT_DETAIL_FETCH_SUCCESS ",
        //   projectDetails,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.projectDetails = projectDetails ? projectDetails : [];
          draft.count = projectDetails?.length;
        });
      }
      break;

    case PROJECT_DETAILS_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.projectDetails = [];
          draft.count = 0;
        });
      }
      break;

    case PROJECT_EDIT_TRIGGER:
      {
        // console.log("projectReducer() PROJECT_EDIT_TRIGGER", action.payload);
        const { projectEdit } = action.payload;
        return produce(state, (draft) => {
          draft.isProjectEdit = true;
          draft.projectDataToEdit = projectEdit;
        });
      }
      break;

    case PROJECT_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.projectDataToEdit = undefined;
          draft.isProjectEdit = false;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default projectReducer;
