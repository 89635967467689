import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
} from "@mui/material";
import CustomizedStepper from "../../components/CustomizedStepper/CustomizedStepper";
import ClientInfoForm from "./components/ClientInfoForm";
import AddressForm from "./components/AddressForm/AddressForm";
import AddItems from "./components/AddItems/AddItems";
import Summary from "./components/Summary";
import TermsConditions from "./components/TermsConditions";
import InvoicePDF from "./components/GenerateQuatation/InvoicePdf";
import { useSelector } from "react-redux";
import QuoteItems from "./components/AddItems/QuoteItems";

function Quote() {
  const quotes = useSelector((state) => state?.quote);
  // console.log(" quote: ", quotes);

  const quoteInProgress = quotes?.quoteInProgress;
  // console.log(" quoteInProgress: ", quoteInProgress);
  // console.log("quoteInProgress", quoteInProgress);
  const moveNext = () => {};

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Start Quotation",
      data: (
        <ClientInfoForm
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Add Customer Details",
      data: (
        <AddressForm
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Add Items",
      data: (
        <QuoteItems
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    // {
    //   label: "Summary",
    //   data: (
    //     <Summary
    //       handleNext={handleNext}
    //       handleBack={handleBack}
    //       activeStep={activeStep}
    //     />
    //   ),
    // },
    {
      label: "Terms & Conditions",
      data: (
        <TermsConditions
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Review & Finalize",
      data: (
        <InvoicePDF
          quote={quoteInProgress}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} Quote</title>
      </Helmet>
      <CardContent>
        <CustomizedStepper
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </CardContent>
    </DashboardLayout>
  );
}

export default Quote;
