import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "./mse.png";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/formatUtils";
import { moneyFormatter } from "../../../../utils/formatUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Create styles
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
const styles = StyleSheet.create({
  page: {
    height: "500px",
    width: "100%",
  },
  pdfPage: {
    fontSize: 10,
    backgroundColor: "white",
    border: "3px solid black",
    padding: "25px",
    // lineHeight: 1.5,
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },

  text: {
    marginTop: "3px",
  },
  grandTotal: {
    marginTop: "10px",
  },
  section: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.2px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  tncSection: {
    padding: "10px",
    paddingTop: "10px",
    height: "auto",
    borderBottom: "1px solid black",
  },
  billToSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  deliverySection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  SupplierSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },

  OrderIdSection: {
    fontFamily: "Lato Bold",
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "column-reverse",
    marginLeft: "70px",
  },
  accountDetailsSection: {
    // height: "auto",
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    borderBottom: "0.5px solid black",
  },
  rightSection: {
    paddingRight: "180px",
  },
  leftSection: {
    paddingRight: "-150px",
  },
  totalSection: {
    padding: "10px",
    height: "auto",
    // borderBottom: "0.5px solid gray",
    backgroundColor: "white",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "10px",
  },
  signiture: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "50px",
  },
  addressSection: {
    flexDirection: "row",
    marginTop: "40px",
    padding: "5px",
  },
  subTotalSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    border: "0.5px solid gray",
    backgroundColor: "white",
  },
  subTotalInnerSection: {
    padding: 15,
  },
  title: {
    fontSize: 11,
    fontWeight: 900,
  },
  itemListHead: {
    flexDirection: "row",
    // justifyContent: "center",
    backgroundColor: "white",
    color: "black",
    // fontWeight: "ultrabold",
    fontFamily: "Lato Bold",
  },
  itemList: {
    flexDirection: "row",
    fontSize: 8,
  },
  id: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "20%",
  },
  name: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "50%",
    textAlign: "left",
    overflow: "hidden",
  },
  hsnCode: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "20%",
  },
  prise: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "25%",
  },
  totalTax: {
    flexDirection: "column",
    padding: 10,
    border: "0.3px solid black",
    width: "30%",
  },
  quantity: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "15%",
  },
  total: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "25%",
  },
  totalTax: {
    flexDirection: "column",
    padding: 10,
    border: "0.3px solid black",
    width: "30%",
  },
  table: {
    padding: 5,
    backgroundColor: "white",
    marginTop: "40px",
  },
  // gstItemListHead: {
  //   // flexDirection: "column",
  //   justifyContent: "center",
  //   backgroundColor: "white",
  //   color: "black",
  //   // fontWeight: "ultrabold",
  //   fontFamily: "Lato Bold",
  // }
  gstTotal: {
    width: "40%",
    marginRight: "-130px",
  },
  gstTotal2: {
    width: "50%",
    marginRight: "-157px",
    textAlign: "right",
  },
  head: {
    flexDirection: "column",
    padding: 5,
    border: "0.3px solid black",
    width: "40%",
  },
  gstItemList: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 8,
    marginTop: 10,
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cardHeaders: {
    flexDirection: "start",
    backgroundColor: "white",
    fontFamily: "Lato Bold",
  },
  invoiceT: {
    fontSize: "18px",
    color: "black",
    letterSpacing: "5px",
    // marginTop: "10px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
  },
  leftColumn: {
    justifyContent: "center",
    backgroundColor: "white",
    padding: "5px",
    width: "50%",
  },
});

const AppendixInvoicePdf = ({ quote, handleBack, activeStep }) => {
  let params = useParams();
  //   useEffect(() => {
  //     fetchOrderDetail(params.orderId);
  //   }, []);

  const navigate = useNavigate();

  const itemsData = quote?.items ? quote?.items : [];
  // console.log("items", itemsData);
  const renderItems = () => {
    const pages = [];
    const headers = [
      { label: "Hose Name", data: itemsData[0] },
      { label: "Fitting A", data: itemsData[1] },
      { label: "Fitting B", data: itemsData[2] },
      { label: "Sleeves", data: itemsData[3] },
      { label: "Adaptors", data: itemsData[4] },
    ];

    headers.forEach((header, index) => {
      const { label, data } = header;
      if (data && data.products && data.products.length > 0) {
        pages.push(
          <View key={index}>
            <View style={styles.table}>
              <Text style={{ fontFamily: "Lato Bold", marginBottom: "10px" }}>
                Description: {data.description}
              </Text>
              <View style={styles.itemListHead}>
                <Text style={styles.name}>Name</Text>
                <Text style={styles.quantity}>Quantity</Text>
                <Text style={styles.hsnCode}>UOM</Text>
                <Text style={styles.prise}>Price</Text>
                <Text style={styles.prise}>Total Amount</Text>
              </View>
              {data.products.map((product, idx) => (
                <View style={styles.itemList} key={idx}>
                  <View style={styles.tableRow}>
                    <Text style={styles.name}>{product.name}</Text>
                    <Text style={styles.quantity}>{product.quantity}</Text>
                    <Text style={styles.hsnCode}>{product.uom}</Text>
                    <Text style={styles.prise}>
                      Rs. {moneyFormatter(product.salesPrice || 0)}
                    </Text>
                    <Text style={styles.prise}>
                      Rs.{" "}
                      {moneyFormatter(
                        (product.salesPrice || 0) * (product.quantity || 0)
                      )}
                    </Text>
                  </View>
                </View>
              ))}
              <Text
                style={{
                  fontFamily: "Lato Bold",
                  position: "relative",
                  left: "75%",
                  top: "10px",
                }}
              >
                Total Amount: Rs. {moneyFormatter(data.salesPrice || 0)}
              </Text>
            </View>
            <View style={styles.table}>
              <View style={styles.itemListHead}>
                <Text style={styles.name}>Description</Text>
                <Text style={styles.prise}>Amount</Text>
                <Text style={styles.hsnCode}>QTY</Text>
                <Text style={styles.prise}>Taxable Amount</Text>
                <Text style={styles.totalTax}>Total Tax</Text>
                <Text style={styles.prise}>Total Amount</Text>
              </View>
              <View style={styles.itemList} key={index}>
                <View style={styles.tableRow}>
                  <Text style={styles.name}>{data?.description}</Text>
                  <Text style={styles.prise}>
                    Rs. {moneyFormatter(data.salesPrice || 0)}
                  </Text>
                  <Text style={styles.hsnCode}>{data.qty}</Text>
                  <Text style={styles.prise}>
                    Rs. {moneyFormatter(data.taxableAmount || 0)}
                  </Text>
                  <Text style={styles.totalTax}>
                    {data.gstAmount?.cgst !== 0 && (
                      <Text>
                        CGST: Rs. {moneyFormatter(data.gstAmount?.cgst)}
                        {"\n"}
                      </Text>
                    )}
                    {data.gstAmount?.sgst !== 0 && (
                      <Text>
                        SGST: Rs. {moneyFormatter(data.gstAmount?.sgst)}
                        {"\n"}
                      </Text>
                    )}
                    {data.gstAmount?.igst !== 0 && (
                      <Text>
                        IGST: Rs. {moneyFormatter(data.gstAmount?.igst)}
                        {"\n"}
                      </Text>
                    )}
                  </Text>
                  <Text style={styles.prise}>
                    Rs. {moneyFormatter(data.totalAmount || 0)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        );
      }
    });
    return pages;
  };
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.pdfPage}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "2px solid grey",
            paddingBottom: "10px",
          }}
        >
          <View style={{ width: "35%", marginLeft: "25px", marginTop: "10px" }}>
            <Image src={logo} style={{ width: 100, height: 80 }} />
          </View>
          <View style={{ width: "25%" }}>
            <Text style={styles.invoiceT}>Appendix</Text>
          </View>

          <View style={styles.OrderIdSection}>
            <Text style={styles.text}>Quote Id {quote?.quoteId}</Text>
            <Text style={styles.text}>
              Quote Date : {dateFormatter(quote?.quoteDate)}
            </Text>
            <Text style={styles.text}>Quote Status : {quote?.status}</Text>
          </View>
        </View>

        {renderItems()}
        <View style={styles.gstItemList}>
          <View style={styles.gstTotal}>
            <Text style={styles.head}>Sub Total</Text>
            <Text style={styles.head}>CGST</Text>
            <Text style={styles.head}>SGST</Text>
            <Text style={styles.head}>IGST</Text>
            <Text style={styles.head}>Total</Text>
          </View>
          <View style={styles.gstTotal2}>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.subTotal
                  ? quote?.amountSummary?.subTotal
                  : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.
              {moneyFormatter(
                quote?.amountSummary?.cgst ? quote?.amountSummary?.cgst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.sgst ? quote?.amountSummary?.sgst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.
              {moneyFormatter(
                quote?.amountSummary?.igst ? quote?.amountSummary?.igst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.
              {moneyFormatter(
                quote?.amountSummary?.grandTotal
                  ? quote?.amountSummary?.grandTotal
                  : 0
              )}
            </Text>
          </View>
        </View>

        {/* <View style={styles.totalSection}>
          <Text style={styles.text}>
            Sub Total : Rs.
            {moneyFormatter(
              quote?.amountSummary?.subTotal
                ? quote?.amountSummary?.subTotal
                : 0
            )}
          </Text>
          <Text style={styles.text}>
            CGST : Rs.
            {moneyFormatter(
              quote?.amountSummary?.cgst ? quote?.amountSummary?.cgst : 0
            )}
          </Text>
          <Text style={styles.text}>
            IGST : Rs.
            {moneyFormatter(
              quote?.amountSummary?.igst ? quote?.amountSummary?.igst : 0
            )}
          </Text>
          <Text style={styles.text}>
            SGST : Rs.
            {moneyFormatter(
              quote?.amountSummary?.sgst ? quote?.amountSummary?.sgst : 0
            )}
          </Text>
          <Text style={styles.grandTotal}>
            Total Amount : Rs.
            {moneyFormatter(
              quote?.amountSummary?.grandTotal
                ? quote?.amountSummary?.grandTotal
                : 0
            )}
          </Text>
        </View> */}

        {/* </View> */}
        <View style={styles.signiture}>
          <Text style={{ fontFamily: "Lato Bold" }}>Authorised Signitory</Text>
        </View>
        <View style={styles.thankyou}>
          <Text style={styles.thankU}>Thank you for your Order</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PDFViewer style={styles.page}>
          <MyDocument />
        </PDFViewer>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back
          </Button>
          <Grid item xs="auto">
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/app/quotations/all")}
              >
                Finish & Go to Quote List
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppendixInvoicePdf;
