import React from "react";
import PropTypes from 'prop-types';

import { CopyRight } from "components";
import { TopBar } from './components';
import { Box, Grid } from '@mui/material';

const Main = ({ children }) => {
    return (
        <Box>
            <TopBar />
            <main>
                {children}
            </main>
            <Grid alignItems="flex-end">
                <CopyRight />
            </Grid>
        </Box>
    )
}

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;