/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  CREATE_ASSEMBLE_PRODUCT_SUCCESS,
  ADD_CONFIG_PRODUCT_SUCCESS,
  ASSEMBLED_PRODUCT_EDIT_TRIGGER,
  ASSEMBLED_PRODUCT_CREATE_TRIGGER,
  ASSEMBLED_PRODUCT_UPDATE_SUCCESS,
  FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS,
  REMOVE_ASSEMBLED_PRODUCT_SUCCESS,
} from "actions/catalogAction";
const initialState = {
  products: [],
  currentStep: 1,
  skuid: undefined,
  name: undefined,
  description: undefined,
  hsnCode: undefined,
  uom: undefined,
  totalPrice: undefined,
  totalGst: undefined,
  discount: undefined,
  notes: undefined,
  assembledProductId: undefined,
  assembledProductDataToEdit: [],
  isAssembledProductEdit: false,
  dataToEdit: {
    skuid: undefined,
    name: undefined,
    description: undefined,
    hsnCode: undefined,
    uom: undefined,
    totalPrice: undefined,
    totalGst: undefined,
    discount: undefined,
    notes: undefined,
    assembledProductId: undefined,
    products: [],
  },
};

const configuratorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ASSEMBLE_PRODUCT_SUCCESS:
    case ADD_CONFIG_PRODUCT_SUCCESS:
    case REMOVE_ASSEMBLED_PRODUCT_SUCCESS:
      {
        const { assembledProduct } = action.payload;
        // console.log(
        //   "configuratorReducer: CREATE_ASSEMBLE_PRODUCT_SUCCESS ",
        //   assembledProduct,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.dataToEdit = {
            skuid: assembledProduct?.skuid,
            name: assembledProduct?.name,
            description: assembledProduct?.description,
            hsnCode: assembledProduct?.hsnCode,
            uom: assembledProduct?.uom,
            totalPrice: assembledProduct?.totalPrice,
            totalGst: assembledProduct?.totalGst,
            discount: assembledProduct?.discount,
            notes: assembledProduct?.notes,
            assembledProductId: assembledProduct?.assembledProductId,
            products: assembledProduct?.products
              ? assembledProduct?.products
              : [],
          };
        });
      }
      break;


    case FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS:
      {
        const { customProduct } = action.payload;
        // console.log(
        //   "catalogReducer() FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS",
        //   customProduct,
        //   action.payload
        // );
        return produce(state, (draft) => {
          draft.dataToEdit = {
            skuid: customProduct?.skuid,
            name: customProduct?.name,
            description: customProduct?.description,
            hsnCode: customProduct?.hsnCode,
            uom: customProduct?.uom,
            totalPrice: customProduct?.totalPrice,
            totalGst: customProduct?.totalGst,
            discount: customProduct?.discount,
            notes: customProduct?.notes,
            assembledProductId: customProduct?.assembledProductId,
            products: customProduct?.assembleProduct?.products
              ? customProduct?.assembleProduct?.products
              : [],
          };
          draft.isAssembledProductEdit = true;
        });
      }
      break;
    case ASSEMBLED_PRODUCT_UPDATE_SUCCESS:
      {
        const { customProduct } = action.payload;
        return produce(state, (draft) => {
          draft.dataToEdit = {
            skuid: customProduct?.assembleProduct?.skuid,
            name: customProduct?.assembleProduct?.name,
            description: customProduct?.assembleProduct?.description,
            hsnCode: customProduct?.assembleProduct?.hsnCode,
            uom: customProduct?.assembleProduct?.uom,
            totalPrice: customProduct?.assembleProduct?.totalPrice,
            totalGst: customProduct?.assembleProduct?.totalGst,
            discount: customProduct?.assembleProduct?.discount,
            notes: customProduct?.assembleProduct?.notes,
            assembledProductId:
              customProduct?.assembleProduct?.assembledProductId,
            products: customProduct?.assembleProduct?.products
              ? customProduct?.assembleProduct?.products
              : [],
          };
          draft.isAssembledProductEdit = true;
        });
      }
      break;
    case ASSEMBLED_PRODUCT_EDIT_TRIGGER:
      {
    
        return produce(state, (draft) => {
          // draft.assembledProductDataToEdit = customProduct;
          draft.isAssembledProductEdit = true;
        });
      }
      break;

    case ASSEMBLED_PRODUCT_CREATE_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.dataToEdit = undefined;
          draft.assembledProductDataToEdit = undefined;
          draft.isAssembledProductEdit = false;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default configuratorReducer;
