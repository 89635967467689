import React from "react";
import { IconButton, SvgIcon, Button, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";

import { ConfirmDialog } from "components";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "utils/formatUtils";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleEditQuote,
  showBlock,
  handleBlock,
  viewClient,
  modalBlock,
  setModalBlock
) => [
  { field: "quoteId", headerName: "Quote Id", width: 120 },

  {
    field: "quoteDate",
    headerName: "Qoute Date",
    valueGetter: (params) => dateFormatter(params.row.quoteDate),
    width: 250,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: true,
  },
  {
    field: "tnc",
    headerName: "Terms And Condition",
    width: 200,
    editable: true,
  },
  {
    field: "supplierId",
    headerName: "Supplier Id",
    width: 120,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };
      // const navigate = useNavigate();
      return (
        <div className="cellAction">
          {/* edit button */}
          <Tooltip title={"Edit Customer"}>
            <IconButton
              onClick={() => handleEditQuote(params.row.quoteId)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* edit button */}
          <>
            {showDelete && params.row.quoteId && (
              <>
                <ConfirmDialog
                  title="Delete Customer"
                  description={`Do you want to permanently delete My Quote:${params.row.name}(${params.row.quoteId})? This action can't be undone`}
                  isOpen={modal === params.row.quoteId && params.row.quoteId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.row.quoteId);
                    setModal(false);
                    handleDelete(params.row.quoteId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <Tooltip title={"Delete Customer"}>
                  <IconButton
                    onClick={() => setModal(params.id)}
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
          {/* <>
            {showBlock && params.row.clientId && (
              <>
                <ConfirmDialog
                  title="Block Customer"
                  description={`Do you want to permanently block Customer:${params.row.name}(${params.row.clientId})? This action can't be undone`}
                  isOpen={
                    modalBlock === params.row.clientId && params.row.clientId
                  }
                  confirmAction={() => {
                    console.log("Block confirm: ", params.row.clientId);
                    setModalBlock(false);
                    handleBlock(params.row.clientId);
                  }}
                  cancelAction={() => setModalBlock(false)}
                />
                <Tooltip title={"Block Customer"}>
                  <IconButton onClick={() => setModalBlock(params.id)}>
                    <BlockSharpIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </> */}
          {/* <IconButton onClick={() => viewClient(params.row)}>
            <VisibilityIcon />
          </IconButton> */}
        </div>
      );
    },
  },
];
