import React from "react";
import { IconButton, SvgIcon, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfirmDialog } from "components";
import { moneyFormatter } from "../../../utils/formatUtils";

export const headCells = (showDelete, handleButtonClick, modal, setModal) => [
  {
    field: "description",
    headerName: "Description",
    renderCell: (params) => {
      return (
        <div
          style={{
            whiteSpace: "normal",
          }}
        >
          {params.value}
        </div>
      );
    },
    width: 350,
  },
  ,
  { field: "skuid", headerName: "SKU Id", width: 100 },
  {
    field: "type",
    headerName: "Category",
    width: 150,
    editable: true,
  },
  { field: "hsnCode", headerName: "HSN", width: 100 },
  {
    field: "price",
    headerName: "Price",
    valueGetter: (params) => moneyFormatter(params.row.totalPrice.mrp),
    sortable: false,
    width: 120,
  },
  {
    field: "salesPrice",
    headerName: "Sales Price",
    valueGetter: (params) => moneyFormatter(params.row.totalPrice.salesPrice),
    sortable: false,
    width: 120,
  },
  // { field: "qty", headerName: "Quantity", width: 100, type: "number" },
  { field: "uom", headerName: "UOM", width: 80 },
  {
    field: "totalGst",
    headerName: "Total GST",
    type: "number",
    width: 80,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}

          {/* <IconButton
            onClick={() => {
              // handleClick(params.row);
            }}
            disabled
          >
            <EditIcon />
          </IconButton> */}

          {/* edit button */}
          {/* <m>
            {showDelete && params.row.skuid && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this Item ,action can't be undone`}
                  isOpen={modal === params.row.skuid && params.row.skuid}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.skuid);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <IconButton onClick={() => setModal(params.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </m> */}
        </div>
      );
    },
  },
];
