import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "../AssignMemberList/utils";
import { Button, Grid, Card, CardContent, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { deletePojectMember } from "../../../../../actions/projectAction";

function AssignMemberList({ members, projectId }) {
  const [modal, setModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("Assign members", members);
  const handleClickMember = (serviceRow) => {
    // setServi(serviceRow);
    // setIsShown((current) => !current);
    navigate("/app/mgmt/project/create-member");
  };
  const handleViewDevice = (params) => {
    // logic to handle view device
  };

  const handleDeleteMember = (userId) => {
    // console.log("params", userId);
    dispatch(deletePojectMember(projectId, userId));
  };
  useEffect(() => {}, [members]);
  let isMemberExists = false;
  // if (members) {
  //   members.map((member) => {
  //     if (!member.isDeleted) {
  //       isMemberExists = true;
  //     }
  //   });
  // }
  return (
    <Card sx={{ boxShadow: 3, mb: 3, m: 2, p: 1 }}>
      <CardContent>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={6} md={8}>
            <Typography variant="h4">Project Members</Typography>
          </Grid>
          <Grid item xs={6} md={4} sx={{ textAlign: "end" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickMember}
            >
              <AddIcon />
              Assign Member
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ height: 300 }}>
          <DataGrid
            rows={members}
            columns={headCells(true, modal, setModal, handleDeleteMember)}
            getRowId={(row) => row.userId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
          />
        </Box>
      </CardContent>
    </Card>
  );
}
AssignMemberList.propTypes = {
  members: PropTypes.array,
};
export default AssignMemberList;
