import React from "react";
import { IconButton, SvgIcon, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfirmDialog } from "components";
import { moneyFormatter } from "../../../../../utils/formatUtils";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleEditProduct
) => [
  // { field: "buisnessId", headerName: "Business Id", width: 120 },
  {
    field: "assembledProductId",
    headerName: "Product Id",
    width: 120,
  },
  {
    field: "name",
    headerName: "Product Name",
    width: 250,    
  },
  {
    field: "skuid",
    headerName: "SKU",
    width: 200,
  },
  {
    field: "uom",
    headerName: "Unit Of Measure",
    width: 150,
  },
  {
    field: "price",
    headerName: "MRP",
    valueGetter: (params) => moneyFormatter(params.row.totalPrice.mrp),
    align: "right",
    sortable: false,
    width: 120,
  },
  {
    field: "salesPrice",
    headerName: "Sales Price",
    valueGetter: (params) => moneyFormatter(params.row.totalPrice.salesPrice),
    sortable: false,
    align: "right",
    width: 120,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}

          <IconButton
            onClick={() => {
              handleEditProduct(params.row);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>

          {/* edit button */}
          <m>
            {showDelete && params.row.assembledProductId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this Assembled ,action can't be undone`}
                  isOpen={
                    modal === params.row.assembledProductId &&
                    params.row.assembledProductId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.assembledProductId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <IconButton onClick={() => setModal(params.id)} color="primary">
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </m>
          {/* <Button onClick={()=>viewProject(params.row)}>
            <VisibilityIcon />
          </Button> */}
        </div>
      );
    },
  },
];
