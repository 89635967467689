import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddressContent from "../../../../../components/AddressContent";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { deleteRegisterAddress } from "../../../../../actions/registeredAddressAction";
import { fetchClient } from "../../../../../actions/clientAction";
import { useNavigate } from "react-router-dom";

const RegisterAddress = ({ onClick, registeredAddress }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
  };

  const clientEdit = useSelector((state) => state?.clientEdit);
  // console.log("clientEdit: ", clientEdit);
  const clientDataToEdit = clientEdit.clientDataToEdit;
  // console.log("clientDataToEdit: ", clientDataToEdit);

  const clientId = clientDataToEdit?.clientId ? clientDataToEdit?.clientId : [];
  // console.log("clientId: ", clientId);

  const handleClose = () => {
    dispatch(deleteRegisterAddress({ clientId: clientId }));
    setOpen();
    navigate("/app/mgmt/customer/my");
  };
  const handleCloseRegisteredAddressDelete = () => {
    setOpen();
  };

  return (
    <Grid>
      <Card sx={{ boxShadow: 3, mb: 3 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={10}>
            <CardHeader title={"Register Address"} />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={"Edit Address"}>
              <IconButton onClick={onClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Divider />
        {registeredAddress.label ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={10}>
              <CardContent>
                <AddressContent
                  label={registeredAddress.label}
                  address1={registeredAddress.address1}
                  address2={registeredAddress.address2}
                  state={registeredAddress.state}
                  city={registeredAddress.city}
                  country={registeredAddress.country}
                  pincode={registeredAddress.pincode}
                />
              </CardContent>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={"Delete Address"}>
                <IconButton onClick={handleClick}>
                  <DeleteIcon />
                </IconButton>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this Register Address ,action can't be undone`}
                  isOpen={open}
                  confirmAction={handleClose}
                  cancelAction={handleCloseRegisteredAddressDelete}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}
      </Card>
    </Grid>
  );
};

RegisterAddress.prototype = {
  onClick: PropTypes.func,
  registeredAddress: PropTypes.object,
};

export default RegisterAddress;
