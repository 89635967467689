class AddProduct {
  constructor(values, productId) {
    if (values) {
      this.productId = productId;
      this.businessId = values.businessId;
      this.description = values.description;
      this.type = values.productType;
      this.skuid = values.skuId;
      this.category = values.productCategory;
      this.name = values.name;
      this.notes = values.notes;
      this.hsnCode = values.hsnCode;
      this.uom = values.uom;
      this.brandName = values.brandName;
      this.brandPart = values.brandPart;
      this.size = values.size;
      this.price = {
        mrp: values.mrp,
        rate: values.priceRate,
        salesPrice: values.salesPrice,
      };
      this.cost = {
        rate: values.costRate,
      };
      this.gst = values.gst;
    }
  }
}
class AddAssembleProduct {
  constructor(values, assembledProductId) {
    if (values) {
      this.assembledProductId = assembledProductId;
      this.businessId = values.businessId;
      // this.type = values.productType;
      this.skuid = values.skuId;
      // this.category = values.productCategory;
      this.name = values.name;
      this.description = values.description;
      this.hsnCode = values.hsnCode;
      this.uom = values.uom;
      this.discount = values.discount;
      this.notes = values.notes;
    }
  }
}
class HoseAssembleProduct {
  constructor(values) {
    if (values) {
      this.businessId = values.businessId;
      this.adaptor = values.adaptors;
      this.adptQty = values.adptQty;
      this.amount = values.amount;
      this.name = values.description;
      this.fittingA = values.fittingA;
      this.fittingB = values.fittingB;
      this.hosePipe = values.hoseName      ;
      this.length = values.length;
      this.skuid = values.materialCode;
      this.sleeves = values.sleeves;
      this.hsnCode = values.hsnCode;
      // this.quoteId = values.quoteId;
      // this.skuid = values.skuid;
      // this.qty = values.qty;
      // this.discount = values.discount;
    }
  }
}

export default { AddProduct };
export { AddProduct, AddAssembleProduct, HoseAssembleProduct };
