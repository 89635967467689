import React from "react";
import { IconButton, SvgIcon, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfirmDialog } from "components";

export const headCells = (
  showDelete,
  handleDelete,
  viewProject,
  modal,
  setModal,
  handleEditProject
) => [
  { field: "projectId", headerName: "ID", width: 80 },
  {
    field: "projectName",
    headerName: "Project name",
    width: 200,
    editable: true,
  },
  {
    field: "projectManager",
    headerName: "Manager",
    width: 100,
    editable: true,
  },
  {
    field: "regions",
    headerName: "Region",
    type: "number",
    width: 200,
    editable: true,
  },
  {
    field: "projectCode",
    headerName: "Project Code",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 200,
    // valueGetter: (params) =>
    //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };

      return (
        <div className="cellAction">
          {/* edit button */}

          <IconButton
            onClick={() => {
              handleEditProject(params.row);
            }}
          >
            <EditIcon />
          </IconButton>

          {/* edit button */}
          <m>
            {showDelete && params.row.projectId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete this project,action can't be undone`}
                  isOpen={modal === params.row.projectId && params.row.projectId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.id);
                    setModal(false);
                    handleDelete(params.row.projectId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <IconButton onClick={() => setModal(params.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </m>
          <Button onClick={() => viewProject(params.row)}>
            <VisibilityIcon />
          </Button>
        </div>
      );
    },
  },
];
