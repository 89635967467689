import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  CardHeader,
  Divider,
} from "@mui/material";

import PropTypes from "prop-types";

import ColorlibConnector from "./components/ColorlibConnector ";
import ColorlibStepIcon from "./components/ColorlibStepIcon";

const CustomizedStepper = ({ steps, handleNext, handleBack,activeStep ,setActiveStep}) => {
  // const [activeStep, setActiveStep] = React.useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isLastStep = activeStep === steps.length - 1;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={12}>
        <Card
          sx={{
            width: "100%",
            overflowX: { xs: "scroll", sm: "hidden", md: "hidden" },
          }}
        >
          <CardContent>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel
                    sx={{
                      ".MuiStepLabel-labelContainer span": { fontSize: "10px" },
                    }}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    {label.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <React.Fragment>
          <Card>
            <CardHeader
              titleTypographyProps={{ variant: "body1" }}
              title={steps[activeStep].label}
            />
            <Divider />
            <CardContent>{steps[activeStep].data}</CardContent>
          </Card>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              disabled={isLastStep}
              onClick={handleNext}
              variant="contained"
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box> */}
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

CustomizedStepper.propTypes = {
  steps: PropTypes.func,
};

export default CustomizedStepper;
