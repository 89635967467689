import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { TextField, Button, Container, Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { BUSINESS_ID } from "../../../../../config";

const ProfileEditForm = ({ data, onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessId: BUSINESS_ID,
        userId: data ? data.userId : "",
        name: data ? data.name : "",
        mobile: data ? data.mobile : "",
        email: data ? data.email : "",
        userType: data ? data.userType : "",
      }}
      validationSchema={Yup.object().shape({
        // userId: Yup.number(),
        name: Yup.string().max(80),
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitSuccess(navigate("/profile"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="userType"
                  name="userType"
                  label="User Type"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={values.userType}
                  onChange={handleChange}
                  error={touched.userType && Boolean(errors.userType)}
                  helperText={touched.userType && errors.userType}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  error={Boolean(touched.mobile && errors.mobile)}
                  fullWidth
                  helperText={touched.mobile && errors.mobile}
                  label="Enter Mobile Number"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 10 }}
                  required
                  value={values.mobile}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  type="email"
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled
                >
                  Edit Profile
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2} mt={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/profile")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default ProfileEditForm;
