import catalogService from "services/catalogService";
import {
  AddProduct,
  AddAssembleProduct,
  HoseAssembleProduct,
} from "../interface/catalogProductIf";

export const PRODUCT_FETCH = "@product/fetch";
export const PRODUCT_FETCH_SUCCESS = "@product/fetch-success";
export const PRODUCT_FETCH_FAILURE = "@product/fetch-failure";

export const CUSTOM_PRODUCT_FETCH = "@custom-product/fetch";
export const CUSTOM_PRODUCT_FETCH_SUCCESS = "@custom-product/fetch-success";
export const CUSTOM_PRODUCT_FETCH_FAILURE = "@custom-product/fetch-failure";

export const FETCH_CUSTOM_PRODUCT_BY_ID = "@productById/fetch";
export const FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS = "@productById/fetch-success";
export const FETCH_CUSTOM_PRODUCT_BY_ID_FAILURE = "@productById/fetch-failure";

export const ADD_CONFIG_PRODUCT = "@custom-product/add";
export const ADD_CONFIG_PRODUCT_SUCCESS = "@custom-product/add-success";
export const ADD_CONFIG_PRODUCT_FAILURE = "@custom-product/add-failure";

export const ADD_PRODUCT = "@product/add";
export const ADD_PRODUCT_SUCCESS = "@product/add-success";
export const ADD_PRODUCT_FAILURE = "@product/add-failure";

export const CREATE_ASSEMBLE_PRODUCT = "@assemble/create";
export const CREATE_ASSEMBLE_PRODUCT_SUCCESS = "@assemble/create-success";
export const CREATE_ASSEMBLE_PRODUCT_FAILURE = "@assemble/create-failure";

export const CREATE_HOSE_ASSEMBLE = "@hose-assemble/create";
export const CREATE_HOSE_ASSEMBLE_SUCCESS = "@hose-assemble/create-success";
export const CREATE_HOSE_ASSEMBLE_FAILURE = "@hose-assemble/create-failure";

export const DELETE_PRODUCT = "@product/delete";
export const DELETE_PRODUCT_SUCCESS = "@product/delete-success";
export const DELETE_PRODUCT_FAILURE = "@product/delete-failure";

export const REMOVE_ASSEMBLED_PRODUCT = "@product/remove";
export const REMOVE_ASSEMBLED_PRODUCT_SUCCESS = "@product/remove-success";
export const REMOVE_ASSEMBLED_PRODUCT_FAILURE = "@product/remove-failure";

export const DELETE_ASSEMBLED_PRODUCT = "@assembled/delete";
export const DELETE_ASSEMBLED_PRODUCT_SUCCESS = "@assembled/delete-success";
export const DELETE_ASSEMBLED_PRODUCT_FAILURE = "@assembled/delete-failure";

export const PRODUCT_UPDATE = "@product/update";
export const PRODUCT_UPDATE_SUCCESS = "@product/update-success";
export const PRODUCT_UPDATE_FAILURE = "@product/update-failure";

export const ASSEMBLED_PRODUCT_UPDATE = "@assembled-product/update";
export const ASSEMBLED_PRODUCT_UPDATE_SUCCESS =
  "@assembled-product/update-success";
export const ASSEMBLED_PRODUCT_UPDATE_FAILURE =
  "@assembled-product/update-failure";

export const PRODUCT_EDIT_TRIGGER = "@product/edit-trigger";
export const PRODUCT_CREATE_TRIGGER = "@product/create-trigger";

export const ASSEMBLED_PRODUCT_EDIT_TRIGGER = "@assembled-product/edit-trigger";
export const ASSEMBLED_PRODUCT_CREATE_TRIGGER =
  "@assembled-product/create-trigger";

export const FETCH_HOSE_ASSEMBLY_BY_CRITERIA = "@catalog/fetch-criteria";
export const FETCH_HOSE_ASSEMBLY_BY_CRITERIA_SUCCESS =
  "@catalog/fetch-criteria-success";
export const FETCH_HOSE_ASSEMBLY_BY_CRITERIA_FAILURE =
  "@catalog/fetch-criteria-failure";

export const FETCH_TOTAL_AMOUNT = "@catalog/fetch-totalAmount";
export const FETCH_TOTAL_AMOUNT_SUCCESS = "@catalog/fetch-totalAmount-success";
export const FETCH_TOTAL_AMOUNT_FAILURE = "@catalog/fetch-totalAmountfailure";

export const UPLOAD_PRODUCTS = "@catalog/upload-products";
export const UPLOAD_PRODUCTS_SUCCESS = "@catalog/upload-products-success";
export const UPLOAD_PRODUCTS_FAILURE = "@catalog/upload-productst-failure";

export function fetchProduct() {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_FETCH,
      });
      const products = await catalogService.fetchProduct();

      // console.log("productsAction.fetchProduct Resp:", products);

      dispatch({
        type: PRODUCT_FETCH_SUCCESS,
        payload: {
          products,
        },
      });
      return products;
    } catch (error) {
      // console.log("productsAction.fetchProduct: Error", error);
      dispatch({ type: PRODUCT_FETCH_FAILURE });
      throw error;
    }
  };
}
export function fetchCustomProduct() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CUSTOM_PRODUCT_FETCH,
      });
      const customProducts = await catalogService.fetchCustomProduct();

      // console.log("productsAction.fetchCustomProduct Resp:", customProducts);

      dispatch({
        type: CUSTOM_PRODUCT_FETCH_SUCCESS,
        payload: {
          customProducts,
        },
      });
    } catch (error) {
      // console.log("productsAction.fetchCustomProduct: Error", error);
      dispatch({ type: CUSTOM_PRODUCT_FETCH_FAILURE });
      throw error;
    }
  };
}

export function addProduct(values) {
  // console.log("values", values);
  return async (dispatch) => {
    try {
      const newProductInfo = new AddProduct(values, undefined);
      dispatch({
        type: ADD_PRODUCT,
        payload: {
          newProductInfo,
        },
      });
      // console.log("productsAction.addProduct:", newProductInfo);

      const resp = await catalogService.addProduct(newProductInfo);

      // console.log("productsAction.addProduct: Resp:", resp);

      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProduct());

      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADD_PRODUCT_FAILURE });
      throw error;
    }
  };
}
export function deleteProduct(productId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PRODUCT,
        payload: {
          productId,
        },
      });
      // console.log("productsAction.deleteProduct:", productId);

      const products = await catalogService.deleteProduct(productId);

      // console.log("productsAction.deleteProduct Resp:", products);
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: {
          products,
        },
      });
      await dispatch(fetchProduct());
    } catch (error) {
      // console.log("productsAction.deleteProduct: Error", error);
      dispatch({ type: DELETE_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function updateProducts(productId, values) {
  return async (dispatch) => {
    try {
      const newProductInfo = new AddProduct(values, productId);

      dispatch({
        type: PRODUCT_UPDATE,
        payload: {
          newProductInfo,
        },
      });
      // console.log("catalogAction.updateProducts:", newProductInfo);

      const resp = await catalogService.updateProducts(newProductInfo);

      // console.log("catalogAction.updateProducts Resp:", resp);

      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchProduct());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateProducts: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PRODUCT_UPDATE_FAILURE });
      throw error;
    }
  };
}
export function editProductTrigger(productEdit) {
  return async (dispatch) => {
    // console.log("editproductTrigger() ", productEdit);
    dispatch({
      type: PRODUCT_EDIT_TRIGGER,
      payload: { productEdit },
    });
  };
}

export function createProductTrigger() {
  return async (dispatch) => {
    dispatch({
      type: PRODUCT_CREATE_TRIGGER,
      payload: {},
    });
  };
}

export function createAssembleProduct(values) {
  // console.log("values", values);
  return async (dispatch) => {
    try {
      const newAssembleInfo = new AddAssembleProduct(values, undefined);
      dispatch({
        type: CREATE_ASSEMBLE_PRODUCT,
        payload: {
          newAssembleInfo,
        },
      });
      // console.log("productsAction.createAssembleProduct:", newAssembleInfo);

      const payload = await catalogService.createAssembleProduct(
        newAssembleInfo
      );
      const assembledProduct = payload?.assembledProduct;

      // console.log(
      //   "productsAction.createAssembleProduct: Resp:",
      //   assembledProduct
      // );

      dispatch({
        type: CREATE_ASSEMBLE_PRODUCT_SUCCESS,
        payload: {
          assembledProduct,
        },
      });
      // await dispatch(fetchProduct());

      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CREATE_ASSEMBLE_PRODUCT_FAILURE });
      throw error;
    }
  };
}
export function createHoseAssembly(values) {
  // console.log("values", values);
  return async (dispatch) => {
    try {
      const newAssembleInfo = new HoseAssembleProduct(values, undefined);
      dispatch({
        type: CREATE_HOSE_ASSEMBLE,
        payload: {
          newAssembleInfo,
        },
      });
      // console.log("catalogAction.createHoseAssembly:", newAssembleInfo);

      const payload = await catalogService.createHoseAssembly(newAssembleInfo);
      // console.log("catalogAction.createHoseAssembly: payload:", payload);
      const assembledProduct = payload?.assembledProduct;

      // console.log("catalogAction.createHoseAssembly: Resp:", assembledProduct);

      dispatch({
        type: CREATE_HOSE_ASSEMBLE_SUCCESS,
        payload: {
          assembledProduct,
        },
      });
      // await dispatch(fetchProduct());

      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CREATE_HOSE_ASSEMBLE_FAILURE });
      throw error;
    }
  };
}

export function updateAssembledProducts(assembledProductId, values) {
  return async (dispatch) => {
    try {
      const newAssembleInfo = new AddAssembleProduct(
        values,
        assembledProductId
      );

      dispatch({
        type: ASSEMBLED_PRODUCT_UPDATE,
        payload: {
          newAssembleInfo,
        },
      });
      // console.log("catalogAction.updateAssembledProducts:", newAssembleInfo);

      const customProduct = await catalogService.updateAssembledProducts(
        newAssembleInfo
      );

      // console.log("catalogAction.updateAssembledProducts Resp:", customProduct);

      dispatch({
        type: ASSEMBLED_PRODUCT_UPDATE_SUCCESS,
        payload: {
          customProduct,
        },
      });
      await dispatch(fetchCustomProduct());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateProducts: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ASSEMBLED_PRODUCT_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function editAssembledProductTrigger(customProduct) {
  return async (dispatch) => {
    // console.log("editAssembledProductTrigger() ", customProduct);
    dispatch({
      type: ASSEMBLED_PRODUCT_EDIT_TRIGGER,
      payload: { customProduct },
    });
  };
}

export function createAssembledProductTrigger() {
  return async (dispatch) => {
    dispatch({
      type: ASSEMBLED_PRODUCT_CREATE_TRIGGER,
      payload: {},
    });
  };
}
export function deleteAssembleProduct(assembledProductId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_ASSEMBLED_PRODUCT,
        payload: {
          assembledProductId,
        },
      });
      // console.log("productsAction.deleteAssembleProduct:", assembledProductId);

      const assembledProduct = await catalogService.deleteAssembleProduct(
        assembledProductId
      );

      // console.log("productsAction.deleteAssembleProduct Resp:", products);
      dispatch({
        type: DELETE_ASSEMBLED_PRODUCT_SUCCESS,
        payload: {
          assembledProduct,
        },
      });
      await dispatch(fetchCustomProduct());
    } catch (error) {
      // console.log("productsAction.deleteAssembleProduct: Error", error);
      dispatch({ type: DELETE_ASSEMBLED_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function addConfiguratorProduct(
  productId,
  assembledProductId,
  quantity
) {
  // console.log(
  //   "productid and assembled product id",
  //   productId,
  //   assembledProductId,
  //   quantity
  // );
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_CONFIG_PRODUCT,
        payload: {
          productId,
          assembledProductId,
          quantity,
        },
      });
      // console.log(
      //   "productsAction.addConfiguratorProduct:",
      //   productId,
      //   assembledProductId,
      //   quantity
      // );

      const payload = await catalogService.addConfiguratorProduct(
        productId,
        assembledProductId,
        quantity
      );
      const assembledProduct = payload?.assembledProduct;
      // console.log(
      //   "productsAction.addConfiguratorProduct Resp:",
      //   assembledProduct
      // );
      dispatch({
        type: ADD_CONFIG_PRODUCT_SUCCESS,
        payload: {
          assembledProduct,
        },
      });
      // await dispatch(fetchProduct());
      await dispatch(fetchProduct());
    } catch (error) {
      // console.log("productsAction.addConfiguratorProduct: Error", error);
      dispatch({ type: ADD_CONFIG_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function removeAssembleProduct(productId, assembledProductId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_ASSEMBLED_PRODUCT,
        payload: {
          productId,
        },
      });
      // console.log(
      //   "catalogAction.removeAssembleProduct:",
      //   productId,
      //   assembledProductId
      // );

      const payload = await catalogService.removeAssembleProduct(
        productId,
        assembledProductId
      );
      const assembledProduct = payload?.assembledProduct;

      // console.log(
      //   "catalogAction.removeAssembleProduct Resp:",
      //   assembledProduct
      // );
      dispatch({
        type: REMOVE_ASSEMBLED_PRODUCT_SUCCESS,
        payload: {
          assembledProduct,
        },
      });
      await dispatch(fetchProduct());
    } catch (error) {
      // console.log("catalogAction.removeAssembleProduct: Error", error);
      dispatch({ type: REMOVE_ASSEMBLED_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function fetchCustomProductBySkuId(skuId) {
  // console.log("skuId IN catalogAction", projectId)
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_CUSTOM_PRODUCT_BY_ID,
        payload: {
          skuId,
        },
      });
      // console.log("catalogAction.fetchProjectDetail:");
      const customProduct = await catalogService.fetchCustomProductBySkuId(
        skuId
      );

      // console.log("catalogAction.fetchProjectDetail Resp:", projectDetails);

      dispatch({
        type: FETCH_CUSTOM_PRODUCT_BY_ID_SUCCESS,
        payload: {
          customProduct,
        },
      });
    } catch (error) {
      // console.log("catalogAction.fetchProductBySkuId: Error", error);
      dispatch({ type: FETCH_CUSTOM_PRODUCT_BY_ID_FAILURE });
      throw error;
    }
  };
}
export function fetchHoseAssemblyByCriteria(skuid) {
  // console.log("fetchHoseAssemblyByCriteria IN Action ====>>>>", skuid);
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_HOSE_ASSEMBLY_BY_CRITERIA,
        payload: {
          skuid,
        },
      });

      const requestList = await catalogService.fetchHoseAssemblyByCriteria(skuid);

      // console.log(
      //   "quoteService.fetchHoseAssemblyByCriteria Resp:",
      //   requestList
      // );

      dispatch({
        type: FETCH_HOSE_ASSEMBLY_BY_CRITERIA_SUCCESS,
        payload: {
          requestList,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_HOSE_ASSEMBLY_BY_CRITERIA_FAILURE });
      throw error;
    }
  };
}
export function CalculateTotalAmount(
  hoseName,
  length,
  fittingA,
  fittingB,
  sleeves,
  adaptor,
  adptQty,
  customerCategory
) {
  // console.log(
  //   "values",
  //   hoseName,
  //   length,
  //   fittingA,
  //   fittingB,
  //   sleeves,
  //   adaptor,
  //   adptQty
  // );
  return async (dispatch) => {
    try {
      // const newProductInfo = new AddProduct(values, undefined);
      dispatch({
        type: FETCH_TOTAL_AMOUNT,
        payload: {
          hoseName,
          length,
          fittingA,
          fittingB,
          sleeves,
          adaptor,
          adptQty,
          customerCategory
        },
      });
      // console.log(
      //   "productsAction.CalculateTotalAmount:",
      //   hoseName,
      //   length,
      //   fittingA,
      //   fittingB,
      //   sleeves,
      //   adaptor,
      //   adptQty
      // );

      const totalAmount = await catalogService.CalculateTotalAmount(
        hoseName,
        length,
        fittingA,
        fittingB,
        sleeves,
        adaptor,
        adptQty,
        customerCategory
      );

      // console.log("productsAction.CalculateTotalAmount: Resp:", totalAmount);

      dispatch({
        type: FETCH_TOTAL_AMOUNT_SUCCESS,
        payload: {
          totalAmount,
        },
      });
      // await dispatch(fetchProduct());
      return totalAmount;
      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: FETCH_TOTAL_AMOUNT_FAILURE });
      throw error;
    }
  };
}
export function uploadProducts(payload) {
  // console.log("values", payload);
  return async (dispatch) => {
    try {
      // const newProductInfo = new AddProduct(values, undefined);
      dispatch({
        type: UPLOAD_PRODUCTS,
        payload: { payload },
      });
      // console.log("productsAction.uploadProducts:", payload);

      const resp = await catalogService.uploadProducts(payload);

      // console.log("productsAction.uploadProducts: Resp:", resp);

      dispatch({
        type: UPLOAD_PRODUCTS_SUCCESS,
        payload: {
          resp,
        },
      });
      // await dispatch(fetchProduct());

      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: UPLOAD_PRODUCTS_FAILURE });
      throw error;
    }
  };
}
