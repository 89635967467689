import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import PropTypes from "prop-types";

import {
  Button,
  IconButton,
  SvgIcon,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { deletePojectDevice } from "../../../../../actions/projectAction";

function AllocateDeviceList({ devices,projectId }) {
  const [modal, setModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("allocate devices", devices);
  const handleClickDevice = (serviceRow) => {
    // setServi(serviceRow);
    // setIsShown((current) => !current);
    navigate("/app/mgmt/project/allocate-device");
  };
  const handleViewDevice = (params) => {
    // logic to handle view device
  };

  const handleDeleteDevice = (deviceId) => {
    // console.log("device data",deviceId);
    dispatch(deletePojectDevice(projectId,deviceId));
  };
  useEffect(() => {}, [devices]);
  return (
    <Card sx={{ boxShadow: 3, mb: 3, m: 2, p: 1 }}>
      <CardContent>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={6} md={8}>
            <Typography variant="h4">Project Devices</Typography>
          </Grid>
          <Grid item xs={6} md={4} sx={{ textAlign: "end" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickDevice}
            >
              <AddIcon />
              Allocate Device
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ height: 300 }}>
          <DataGrid
            rows={devices}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 20]}
            columns={headCells(true, modal, setModal, handleDeleteDevice)}
            getRowId={(row) => row.deviceId}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
AllocateDeviceList.propTypes = {
  devices: PropTypes.array,
};
export default AllocateDeviceList;
