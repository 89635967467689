class UserProfile {
  constructor(userInfo) {
    if (userInfo) {
      this.email = userInfo.email;
      this.isActive = userInfo.isActive;
      this.mobile = userInfo.mobile;
      this.name = userInfo.name;
      this.userType = userInfo.userType;
      this.userId = userInfo.userId;
      this.lastLoggedIn = userInfo.lastLoggedIn;
    }
  }
}
class AddUser {
  constructor(values) {
    if (values) {
      this.name = values.name;
      this.mobile = values.mobile;
      this.email = values.email;
      this.userType = values.userType;
      this.password = values.password;
      this.businessId = values.businessId;
    }
  }
}
class AddDiscount {
  constructor(values, discountId) {
    if (values) {
      this.discountId = discountId;
      this.businessId = values.businessId;
      this.productCategory = values.productCategory;
      this.customerCategory = values.customerCategory;
      this.discount = values.discount;
    }
  }
}

export default { UserProfile };
export { UserProfile, AddUser, AddDiscount };
