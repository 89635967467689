import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  Autocomplete,
} from "@mui/material";
import { CUSTOMER_CATEGORY, STATE } from "config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createClient,
  editClientTrigger,
  updateClient,
} from "../../../../../actions/clientAction";

import { BUSINESS_ID } from "../../../../../config";
import { useNavigate } from "react-router-dom";

const CustomerForm = ({
  onSubmitSuccess,
  data,
  isClientEdit,
  isClientView,
  isClientProfile,
}) => {
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state);

  const navigate = useNavigate();

  // console.log("data=>>>>> ", data);
  // console.log("SignInEmail: auth", auth, " user: ",name);
  // const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        clientId: data ? data.clientId : "",
        businessId: BUSINESS_ID,
        name: data ? data.name : "",
        contact: data?.contact ? data?.contact : "",
        email: data?.email ? data?.email : "",
        contactPerson: data?.contactPerson ? data?.contactPerson : "",
        gstin: data?.gstin ? data?.gstin : "",
        pan: data?.pan ? data?.pan : "",
        notes: data?.notes ? data?.notes : "",
        category: data?.category ? data?.category : "",
        state: data?.state ? data?.state : "",
      }}
      validationSchema={Yup.object({
        // clientId: Yup.string(),
        businessId: Yup.string(),
        name: Yup.string().required("Name is required").max(80),
        contact: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),
        email: Yup.string()
          .email()
          .min(5)
          .max(80)
          .required("Please enter valid email address"),
        contactPerson: Yup.string().min(5).max(80),
        gstin: Yup.string().min(1).max(15),
        pan: Yup.string().min(1).max(10),
        notes: Yup.string().min(1).max(200),
        // category: Yup.string().min(5).max(80),
        state: Yup.string().min(3).max(80),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, isClientProfile }
      ) => {
        try {
          if (data) {
            await dispatch(updateClient(values));
          } else {
            await dispatch(createClient(values));
          }
          onSubmitSuccess(navigate("/app/mgmt/customer/my"));
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
      // onSubmit={(values) => {
      //   console.log("Form: submit", values)
      // }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  required
                  label="Business Name"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contactPerson"
                  id="contactPerson"
                  label="Contact Person"
                  type="string"
                  required
                  value={values.contactPerson}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.contactPerson && errors.contactPerson)}
                  helperText={touched.contactPerson && errors.contactPerson}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={Boolean(touched.contact && errors.contact)}
                  fullWidth
                  helperText={touched.contact && errors.contact}
                  label="Mobile Number"
                  name="contact"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 10 }}
                  // required
                  value={values.contact}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  type="email"
                  required
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="gstin"
                  id="gstin"
                  label="GST Number"
                  type="string"
                  value={values.gstin}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.gstin && errors.gstin)}
                  helperText={touched.gstin && errors.gstin}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  name="pan"
                  id="pan"
                  label="Pancard Number"
                  type="string"
                  value={values.pan}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.pan && errors.pan)}
                  helperText={touched.pan && errors.pan}
                />
              </Grid> */}
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  value={values.category}
                  onChange={(event, newValue) => {
                    setFieldValue("category", newValue);
                  }}
                  disablePortal
                  id="category"
                  options={CUSTOMER_CATEGORY}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Category"
                        required
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="notes"
                  id="notes"
                  label="Note"
                  type="string"
                  multiline
                  rows={3}
                  value={values.notes}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 80 }}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  value={values.state}
                  onChange={(event, newValue) => {
                    setFieldValue("state", newValue);
                  }}
                  disablePortal
                  id="state"
                  options={STATE}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField {...params} label="State" />
                    </>
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={6}>
                {/* {data ? (
                  <Button variant="contained" color="primary" type="submit">
                    {isClientEdit ? "Update Customer" : "Create Customer"}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" type="submit">
                     {isClientView === "Back"}
                  </Button>
                )} */}
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => navigate("/app/mgmt/customer/all")}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {isClientEdit ? "Update Customer" : "Create Customer"}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default CustomerForm;
