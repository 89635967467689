import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  SvgIcon,
  Card,
  Paper,
  Autocomplete,
} from "@mui/material";
import { STATE} from "config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createUsers } from "../../../../../actions/usersAction";

const AddressForm = ({ onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const name = auth.user.name;

  // console.log("SignInEmail: auth", auth, " user: ",name);
  const [isShown, setIsShown] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        lable: "",
        address1: "",
        address2: "",
        city: "",
        state:"",
        country: "india",
        pincode: "",
      }}
      validationSchema={Yup.object().shape({
        lable: Yup.string().required("Name is required").max(80),
        address1: Yup.string().required("Name is required").max(80),
        address2: Yup.string().required("Name is required").max(80),
        city: Yup.string().required("Name is required").max(80),
        state: Yup.string().required("Name is required").max(80),
        country: Yup.string().required("Name is required").max(80),
        country: Yup.string().required("Name is required").max(80),
        pincode: Yup.string().required("User Type is required").max(100),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(createUsers(values));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="lable"
                  name="lable"
                  label="Address lable"
                  value={values.lable}
                  onChange={handleChange}
                  error={touched.lable && Boolean(errors.lable)}
                  helperText={touched.lable && errors.lable}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="address1"
                  name="address1"
                  label="Address1"
                  multiline
                  rows={4}
                  value={values.address1}
                  onChange={handleChange}
                  error={touched.address1 && Boolean(errors.address1)}
                  helperText={touched.address1 && errors.address1}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="address2"
                  name="address2"
                  label="Address2"
                  multiline
                  rows={4}
                  value={values.address2}
                  onChange={handleChange}
                  error={touched.address2 && Boolean(errors.address2)}
                  helperText={touched.address2 && errors.address2}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  fullWidth
                  value={values.state}
                  onChange={(event, newValue) => {
                    setFieldValue("state", newValue);
                  }}
                  disablePortal
                  id="serviceType"
                  options={STATE}
                  required
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField {...params} label="State" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="country"
                  name="country"
                  label="Country"
                  value={values.country}
                  onChange={handleChange}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  error={touched.pincode && Boolean(errors.pincode)}
                  helperText={touched.pincode && errors.pincode}
                />
                
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // disabled={!isValid}
                >
                  Create Address
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default AddressForm;
