import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardContent,
  Button,
  SvgIcon,
  Grid,
  Box,
  Typography,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import AllocateDeviceList from "./AllocateDeviceList/AllocateDeviceList";
import AssignMemberList from "./AssignMemberList/AssignMemberList";

const ViewProjectDetail = () => {
  const projectDetail = useSelector((state) => state.projectDetail);
  const projectInfo = projectDetail?.projectDetails?.projectInfo;
  // console.log("projectDetails veiw project", projectDetail?.projectDetails);
  // console.log("projectInfo veiw project", projectInfo);
  const members = projectInfo?.members ? projectInfo?.members : [];
  const devices = projectInfo?.devices ? projectInfo?.devices : [];
  const projectId = projectInfo?.projectId;
  const navigate = useNavigate();
  // const handleClick = (serviceRow) => {
  //   // setServi(serviceRow);
  //   // setIsShown((current) => !current);
  //   navigate("/app/mgmt/project/create-member");
  // };

  const handleClickBack = () => {
    navigate("/app/mgmt/project/all");
  };

  useEffect(() => {}, [projectInfo, members, devices]);

  return (
    <Grid
      container
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid item>
        <Button
          onClick={handleClickBack}
          color="primary"
          variant="contained"
          sx={{ mt: 4 }}
        >
          Go back to project list
        </Button>

        <Card sx={{ boxShadow: 3, mb: 3, m: 2, p: 2, width: 950 }}>
          <CardContent>
            <Grid item xs={6} md={8}>
              <Typography variant="h4">Project Info</Typography>
            </Grid>
            <Divider />
            <Box sx={{ height: "auto", mt: 3 }}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Project ID:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.projectId}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Project Name:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.projectName}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Project Code:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.projectCode}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Project Manager:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.projectManager}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Region:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.regions}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={4}>
                    <Typography> Description:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{projectInfo?.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <AssignMemberList
          members={members?.filter((row) => row?.isDeleted == false)}
          projectId={projectId}
        />
        <AllocateDeviceList
          devices={devices?.filter((row) => row?.isDeleted == false)}
          projectId={projectId}
        />
      </Grid>
    </Grid>
  );
};

export default ViewProjectDetail;
