import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { useNavigate } from "react-router-dom";
import {
  fetchMyQuoteList,
  getQuoteByIdToUpdate,
  removeMyQuotes,
} from "../../../actions/quoteActions";

function MyQuoteList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myQuoteList = useSelector((state) => state.quote.myQuoteList);
  // console.log("quoteList : ", myQuoteList);
  const [modalBlock, setModalBlock] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [servi, setServi] = useState({});

  useEffect(() => {}, [myQuoteList]);

  useEffect(() => {
    dispatch(fetchMyQuoteList());
  }, []);

  // const handleDelete = (quoteId) => {
  //   // console.log("clientId", quoteId);
  //   dispatch(removeMyQuotes(quoteId));
  // };
  const handleDelete = async (quoteId) => {
    await dispatch(removeMyQuotes(quoteId));
  };
  const handleEditQuote = (data) => {
    dispatch(getQuoteByIdToUpdate(data));
    navigate("/quote-create");
  };

  // const handleUpdate = (data) => {
  //   dispatch(editClientTrigger(data));
  //   navigate("/app/mgmt/customer/new-customer");
  // };

  // const handleBlock = (clientId) => {
  //   console.log("clientId", clientId);
  //   dispatch(blockClient(clientId));
  // };
  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={myQuoteList}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleEditQuote,
          // true,
          // handleBlock,
          // viewClient,
          modalBlock,
          setModalBlock
        )}
        getRowId={(row) => row.quoteId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default MyQuoteList;
