import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { Button, Grid, Card, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Delete } from "react-feather";
import {
  editQuoteTrigger,
  fetchQuoteByCriteria,
  getQuoteByIdToUpdate,
  quoteGetById,
  removeQuote,
} from "actions/quoteActions";
// import { removeUser } from "../../../../actions/usersAction";
import { Pagination, PaginationItem, Typography, Stack } from "@mui/material";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import SearchBox from "./SearchBox";
import { updateQuoteStatus } from "../../../actions/quoteActions";

function AllQuotationList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quoteList = useSelector((state) => state.quote.quoteList);
  // console.log("quoteList : ", quoteList);
  const requestList = useSelector((state) => state.quote?.requestList.result);
  // console.log("requestList by criteria : ", requestList);
  const page = useSelector((state) => state.quote?.page);
  const criteria = useSelector((state) => state.quote?.criteria);
  // console.log(" criteria: ", criteria);
  // console.log(" page: ", page);

  const totalRecords = useSelector(
    (state) => state.quote?.requestList?.totalRecords
  );

  const totalPages = Math.ceil(totalRecords / 25);
  const [isShown, setIsShown] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalApproved, setModalApproved] = useState(false);
  const [modalSubmitted, setModalSubmitted] = useState(false);
  const [modalAccepted, setModalAccepted] = useState(false);
  const [modalOrdered, setModalOrdered] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [servi, setServi] = useState({});

  const viewQuote = (data) => {
    // console.log("handle view project", data);
    dispatch(quoteGetById(data));
    navigate("/download-pdf");
    // navigate("/app/mgmt/project/project-details");
  };
  const viewAppendixFile = (data) => {
    // console.log("handle view project", data);
    dispatch(quoteGetById(data));
    navigate("/download-appendix");
    // navigate("/app/mgmt/project/project-details");
  };

  // useEffect(() => {
  //   dispatch(fetchQuoteByCriteria(page));
  // }, [dispatch, page]);

  const handleNextPage = () => {
    dispatch(
      fetchQuoteByCriteria(
        criteria?.supplierId,
        criteria?.quoteId,
        criteria?.qouteDate,
        criteria?.status,
        page + 1
      )
    );
  };
  const handlePrevPage = () => {
    dispatch(
      fetchQuoteByCriteria(
        criteria?.supplierId,
        criteria?.quoteId,
        criteria?.qouteDate,
        criteria?.status,
        page - 1
      )
    );
  };

  useEffect(() => {}, [requestList]);
  // useEffect(() => {
  //   try {
  //     dispatch(fetchQuoteList(page));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  const handleApproved = (quoteId) => {
    dispatch(updateQuoteStatus({ quoteId, newStatus: "READY" }));
  };
  const handleSubmitted = (quoteId) => {
    dispatch(updateQuoteStatus({ quoteId, newStatus: "SUBMITTED" }));
  };
  const handleAccepted = (quoteId) => {
    dispatch(updateQuoteStatus({ quoteId, newStatus: "ACCEPTED" }));
  };
  const handleOrdered = (quoteId) => {
    dispatch(updateQuoteStatus({ quoteId, newStatus: "ORDER" }));
  };
  const handleCancel = (quoteId) => {
    dispatch(updateQuoteStatus({ quoteId, newStatus: "CANCELLED" }));
  };
  const handleDelete = async (quoteId) => {
    await dispatch(removeQuote(quoteId));
    dispatch(
      fetchQuoteByCriteria(
        criteria?.supplierId,
        criteria?.quoteId,
        criteria?.qouteDate,
        criteria?.status,
        page
      )
    );
  };
  const handleEditQuote = (data) => {
    dispatch(getQuoteByIdToUpdate(data));
    navigate("/quote-create");
  };

  return (
    <>
      <Typography variant="h9" sx={{ ml: 2 }}>
        Please Add the Quote Criteria And Press The Search Quote Button To Fetch
        Quotes.
      </Typography>
      <SearchBox />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={handlePrevPage} disabled={page === 1}>
          Prev
        </Button>
        <Button onClick={handleNextPage} disabled={page >= totalPages}>
          Next
        </Button>
      </Box>

      <Box sx={{ height: 400 }}>
        <DataGrid
          rows={requestList ? requestList : []}
          columns={headCells(
            true,
            handleDelete,
            modal,
            setModal,
            viewQuote,
            handleEditQuote,
            modalApproved,
            true,
            setModalApproved,
            handleApproved,
            true,
            modalSubmitted,
            setModalSubmitted,
            handleSubmitted,
            true,
            modalAccepted,
            setModalAccepted,
            handleAccepted,
            true,
            modalOrdered,
            setModalOrdered,
            handleOrdered,
            true,
            modalCancel,
            setModalCancel,
            handleCancel,
            viewAppendixFile
          )}
          getRowId={(row) => row.quoteId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          rowsPerPageOptions={[25]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}
export default AllQuotationList;
