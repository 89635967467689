import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { headCells } from "./utils";
import { useNavigate } from "react-router-dom";
import {
  deleteDiscount,
  editDiscountTrigger,
  fetchDiscount,
} from "../../../../actions/usersAction";

function AllDiscountsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discount = useSelector((state) => state?.user);
  // console.log(" discount: ", discount);
  const discounts = discount.discount;
  const [modalBlock, setModalBlock] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [servi, setServi] = useState({});

  useEffect(() => {
    dispatch(fetchDiscount());
  }, []);

  useEffect(() => {}, [discounts]);

  const handleDelete = (discountId) => {
    // console.log("discountId", discountId);
    dispatch(deleteDiscount(discountId));
  };

  const handleUpdate = (data) => {
    dispatch(editDiscountTrigger(data));
    navigate("/app/mgmt/discount/create");
  };
  return (
    <Box sx={{ height: 600 }}>
      <DataGrid
        rows={discounts}
        columns={headCells(
          true,
          handleDelete,
          modal,
          setModal,
          handleUpdate,
          true,
          modalBlock,
          setModalBlock
        )}
        getRowId={(row) => row.discountId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
      />
    </Box>
  );
}
export default AllDiscountsList;
