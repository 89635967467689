import React from "react";
import { Button, TextField, Grid, CardHeader, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function FinalizeProduct({ handleBack, activeStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configurator = useSelector((state) => state.configurator);
  // console.log("configurator====>>>> : ", configurator);

  // const assembledProductEdit = useSelector((state) => state.customProduct);

  const isAssembledProductEdit = useSelector((state) => state.configurator?.isAssembledProductEdit);
  const dataToEdit = useSelector((state) => state.configurator?.dataToEdit);
  // console.log("assembledProductInProgress",isAssembledProductEdit, dataToEdit);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        totalPrice: dataToEdit?.totalPrice?.mrp,
        totalGst:dataToEdit?.totalGst,
        discount:  dataToEdit?.discount,
        totalSalesPrice: dataToEdit?.totalPrice?.salesPrice,
      }}
      validationSchema={Yup.object().shape({
        totalPrice: Yup.string().required("totalPrice is required").max(100),
        totalGst: Yup.string().required("totalGst required").max(100),
        discount: Yup.string().required("discount is required").max(100),
        totalSalesPrice: Yup.string()
          .required("totalSalesPrice is required")
          .max(100),
      })}
      onSubmit={async (values, { setErrors, setuom, setSubmitting }) => {
        try {
          // await dispatch(createAssembleProduct(values));
          // onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setuom({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Card>
            <form onSubmit={handleSubmit}>
              <CardHeader title="Products Info Card" />
              <Grid container spacing={2} p={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="totalPrice"
                    name="totalPrice"
                    label="Total Price"
                    value={values.totalPrice}
                    onChange={handleChange}
                    error={touched.totalPrice && Boolean(errors.totalPrice)}
                    helperText={touched.totalPrice && errors.totalPrice}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="totalGst"
                    name="totalGst"
                    label="Total Gst"
                    value={values.totalGst}
                    onChange={handleChange}
                    error={touched.totalGst && Boolean(errors.totalGst)}
                    helperText={touched.totalGst && errors.totalGst}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="totalSalesPrice"
                    name="totalSalesPrice"
                    label="Total Sales Price"
                    value={values.totalSalesPrice}
                    onChange={handleChange}
                    error={
                      touched.totalSalesPrice && Boolean(errors.totalSalesPrice)
                    }
                    helperText={
                      touched.totalSalesPrice && errors.totalSalesPrice
                    }
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="discount"
                    name="discount"
                    label="Discount(%)"
                    value={values.discount}
                    onChange={handleChange}
                    error={touched.discount && Boolean(errors.discount)}
                    helperText={touched.discount && errors.discount}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBackIcon />}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Grid item xs="auto">
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            navigate("/app/mgmt/catalog/configurators/all")
                          }
                        >
                          Finalize Product
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        );
      }}
    </Formik>
  );
}
