import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import RegisterAddress from "./RegisterAddress/RegisterAddress";
import OtherAddress from "./OtherAddress/OtherAddress";

import PropTypes from "prop-types";

const CustomerAddress = ({
  onClick,
  onClickOther,
  registeredAddress,
  otherAddresses,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <RegisterAddress
          onClick={onClick}
          registeredAddress={registeredAddress}
        />
      </Grid>
      <Grid xs={12}>
        <OtherAddress
          onClickOther={onClickOther}
          addresses={otherAddresses}
        />
      </Grid>
    </Grid>
  );
};

CustomerAddress.prototype = {
  onClick: PropTypes.func,
  onClickOther: PropTypes.func,
  registeredAddress: PropTypes.func,
  otherAddresses: PropTypes.func,
};
export default CustomerAddress;
