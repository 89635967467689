import axios from "utils/axios";

import {
  FETCH_PRODUCT_LIST_URL,
  ADD_PRODUCT_URL,
  DELETE_PRODUCT_URL,
  PRODUCT_UPDATE_URL,
  CREATE_ASSEMBLE_PRODUCT_URL,
  CREATE_HOSE_ASSEMBLE_PRODUCT_URL,
  FETCH_CUSTOM_PRODUCT_LIST_URL,
  ADD_CONFIGURATOR_PRODUCT,
  REMOVE_CONFIGURATOR_PRODUCT_URL,
  DELETE_ASSEMBLED_PRODUCT_URL,
  ASSEMBLED_PRODUCT_UPDATE_URL,
  GET_CONFIGURATOR_PRODUCT_BY_ID,
  SEARCH_HOSE_ASSEMBLY_BY_CRITERIA,
  CALCULATE_TOTAL_AMOUNT_URL,
  UPLOAD_PRODUCTS_URL,
} from "config/services";

class CatalogService {
  fetchProduct = () =>
    new Promise((resolve, reject) => {
      axios
        .get(FETCH_PRODUCT_LIST_URL, {})
        .then((response) => {
          // console.log("catalogService.fetchUsers() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.fetchUsers() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchCustomProduct = () =>
    new Promise((resolve, reject) => {
      axios
        .get(FETCH_CUSTOM_PRODUCT_LIST_URL, {})
        .then((response) => {
          // console.log("catalogService.fetchCustomProduct() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.fetchCustomProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addProduct = (newProductInfo) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.addProduct called", newProductInfo);
      const payload = newProductInfo;
      axios
        .post(ADD_PRODUCT_URL, { payload })
        .then((response) => {
          // console.log("catalogService.addProduct() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.addProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  createAssembleProduct = (newAssembleInfo) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "catalogService.createAssembleProduct called",
      //   newAssembleInfo
      // );
      const payload = newAssembleInfo;
      axios
        .post(CREATE_ASSEMBLE_PRODUCT_URL, { payload })
        .then((response) => {
          // console.log("catalogService.createAssembleProduct() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.addProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  createHoseAssembly = (newAssembleInfo) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.createHoseAssembly called", newAssembleInfo);
      const payload = newAssembleInfo;
      axios
        .post(CREATE_HOSE_ASSEMBLE_PRODUCT_URL, { payload })
        .then((response) => {
          // console.log("catalogService.createHoseAssembly() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.createHoseAssembly() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  deleteProduct = (productId) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.deleteProduct called", productId);

      axios
        .post(DELETE_PRODUCT_URL, { payload: { productId } })
        .then((response) => {
          // console.log("catalogService.deleteProduct() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.deleteProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  deleteAssembleProduct = (assembledProductId) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "catalogService.deleteAssembleProduct called",
      //   assembledProductId
      // );

      axios
        .post(DELETE_ASSEMBLED_PRODUCT_URL, { payload: { assembledProductId } })
        .then((response) => {
          // console.log("catalogService.deleteAssembleProduct() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.deleteProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  removeAssembleProduct = (productId, assembledProductId) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "catalogService.removeAssembleProduct called",
      //   productId,
      //   assembledProductId
      // );

      axios
        .post(REMOVE_CONFIGURATOR_PRODUCT_URL, {
          payload: { productId, assembledProductId },
        })
        .then((response) => {
          // console.log("catalogService.removeAssembleProduct() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.removeAssembleProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateProducts = (newProductInfo) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.updateProducts called", newProductInfo);
      const payload = newProductInfo;
      axios
        .post(PRODUCT_UPDATE_URL, { payload })
        .then((response) => {
          // console.log("catalogService.updateProducts() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.updateProducts() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateAssembledProducts = (newAssembleInfo) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.updateAssembledProducts called", newProductInfo);
      const payload = newAssembleInfo;
      axios
        .post(ASSEMBLED_PRODUCT_UPDATE_URL, { payload })
        .then((response) => {
          // console.log("catalogService.updateAssembledProducts() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.updateAssembledProducts() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addConfiguratorProduct = (productId, assembledProductId, quantity) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "catalogService.addConfiguratorProduct called",
      //   productId,
      //   assembledProductId,
      //   quantity
      // );
      axios
        .post(ADD_CONFIGURATOR_PRODUCT, {
          payload: { productId, assembledProductId, quantity },
        })
        .then((response) => {
          // console.log(
          //   "catalogService.addConfiguratorProduct() resp:",
          //   response
          // );
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.addConfiguratorProduct() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchCustomProductBySkuId = (skuId) =>
    new Promise((resolve, reject) => {
      let viewUrl = `${GET_CONFIGURATOR_PRODUCT_BY_ID}?skuid=${skuId}`;
      axios
        .get(viewUrl, {})
        .then((response) => {
          // console.log("catalogService.fetchCustomProductBySkuId() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.fetchCustomProductBySkuId() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchHoseAssemblyByCriteria = (skuid, createdAt) => {
    return new Promise((resolve, reject) => {
      let params = "";

      if (skuid !== undefined && skuid !== "") {
        params += `?skuid=${skuid}`;
      }

      let criteriaUrl = `${SEARCH_HOSE_ASSEMBLY_BY_CRITERIA}${params}`;

      axios
        .get(criteriaUrl, {})
        .then((response) => {
          // console.log(
          //   "QuoteService.fetchHoseAssemblyByCriteria() resp:",
          //   response
          // );
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  };
  CalculateTotalAmount = (
    hosePipe,
    length,
    fittingA,
    fittingB,
    sleeves,
    adaptor,
    adptQty,
    customerCategory
  ) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.CalculateTotalAmount called", payload);
      // const payload = values;
      axios
        .post(CALCULATE_TOTAL_AMOUNT_URL, {
          payload: {
            hosePipe,
            length,
            fittingA,
            fittingB,
            sleeves,
            adaptor,
            adptQty,
            customerCategory,
          },
        })
        .then((response) => {
          // console.log("catalogService.CalculateTotalAmount() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.CalculateTotalAmount() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  uploadProducts = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("catalogService.CalculateTotalAmount called", payload);
      axios
        .post(UPLOAD_PRODUCTS_URL, {
          payload,
        })
        .then((response) => {
          // console.log("catalogService.uploadProducts() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("catalogService.uploadProducts() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const catalogService = new CatalogService();

export default catalogService;
