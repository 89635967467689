import usersService from "services/usersService";
import { AddDiscount, AddUser } from "../interface/userProfileIf";

export const USER_FETCH = "@user/fetch";
export const USER_FETCH_SUCCESS = "@user/fetch-success";
export const USER_FETCH_FAILURE = "@user/fetch-failure";

export const USER_CREATE = "@user/create";
export const USER_CREATE_SUCCESS = "@user/create-success";
export const USER_CREATE_FAILURE = "@user/create-failure";

export const REMOVE_USER = "@user/remove";
export const REMOVE_USER_SUCCESS = "@user/remove-success";
export const REMOVE_USER_FAILURE = "@user/remove-failure";

export const UPDATE_USER = "@user/update";
export const UPDATE_USER_SUCCESS = "@user/update-success";
export const UPDATE_USER_FAILURE = "@user/update-failure";

export const USER_EDIT_TRIGGER = "@user/edit-trigger";
export const USER_CREATE_TRIGGER = "@user/create-trigger";

export const USER_BLOCK = "@client/block";
export const USER_BLOCK_SUCCESS = "@client/block-success";
export const USER_BLOCK_FAILURE = "@client/block-failure";

export const ADD_DISCOUNT = "@discount/add";
export const ADD_DISCOUNT_SUCCESS = "@discount/add-success";
export const ADD_DISCOUNT_FAILURE = "@discount/add-failure";

export const DISCOUNT_FETCH = "@discount/fetch";
export const DISCOUNT_FETCH_SUCCESS = "@discount/fetch-success";
export const DISCOUNT_FETCH_FAILURE = "@discount/fetch-failure";

export const DELETE_DISCOUNT = "@discount/delete";
export const DELETE_DISCOUNT_SUCCESS = "@discount/delete-success";
export const DELETE_DISCOUNT_FAILURE = "@discount/delete-failure";

export const UPDATE_DISCOUNT = "@discount/update";
export const UPDATE_DISCOUNT_SUCCESS = "@discount/update-success";
export const UPDATE_DISCOUNT_FAILURE = "@discount/update-failure";
export const DISCOUNT_CREATE_TRIGGER = "@discount/edit-trigger";
export const DISCOUNT_EDIT_TRIGGER = "@discount/create-trigger";

export const CHANGE_PASSOWRD_EDIT_TRIGGER = "@change-password/edit-trigger";

export function fetchUsers() {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_FETCH,
      });
      const users = await usersService.fetchUsers();

      // console.log("usersAction.fetchUsers Resp:", users);

      dispatch({
        type: USER_FETCH_SUCCESS,
        payload: {
          users,
        },
      });
    } catch (error) {
      // console.log("usersAction.fetchUsers: Error", error);
      dispatch({ type: USER_FETCH_FAILURE });
      throw error;
    }
  };
}

export function createUsers(values) {
  return async (dispatch) => {
    try {
      const newUserInfo = new AddUser(values);
      dispatch({
        type: USER_CREATE,
        payload: {
          newUserInfo,
        },
      });
      // console.log("usersAction.createUsers:", newUserInfo);

      const resp = await usersService.createUsers(newUserInfo);

      // console.log("usersAction.createUsers: Resp:", resp);

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchUsers());

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("usersAction.addusers:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: USER_CREATE_FAILURE });
      throw error;
    }
  };
}
export function removeUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_USER,
        payload: {
          userId,
        },
      });
      // console.log("usersAction.removeUser:", userId);

      const users = await usersService.removeUser(userId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: REMOVE_USER_SUCCESS,
        payload: {
          users,
        },
      });
      await dispatch(fetchUsers());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: REMOVE_USER_FAILURE });
      throw error;
    }
  };
}
export function editUserTrigger(userEdit) {
  return async (dispatch) => {
    // console.log("editUserTrigger() ", userEdit);
    dispatch({
      type: USER_EDIT_TRIGGER,
      payload: { userEdit },
    });
  };
}
export function changePasswordTrigger(changePasswordEdit) {
  return async (dispatch) => {
    // console.log("changePasswordTrigger() ", changePasswordEdit);
    dispatch({
      type: CHANGE_PASSOWRD_EDIT_TRIGGER,
      payload: { changePasswordEdit },
    });
  };
}

export function createUserTrigger() {
  return async (dispatch) => {
    dispatch({
      type: USER_CREATE_TRIGGER,
      payload: {},
    });
  };
}

export function updateUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER,
        payload: {
          userId,
        },
      });
      // console.log("clientAction.removeClient:", userId);

      const user = await usersService.updateUser(userId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: {
          user,
        },
      });
      await dispatch(fetchUsers());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: UPDATE_USER_FAILURE });
      throw error;
    }
  };
}

export function blockClient(userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_BLOCK ,
        payload: {
          userId,
        },
      });
      // console.log("clientAction.blockClient:", userId);

      const user = await usersService.blockUser(userId);

      // console.log("usersAction.removeUser Resp:", users);
      dispatch({
        type: USER_BLOCK_SUCCESS,
        payload: {
          user,
        },
      });
      await dispatch(fetchUsers());
    } catch (error) {
      // console.log("usersAction.removeUser: Error", error);
      dispatch({ type: USER_BLOCK_FAILURE });
      throw error;
    }
  };
}

export function addDiscount(values) {
  // console.log("values", values);
  return async (dispatch) => {
    try {
      const newDiscountInfo = new AddDiscount(values, undefined);
      dispatch({
        type: ADD_DISCOUNT,
        payload: {
          newDiscountInfo,
        },
      });
      // console.log("usersAction.addDiscount:", newDiscountInfo);

      const resp = await usersService.addDiscount(newDiscountInfo);

      // console.log("usersAction.addDiscount: Resp:", resp);

      dispatch({
        type: ADD_DISCOUNT_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchDiscount());

      // TODO SP Fetch Rest of the information for the product to initialize the application
    } catch (err) {
      // console.log("productsAction.addproducts:: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADD_DISCOUNT_FAILURE });
      throw error;
    }
  };
}
export function deleteDiscount(discountId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_DISCOUNT,
        payload: {
          discountId,
        },
      });
      // console.log("usersAction.deleteDiscount:", discountId);

      const resp = await usersService.deleteDiscount(discountId);

      // console.log("usersAction.removeUser Resp:", resp);
      dispatch({
        type: DELETE_DISCOUNT_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchDiscount());
    } catch (error) {
      // console.log("usersAction.deleteDiscount: Error", error);
      dispatch({ type: DELETE_DISCOUNT_FAILURE });
      throw error;
    }
  };
}
export function fetchDiscount() {
  return async (dispatch) => {
    try {
      dispatch({
        type: DISCOUNT_FETCH,
      });
      const discount = await usersService.fetchDiscount();

      // console.log("usersAction.fetchUsers Resp:", users);

      dispatch({
        type: DISCOUNT_FETCH_SUCCESS,
        payload: {
          discount,
        },
      });
    } catch (error) {
      // console.log("usersAction.fetchUsers: Error", error);
      dispatch({ type: DISCOUNT_FETCH_FAILURE });
      throw error;
    }
  };
}

export function updateDiscount(discountId, values) {
  return async (dispatch) => {
    try {
      const newDiscountInfo = new AddDiscount(values, discountId);

      dispatch({
        type: UPDATE_DISCOUNT,
        payload: {
          newDiscountInfo,
        },
      });
      // console.log("usersAction.updateDiscount:", newDiscountInfo);

      const resp = await usersService.updateDiscount(newDiscountInfo);

      // console.log("usersAction.updateDiscount Resp:", resp);

      dispatch({
        type: UPDATE_DISCOUNT_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchDiscount());
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("catalogAction.updateProducts: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: UPDATE_DISCOUNT_FAILURE });
      throw error;
    }
  };
}

export function createDiscountTrigger() {
  return async (dispatch) => {
    dispatch({
      type: DISCOUNT_CREATE_TRIGGER,
      payload: {},
    });
  };
}
export function editDiscountTrigger(discountEdit) {
  return async (dispatch) => {
    // console.log("editDiscountTrigger() ", discountEdit);
    dispatch({
      type: DISCOUNT_EDIT_TRIGGER,
      payload: { discountEdit },
    });
  };
}
