import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "./mse.png";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/formatUtils";
import { moneyFormatter } from "../../../../utils/formatUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mseLogo from "./mse.png";
import mindsclikLogo from "./logo_.png";

// Create styles
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
const styles = StyleSheet.create({
  page: {
    height: "500px",
    width: "100%",
  },
  pdfPage: {
    fontSize: 10,
    backgroundColor: "white",
    border: "3px solid black",
    padding: "25px",
    // lineHeight: 1.5,
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },

  text: {
    marginTop: "3px",
  },
  grandTotal: {
    marginTop: "10px",
  },
  section: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.2px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  tncSection: {
    padding: "10px",
    paddingTop: "10px",
    height: "auto",
    borderBottom: "1px solid black",
  },
  billToSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  deliverySection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  SupplierSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "1px solid black",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },

  OrderIdSection: {
    fontFamily: "Lato Bold",
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "column-reverse",
    marginLeft: "70px",
  },
  accountDetailsSection: {
    // height: "auto",
    backgroundColor: "white",
    paddingTop: "10px",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    borderBottom: "0.5px solid black",
  },
  rightSection: {
    paddingRight: "180px",
  },
  leftSection: {
    paddingRight: "-150px",
  },
  totalSection: {
    padding: "10px",
    height: "auto",
    // borderBottom: "0.5px solid gray",
    backgroundColor: "white",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  signiture: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "50px",
  },
  addressSection: {
    flexDirection: "row",
    marginTop: "40px",
    padding: "5px",
  },
  subTotalSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    border: "0.5px solid gray",
    backgroundColor: "white",
  },
  subTotalInnerSection: {
    padding: 15,
  },
  title: {
    fontSize: 11,
    fontWeight: 900,
  },
  itemListHead: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "white",
    color: "black",
    // fontWeight: "ultrabold",
    fontFamily: "Lato Bold",
  },
  itemList: {
    flexDirection: "row",
    fontSize: 8,
    textAlign: "right",
  },
  id: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "20%",
  },
  name: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "40%",
    textAlign: "left",
  },

  hsnCode: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "20%",
  },
  prise: {
    flexDirection: "row",
    padding: 12,
    border: "0.3px solid black",
    width: "25%",
  },
  quantity: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "15%",
  },
  total: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "25%",
  },
  totalTax: {
    flexDirection: "column",
    padding: 10,
    border: "0.3px solid black",
    width: "30%",
  },
  table: {
    padding: 4,
    backgroundColor: "white",
    marginTop: "15px",
  },
  // gstItemListHead: {
  //   // flexDirection: "column",
  //   justifyContent: "center",
  //   backgroundColor: "white",
  //   color: "black",
  //   // fontWeight: "ultrabold",
  //   fontFamily: "Lato Bold",
  // }
  gstTotal: {
    width: "40%",
    marginRight: "-130px",
  },
  gstTotal2: {
    width: "50%",
    marginRight: "-157px",
    textAlign: "right",
  },
  head: {
    flexDirection: "column",
    padding: 5,
    border: "0.3px solid black",
    width: "40%",
  },
  gstItemList: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 8,
    marginTop: 4,
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cardHeaders: {
    flexDirection: "start",
    backgroundColor: "white",
    fontFamily: "Lato Bold",
  },
  invoiceT: {
    fontSize: "18px",
    color: "black",
    letterSpacing: "5px",
    // marginTop: "10px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
  },
  leftColumn: {
    justifyContent: "center",
    backgroundColor: "white",
    padding: "5px",
    width: "50%",
  },
});

const InvoicePDF = ({ quote, handleBack, activeStep }) => {
  let params = useParams();
  //   useEffect(() => {
  //     fetchOrderDetail(params.orderId);
  //   }, []);
  const businessName = quote?.supplierDetails?.businessName;
  const navigate = useNavigate();
  const selectSupplierLogo = () => {
    switch (businessName) {
      case "M.S. Enterprises":
        return mseLogo; // Import your mse.png image
      case "Ishdev Enterprises":
        return mindsclikLogo; // Import your mindsclik_logo.png image
      default:
        return mseLogo; // Default logo if businessName doesn't match
    }
  };
  const itemsData = quote?.items ? quote?.items : [];
  // console.log("items", itemsData);
  const renderItems = () => {
    const pages = [];
    let currentPageItems = [];
    const itemPerPage = 8;
    for (let i = 0; i < itemsData.length; i++) {
      currentPageItems.push(itemsData[i]);
      if (
        currentPageItems.length === itemPerPage ||
        i === itemsData.length - 1
      ) {
        pages.push(
          <View style={styles.table} key={pages.length} wrap={false}>
            <View style={styles.itemListHead}>
              <Text style={styles.name}>Description</Text>
              <Text style={styles.hsnCode}>HSN Code</Text>
              <Text style={styles.quantity}>Units</Text>
              <Text style={styles.prise}>Sales Price</Text>
              <Text style={styles.total}>Amount </Text>
              <Text style={styles.total}>GST</Text>
              {/* <Text style={styles.total}>CGST</Text>
              <Text style={styles.total}>SGST</Text>
              <Text style={styles.total}>IGST</Text> */}
              <Text style={styles.total}>Total</Text>
            </View>
            {currentPageItems.map((item, index) => (
              <View style={styles.itemList} key={index}>
                <Text style={styles.name}>
                  <Text style={{ fontSize: 8 }}>
                    <View style={styles.cardHeaders}>
                      <Text>Material Code:</Text>
                    </View>
                    {item.skuid}
                    {"\n"}
                  </Text>
                  {item.description}
                </Text>
                <Text style={styles.hsnCode}>{item.hsnCode}</Text>
                <Text style={styles.quantity}>
                  {item.qty} {item.uom}
                </Text>
                <Text style={styles.prise}>
                  Rs. {moneyFormatter(item?.salesPrice)} {"\n"}
                  {/* Rs.({moneyFormatter(item.rate)}) */}
                </Text>
                {/* <Text style={styles.total}>
                  Rs.{moneyFormatter(item.gstAmount.cgst)}
                </Text>
                <Text style={styles.total}>
                  Rs.{moneyFormatter(item.gstAmount.sgst)}
                </Text>
                <Text style={styles.total}>
                  Rs.{moneyFormatter(item.gstAmount.igst)}
                </Text> */}
                <Text style={styles.total}>
                  Rs.{moneyFormatter(item.taxableAmount)}
                </Text>
                <Text style={styles.total}>
                  Rs.{moneyFormatter(item.totalAmount - item.taxableAmount)}
                </Text>
                <Text style={styles.total}>
                  Rs.{moneyFormatter(item.totalAmount)}
                </Text>
              </View>
            ))}
          </View>
        );
        currentPageItems = [];
      }
    }
    return pages;
  };
  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.pdfPage}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "2px solid grey",
            paddingBottom: "10px",
          }}
        >
          <View
            style={{
              height: "40%",
              width: "30%",
              marginLeft: "25px",
              marginTop: "10px",
            }}
          >
            <Image
              src={selectSupplierLogo()}
              style={{ width: 100, height: 80 }}
            />
          </View>
          <View style={{ width: "25%" }}>
            <Text style={styles.invoiceT}>Quotation</Text>
          </View>

          <View style={styles.OrderIdSection}>
            <Text style={styles.text}>Quote Id {quote?.quoteId}</Text>
            <Text style={styles.text}>
              Quote Date : {dateFormatter(quote?.quoteDate)}
            </Text>
            <Text style={styles.text}>Quote Status : {quote?.status}</Text>

            {/* <Text style={{ marginTop: "15px" }}>
              Payment Status : {orderDetails?.status}
            </Text>
            <Text style={styles.text}>
              Payment Method : {orderDetails?.paymentGateway}
            </Text> */}
          </View>
        </View>

        <View style={styles.addressSection}>
          <View style={styles.SupplierSection}>
            <View style={styles.cardHeaders}>
              <Text>Supplier Details :</Text>
            </View>
            <Text>{quote?.supplierDetails?.businessName}</Text>
            <Text style={styles.text}>
              {quote?.supplierDetails?.address?.label},
              {quote?.supplierDetails?.address?.address1}
            </Text>
            <Text style={styles.text}>
              {quote?.supplierDetails?.address?.address2}
            </Text>
            <Text style={styles.text}>
              {quote?.supplierDetails?.address?.state},
            </Text>
            <Text style={styles.text}>
              {quote?.supplierDetails?.address?.city}
              {quote?.supplierDetails?.address?.country},
              {quote?.supplierDetails?.address?.pincode}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>Contact Person : </Text>
              <Text style={styles.text}>
                {quote?.supplierDetails?.contactPerson}
              </Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>Email : </Text>
              <Text style={styles.text}>{quote?.supplierDetails?.email}</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>Contact : </Text>
              <Text style={styles.text}>
                {" "}
                {quote?.supplierDetails?.contact}
              </Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>GSTIN : </Text>
              <Text style={styles.text}> {quote?.supplierDetails?.gstin}</Text>
            </Text>
          </View>
          <View style={styles.billToSection}>
            <View style={styles.cardHeaders}>
              <Text>Bill To :</Text>
            </View>
            <View>
              <Text>{quote?.billToDetails?.businessName}</Text>
              <Text style={styles.text}>
                {quote?.billToDetails?.address?.label},
                {quote?.billToDetails?.address?.address1}
              </Text>
              <Text style={styles.text}>
                {quote?.billToDetails?.address?.address2}
              </Text>
              <Text style={styles.text}>
                {quote?.billToDetails?.address?.state},
              </Text>
              <Text style={styles.text}>
                {quote?.billToDetails?.address?.city}{" "}
                {quote?.billToDetails?.address?.country},
                {quote?.billToDetails?.address?.pincode}
              </Text>

              <Text style={styles.text}>
                <Text style={styles.cardHeaders}>Contact Person : </Text>
                <Text style={styles.text}>
                  {quote?.billToDetails?.contactPerson}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text style={styles.cardHeaders}>Email : </Text>
                <Text style={styles.text}>{quote?.billToDetails?.email}</Text>
              </Text>
              <Text style={styles.text}>
                <Text style={styles.cardHeaders}>Contact : </Text>
                <Text style={styles.text}>{quote?.billToDetails?.contact}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.deliverySection}>
            <View style={styles.cardHeaders}>
              <Text>Delivary To :</Text>
            </View>
            <Text>{quote?.shipToDetails?.businessName}</Text>
            <Text style={styles.text}>
              {quote?.shipToDetails?.address?.label},
              {quote?.shipToDetails?.address?.address1}
            </Text>
            <Text style={styles.text}>
              {quote?.shipToDetails?.address?.address2}
            </Text>
            <Text style={styles.text}>
              {quote?.shipToDetails?.address?.state},
            </Text>
            <Text style={styles.text}>
              {quote?.shipToDetails?.address?.city}
              {quote?.shipToDetails?.address?.country},
              {quote?.shipToDetails?.address?.pincode}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>Email : </Text>
              <Text style={styles.text}>{quote?.shipToDetails?.email}</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.cardHeaders}>Contact : </Text>
              <Text style={styles.text}>{quote?.shipToDetails?.contact}</Text>
            </Text>
          </View>
        </View>
        {renderItems()}
        <View style={styles.gstItemList} wrap={false}>
          <View style={styles.gstTotal} >
            <Text style={styles.head}>Sub Total</Text>
            <Text style={styles.head}>CGST</Text>
            <Text style={styles.head}>SGST</Text>
            <Text style={styles.head}>IGST</Text>
            <Text style={styles.head}>Total</Text>
          </View>
          <View style={styles.gstTotal2}>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.subTotal
                  ? quote?.amountSummary?.subTotal
                  : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.cgst ? quote?.amountSummary?.cgst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.sgst ? quote?.amountSummary?.sgst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.{" "}
              {moneyFormatter(
                quote?.amountSummary?.igst ? quote?.amountSummary?.igst : 0
              )}
            </Text>
            <Text style={styles.head}>
              Rs.
              {moneyFormatter(
                quote?.amountSummary?.grandTotal
                  ? quote?.amountSummary?.grandTotal
                  : 0
              )}
            </Text>
          </View>
        </View>
        {/* <View style={styles.totalSection}>
          <Text style={styles.text}>
            Sub Total : Rs.{moneyFormatter(quote?.amountSummary?.subTotal)}
          </Text>
          <Text style={styles.text}>
            CGST : Rs.{moneyFormatter(quote?.amountSummary?.cgst)}
          </Text>
          <Text style={styles.text}>
            IGST : Rs. {quote?.amountSummary?.igst}
          </Text>
          <Text style={styles.text}>
            SGST : Rs.{moneyFormatter(quote?.amountSummary?.sgst)}
          </Text>
          <Text style={styles.grandTotal}>
            Total Amount : Rs.
            {moneyFormatter(quote?.amountSummary?.grandTotal)}
          </Text>
        </View> */}
        <View style={styles.tncSection}>
          <Text style={{ fontFamily: "Lato Bold" }}>
            Terms And Conditions :
          </Text>
          <Text style={styles.text}>{quote?.tnc}</Text>
        </View>
        {/* </View> */}

        <View style={styles.accountDetailsSection}>
          <View style={styles.rightSection}>
            <Text style={{ fontFamily: "Lato Bold" }}>Account Details :</Text>
            <Text style={styles.text}>
              A/C Name : {quote?.accountDetails?.accountName}
            </Text>
            <Text style={styles.text}>
              A/C Number : {quote?.accountDetails?.accountNumber}
            </Text>
            <Text style={styles.text}>
              Bank Name : {quote?.accountDetails?.bankName}
            </Text>
          </View>
          <View style={styles.leftSection}>
            <Text style={styles.text}>
              Branch : {quote?.accountDetails?.branch}
            </Text>
            <Text style={styles.text}>
              IFSC : {quote?.accountDetails?.ifsc}
            </Text>
            <Text style={styles.text}>
              Swift Code : {quote?.accountDetails?.swiftCode}
            </Text>
          </View>
        </View>
        <View style={styles.signiture}>
          <Text style={{ fontFamily: "Lato Bold" }}>Authorised Signitory</Text>
        </View>
        <View style={styles.thankyou}>
          <Text style={styles.thankU}>Thank you for your Order</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PDFViewer style={styles.page}>
          <MyDocument />
        </PDFViewer>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back
          </Button>
          <Grid item xs="auto">
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/app/quotations/all")}
              >
                Finish & Go to Quote List
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoicePDF;
