class NewQuote {
  constructor(quoteInfo, clientInfo, supplierInfo, quoteId) {
    if (quoteInfo) {
      this.quoteId = quoteId;
      this.supplierId = supplierInfo.supplierId;
      this.clientId = quoteInfo.clientId;
      this.clientBusinessName = quoteInfo.clientBusinessName;
      this.quoteDate = quoteInfo.quoteDate;
      this.quoteDetails = {
        enquiryDate: quoteInfo.enquiryDate,
        concernedSalesPerson: quoteInfo.concernedSalesPerson,
        enquiry: quoteInfo.enquiry,
      };
      this.supplierDetails = {
        businessName: supplierInfo.businessName,
        address: {
          label: supplierInfo?.address?.label,
          address1: supplierInfo?.address?.address1,
          address2: supplierInfo?.address?.address2,
          state: supplierInfo?.address?.state,
          city: supplierInfo?.address?.city,
          country: supplierInfo?.address?.country,
          pincode: supplierInfo?.address?.pincode,
        },
        contactPerson: supplierInfo.contactPerson,
        email: supplierInfo.email,
        contact: supplierInfo.contact,
        gstin: supplierInfo.gstin,
      };
    }
  }
}
class AddCustomerAddress {
  constructor(quoteInfo) {
    if (quoteInfo) {
      this.quoteId = quoteInfo.quoteId;
      this.billTo = {
        businessName: quoteInfo.businessName,
        address: {
          label: quoteInfo.billToLabel,
          address1: quoteInfo.address1,
          address2: quoteInfo.address2,
          state: quoteInfo.state,
          city: quoteInfo.city,
          country: quoteInfo.country,
          pincode: quoteInfo.pincode,
        },
        contactPerson: quoteInfo.contactPerson,
        email: quoteInfo.email,
        contact: quoteInfo.contact,
        gstin: quoteInfo.gstIn,
      };
      this.shippedTo = {
        businessName: quoteInfo.shipBusinessName,
        address: {
          label: quoteInfo.shipBillToLabel,
          address1: quoteInfo.shipAddress1,
          address2: quoteInfo.shipAddress2,
          state: quoteInfo.shipState,
          city: quoteInfo.shipCity,
          country: quoteInfo.shipCountry,
          pincode: quoteInfo.shipPincode,
        },
        contactPerson: quoteInfo.shipContactPerson,
        email: quoteInfo.shipEmail,
        contact: quoteInfo.shipContact,
        gstin: quoteInfo.shipgstIn,
      };
    }
  }
}

class AddItem {
  constructor(quoteItemInfo) {
    if (quoteItemInfo) {
      this.quoteId = quoteItemInfo.quoteId;
      this.skuid = quoteItemInfo.skuid;
      this.qty = quoteItemInfo.qty;
      this.discount = quoteItemInfo.discount;
    }
  }
}
class AddTNC {
  constructor(quoteTNCInfo) {
    if (quoteTNCInfo) {
      this.quoteId = quoteTNCInfo.quoteId;
      this.termsAndConditions = quoteTNCInfo.termsAndConditions;
    }
  }
}

class AddToQoute {
  constructor(values) {
    if (values) {
      this.quoteId = values.quoteId;
      this.businessId = values.businessId;
      this.adaptor = values.adaptors;
      this.adptQty = values.adptQty;
      this.amount = values.amount;
      this.name = values.description;
      this.fittingA = values.fittingA;
      this.fittingB = values.fittingB;
      this.hosePipe = values.hoseProduct;
      this.length = values.length;
      this.skuid = values.materialCode;
      this.sleeves = values.sleeves;
      // this.skuid = values.skuid;
      this.hsnCode = values.hsnCode;
      this.qty = values.qty;
      // this.discount = values.discount;
    }
  }
}

export default { NewQuote };
export { NewQuote, AddCustomerAddress, AddItem, AddTNC, AddToQoute };
