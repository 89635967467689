import React from "react";
import { IconButton, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog } from "components";

export const headCells = (
  showDelete,
  handleDelete,
  modal,
  setModal,
  handleUpdate,
  showBlock,
  modalBlock,
  setModalBlock
) => [
  { field: "discountId", headerName: "Discount Id", width: 120 },
  {
    field: "productCategory",
    headerName: "Product Category",
    width: 250,
    editable: false,
  },
  {
    field: "discount",
    headerName: "Discount",
    width: 120,
    editable: false,
  },
  {
    field: "customerCategory",
    headerName: "Customer Category",
    width: 250,
    editable: false,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      // console.log("RENDERCELLPARAMS:", params.row);
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );
      };
      // const navigate = useNavigate();
      return (
        <div className="cellAction">
          {/* edit button */}
          <Tooltip title={"Edit Customer"}>
            <IconButton
              onClick={() => handleUpdate(params.row)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* edit button */}
          <>
            {showDelete && params.row.discountId && (
              <>
                <ConfirmDialog
                  title="Delete Customer"
                  description={`Do you want to permanently delete Customer:${params.row.name}(${params.row.clientId})? This action can't be undone`}
                  isOpen={
                    modal === params.row.discountId && params.row.discountId
                  }
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.row.discountId);
                    setModal(false);
                    handleDelete(params.row.discountId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <Tooltip title={"Delete Customer"}>
                  <IconButton
                    onClick={() => setModal(params.id)}
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        </div>
      );
    },
  },
];
